import { isEmpty } from "lodash";
import Config from "../../../../../config";
import { isEmptyOrNullOrUndefined } from "../../../../../utils";

const ADDITIOANL_COMMUNITY_OPTIONS = ["OTHER", "UNDISCLOSED"];

const getRequiredFieldValues = ({ tempProfile, authAccount, section }) => {
  const { personalInfo, parentInfo } = tempProfile;
  const requiredValues = [];
  switch (section) {
    case "PERSONAL_INFORMATIONS":
      requiredValues.push(personalInfo?.fname);
      requiredValues.push(personalInfo?.lname);
      requiredValues.push(personalInfo?.displayNameFormat);
      requiredValues.push(personalInfo?.gender);
      requiredValues.push(personalInfo?.dob);
      requiredValues.push(personalInfo?.height);
      requiredValues.push(personalInfo?.religionId);
      requiredValues.push(personalInfo?.civilStatusId);
      requiredValues.push(personalInfo?.educationLevelId);
      requiredValues.push(personalInfo?.professionId);
      requiredValues.push(personalInfo?.drinking);
      requiredValues.push(personalInfo?.smoking);
      requiredValues.push(personalInfo?.foodPreferenceId);

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
        requiredValues.push(personalInfo?.ethnicityId);
      }

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
        if (
          !ADDITIOANL_COMMUNITY_OPTIONS.includes(personalInfo?.communityOther)
        ) {
          requiredValues.push(personalInfo?.communityId);
        }
        requiredValues.push(personalInfo?.motherTongueId);
      }
      break;
    case "PARENTS_INFORMATIONS":
      if (Array.isArray(parentInfo) && !isEmpty(parentInfo)) {
        for (const parent of parentInfo) {
          const instanceFields = [];
          instanceFields.push(parent.religionId);
          instanceFields.push(parent.residentCountryCode);

          if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
            instanceFields.push(parent.ethnicityId);
          }

          if (Config.CURRENT_PLATFORM === Config.PLATFORMS.IN) {
            instanceFields.push(parent.communityId);
          }
          requiredValues.push(instanceFields);
        }
      } else {
        requiredValues.push(undefined);
      }
      break;
    case "PRIVATE_INFORMATIONS":
      requiredValues.push(personalInfo?.originCountryCode);

      if (Config.CURRENT_PLATFORM === Config.PLATFORMS.LK) {
        requiredValues.push(authAccount.userPreferences?.showOfflineId);
        requiredValues.push(authAccount.userPreferences?.subscribeMagazine);
      }
      break;
    default:
      break;
  }

  return requiredValues.flat();
};

export const calculateNextStep = ({ tempProfile, authAccount }) => {
  const { CREATE_FLOW_STEP_META } = Config;
  for (let currentMeta of CREATE_FLOW_STEP_META) {
    const fieldValues = getRequiredFieldValues({
      section: currentMeta.name,
      authAccount,
      tempProfile,
    });
    const shouldRedirect = fieldValues.some((current) =>
      isEmptyOrNullOrUndefined(current)
    );
    if (shouldRedirect) {
      return currentMeta;
    }
  }
  return CREATE_FLOW_STEP_META[CREATE_FLOW_STEP_META.length - 1];
};

export const getActiveStepsByLocation = ({ location }) => {
  const pathName = location.pathname ?? "";
  const sanitziedPathName = pathName.replace("/", "").trim();
  const { CREATE_FLOW_STEP_META } = Config;
  const meta = CREATE_FLOW_STEP_META.find((current) => {
    return current.route === sanitziedPathName;
  });
  return meta?.index ?? CREATE_FLOW_STEP_META.length - 1;
};
