import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StepLabel,
  Stepper,
  Step,
  Paper,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./style.css";
import { getActiveStepsByLocation } from "./extensions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { redirectToCreateFlowStep } from "../../../../features/createflow";
import Config from "../../../../config";

const { CREATE_FLOW_STEP_META } = Config;

const FlowStepper = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { initialized, createflowInitialized, enabledCreateFlowReviewPage } =
    useSelector((state) => state.application);
  const [activeStep, setActiveStep] = useState(3);

  const getStepIconComponent = (step) => {
    return step <= activeStep
      ? RadioButtonCheckedIcon
      : RadioButtonUncheckedIcon;
  };

  const getStepIconClassName = (step) => {
    return step === activeStep ? "active-step-indicator" : "";
  };

  const stepperLabel = (localeKey) => {
    return !enabledCreateFlowReviewPage && localeKey === "common.summery"
      ? "common.payment"
      : localeKey;
  };

  useEffect(() => {
    if (!initialized || createflowInitialized) {
      return;
    }
    dispatch(redirectToCreateFlowStep({ setActiveStep, history }));
  }, [createflowInitialized, dispatch, history, initialized]);

  useEffect(() => {
    if (createflowInitialized) {
      const newStep = getActiveStepsByLocation({ location });
      if (newStep !== activeStep) {
        setActiveStep(newStep);
      }
    }
  }, [activeStep, createflowInitialized, location]);

  return (
    <Paper
      className="create-flow-stepper initialized"
      variant="outlined"
      style={{ paddingTop: initialized ? 8 : 0 }}
    >
      {!initialized && <LinearProgress className="linear-progress" />}
      <Typography align="center" className="stepper-heading">
        {t("createflow.create_a_new_ad")}
      </Typography>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className="flow-stepper-root"
      >
        {CREATE_FLOW_STEP_META.map((currentMeta) => {
          return (
            <Step
              key={currentMeta.name}
              className={getStepIconClassName(currentMeta.index)}
            >
              <StepLabel
                StepIconComponent={getStepIconComponent(currentMeta.index)}
              >
                {t(stepperLabel(currentMeta.localeKey))}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Paper>
  );
};

export default FlowStepper;
