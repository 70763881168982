import React, { useMemo } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import i18next from "i18next";

import FilterCriteriaView from "../filter-criteria-view";
import Config from "../../../../../config";

const { APP_LANGS } = Config;

const SelectableItem = ({ fieldMeta, option, onSelect, checked, labelId }) => {
  const { language } = i18next;

  const computedLabelExtractor = useMemo(() => {
    let labelExtractor = fieldMeta.labelExtractor;
    if (!isEmpty(language) && language === APP_LANGS.SI) {
      labelExtractor = `${labelExtractor}_${language}`;
    }
    return labelExtractor;
  }, [fieldMeta.labelExtractor, language]);

  return (
    <ListItem
      key={option.code}
      role={undefined}
      dense
      button
      onClick={(e) => {
        e.stopPropagation();
        onSelect(option);
      }}
    >
      <ListItemIcon>
        <Checkbox
          size="small"
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText
        id={labelId}
        primary={option[computedLabelExtractor]}
        className={checked ? "weight-bold" : ""}
      />
    </ListItem>
  );
};

const MultiCheckBoxSelector = ({
  fieldMeta,
  options,
  values,
  setFieldValues,
  visibility,
  helperText,
}) => {
  if (!visibility && values === undefined) {
    return false;
  }

  const onSelect = (option) => {
    const current = option[fieldMeta.keyExtractor];
    if (isFunction(setFieldValues) && Array.isArray(values)) {
      const isExist = values.includes(current);
      let temp = [...values];
      if (isExist) {
        temp = temp.filter((element) => element !== current);
      } else {
        temp.push(option[fieldMeta.keyExtractor]);
      }
      setFieldValues(fieldMeta.fieldName, temp);
    }
  };

  const onClickReset = () => {
    if (isFunction(setFieldValues) && Array.isArray(values)) {
      setFieldValues(fieldMeta.fieldName, []);
    }
  };

  return (
    <FilterCriteriaView
      fieldMeta={fieldMeta}
      showResetOption={!isEmpty(values)}
      onClickReset={onClickReset}
    >
      <div className="panel">
        {Boolean(helperText) && <p className="region-msg">{helperText}</p>}
        <List className="filter-list">
          {options.map((item) => (
            <SelectableItem
              key={item[fieldMeta.keyExtractor]}
              fieldMeta={fieldMeta}
              option={item}
              onSelect={onSelect}
              checked={values?.includes(item[fieldMeta.keyExtractor])}
              labelId={item[fieldMeta.keyExtractor]}
            />
          ))}
        </List>
      </div>
    </FilterCriteriaView>
  );
};

export default MultiCheckBoxSelector;
