import AuthApi from "../../services/api/auth";
import UserApi from "../../services/api/user";
import { extractUtmCookies } from "../../utils/extractor";

const triggerOTP = async ({ number, captchaToken }) => {
  let ALPHABET =
    "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
  let bb = Math.round(new Date().getTime() / 1000);
  const tso = bb;
  let aa = "";
  while (bb > 0) {
    let tss = ALPHABET[bb % ALPHABET.length].toString();
    aa = aa + tss;
    bb = parseInt(bb / ALPHABET.length);
  }
  const response = await AuthApi.triggerOTP({
    number,
    captchaToken,
    xClientId: aa,
    xTimestamp: tso,
  });
  return response;
};

const verifyOTP = async ({ token, OTPCode }) => {
  const utmCookieKeys = ["utm_campaign", "utm_medium", "utm_source"];
  const utmCookies = extractUtmCookies(utmCookieKeys);
  const response = AuthApi.verifyOTP({ token, code: OTPCode, utmCookies });
  return response;
};

const getUserAccount = async () => {
  const response = await UserApi.getUserAccount();
  if (response.success) {
    localStorage.setItem("memberId", response.body.memberId ?? "");
    localStorage.setItem("userId", response.body.memberId ?? "");
    localStorage.setItem("user_role", response.body.role ?? "");
    localStorage.setItem("user_name", response.body.name ?? "");
    localStorage.setItem("user_email", response.bodyiii.email ?? "");
  }
  return response;
};

const AuthDomain = { triggerOTP, verifyOTP, getUserAccount };
export default AuthDomain;
