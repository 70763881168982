import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "../../styles/progress-bar.css";
import AppContext from "../../v2/context";
import Constants from "../../v2/config/constant";


const isActiveList1 = (history) => {
  if (history.location.pathname === "/complete-ad-personal") {
    return {
      borderColor: "#000",
      background: "#fff",
    };
  }
};

const isActiveList2 = (history) => {
  if (history.location.pathname === "/complete-ad-parent") {
    return {
      borderColor: "#000",
      background: "#fff",
    };
  }
};

const isActiveList3 = (history) => {
  if (history.location.pathname === "/complete-ad-private") {
    return {
      borderColor: "#000",
      background: "#fff",
    };
  }
};

function ProgressBar(props) {
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const { enabledCreateFlowReviewPage } = useSelector(
    (state) => state.application
  );

  const finalStepLabel = enabledCreateFlowReviewPage
    ? "common.summery"
    : "common.payment";

  return (
    <div className="step-progress-bar">
      <div className="step-progress-bar-complete-listing" style={{ zIndex: 1 }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0 }}>
          <li>
            <p
              className={
                props.history.location.pathname === "/complete-ad-personal" ||
                props.history.location.pathname === "/complete-ad-parent" ||
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? "numb-after"
                  : "step-numb"
              }
              style={isActiveList1(props.history)}
            ></p>
            <p
              className={`step-name ${
                appLanguage === Constants.APP_LANG.SI
                  ? "sinhala-typo sinhala-size-10"
                  : ""
              }`}
              style={
                props.history.location.pathname === "/complete-ad-personal" ||
                props.history.location.pathname === "/complete-ad-parent" ||
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? { color: "#333333", fontWeight: 600 }
                  : null
              }
            >
              {t("common.personal")}
            </p>
          </li>

          <li>
            <p
              className={
                props.history.location.pathname === "/complete-ad-parent" ||
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? "numb-after"
                  : "step-numb"
              }
              style={isActiveList2(props.history)}
            ></p>
            <p
              className={`step-name ${
                appLanguage === Constants.APP_LANG.SI
                  ? "sinhala-typo sinhala-size-10"
                  : ""
              }`}
              style={
                props.history.location.pathname === "/complete-ad-parent" ||
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? { color: "#333333", fontWeight: 600 }
                  : null
              }
            >
              {t("common.parents")}
            </p>
          </li>

          <li>
            <p
              className={
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? "numb-after"
                  : "step-numb"
              }
              style={isActiveList3(props.history)}
            ></p>
            <p
              className={`step-name ${
                appLanguage === Constants.APP_LANG.SI
                  ? " sinhala-typo sinhala-size-10 "
                  : ""
              }`}
              style={
                props.history.location.pathname === "/complete-ad-private" ||
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? { color: "#333333", fontWeight: 600 }
                  : null
              }
            >
              {t("common.private")}
            </p>
          </li>

          <li>
            <p
              className={classnames("step-numb", {
                "numb-after":
                  props.history.location.pathname === "/complete-ad-review" ||
                  props.history.location.pathname === "/pay-for-ad" ||
                  props.history.location.pathname === "/checkout" ||
                  props.history.location.pathname === "/checkout/bank-transfer",
              })}
              style={
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? {
                      borderColor: "#000",
                      background: "#fff",
                    }
                  : null
              }
            ></p>
            <p
              className={`step-name ${
                appLanguage === Constants.APP_LANG.SI
                  ? "sinhala-typo sinhala-size-10"
                  : ""
              }`}
              style={
                props.history.location.pathname === "/complete-ad-review" ||
                props.history.location.pathname === "/pay-for-ad" ||
                props.history.location.pathname === "/checkout" ||
                props.history.location.pathname === "/checkout/bank-transfer"
                  ? { color: "#333333", fontWeight: 600 }
                  : null
              }
            >
              {t(finalStepLabel)}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default withRouter(ProgressBar);
