class PageConfig {
  constructor(
    id,
    pathname,
    title,
    description,
    accessLevels,
    platforms,
    template,
    hideFooter,
    hideNavBar
  ) {
    this.id = id;
    this.pathname = pathname;
    this.title = title;
    this.description = description;
    this.accessLevels = accessLevels;
    this.platforms = platforms;
    this.template = template;
    this.hideFooter = hideFooter;
    this.hideNavBar = hideNavBar;
  }
}

class _PageCofigContainer {
  constructor() {
    this.containerMap = new Map();
  }

  addConfig({
    id,
    pathname,
    title,
    description,
    accessLevels,
    platforms,
    template,
    hideFooter = false,
    hideNavBar = false,
  }) {
    const configObj = new PageConfig(
      id,
      pathname,
      title,
      description,
      accessLevels,
      platforms,
      template,
      hideFooter,
      hideNavBar
    );
    this.containerMap.set(id, configObj);
  }

  getConfig({ id }) {
    const pageConfig = this.containerMap.get(id);
    return pageConfig;
  }
}

const PageConfigContainer = new _PageCofigContainer();
export default PageConfigContainer;
