import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
} from "../../../utils/http-adapter";

const PhoneNumberApi = {
  addPhoneNumber: async ({ number, xTimestamp, xClientId }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "phonenumbers",
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
      body: { number },
      headers: {
        "x-timestamp": xTimestamp,
        "x-client-id": xClientId,
      },
    });
  },
  updateUserAccountPhoneNumber: async ({ token, otp }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/update_phonenumber",
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
      body: { token, otp },
    });
  },
};

export default PhoneNumberApi;
