import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import PhoneNumberInputView from "./phone-number-input-view";
import OTPVerificationView from "./otp-verification-view";
import DialCodeSelectorView from "./dial-code-selector-view";
import AppDialog from "../../../../components/molecules/dialog";

import "./style.css";

const MODAL_CONTENT = Object.freeze({
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  DIAL_CODE_SELECTOR: "DIAL_CODE_SELECTOR",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});

const PhoneNumberUpdateModal = ({ showModal, closeModal }) => {
  const { i18n } = useTranslation();
  const { initialized } = useSelector((state) => state.application);
  const [dialogTitle, setDialogTitle] = useState("");

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <AppDialog
      componentName="upsert-phone-number-modal"
      showModal={showModal}
      onCloseModal={onCloseModal}
      language={i18n.language}
      title={dialogTitle}
      initialized={initialized}
      keepMounted={false}
    >
      <Formik
        initialValues={{
          dialogContent: MODAL_CONTENT.PHONE_NUMBER_INPUT,
          countryCode: "",
          dialCode: "",
          phoneNumber: "",
          token: "",
          oneTimePassword: "",
        }}
      >
        {({ values }) => {
          const { dialogContent } = values;
          return (
            <>
              {dialogContent === MODAL_CONTENT.PHONE_NUMBER_INPUT && (
                <PhoneNumberInputView
                  setDialogTitle={setDialogTitle}
                  onCloseModal={onCloseModal}
                />
              )}
              {dialogContent === MODAL_CONTENT.DIAL_CODE_SELECTOR && (
                <DialCodeSelectorView setDialogTitle={setDialogTitle} />
              )}
              {dialogContent === MODAL_CONTENT.OTP_VERIFICATION && (
                <OTPVerificationView
                  setDialogTitle={setDialogTitle}
                  closeModal={onCloseModal}
                />
              )}
            </>
          );
        }}
      </Formik>
    </AppDialog>
  );
};

export default PhoneNumberUpdateModal;
