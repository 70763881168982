import React, { Fragment, useMemo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { isEmpty, kebabCase } from "lodash";
import Schema from "./schema/schema.json";
import FaqItem from "./faq-item";
import PageHead from "../../components/atoms/page-head";
import Footer from "../../../components/common/Footer";
import FooterMobile from "../../../components/common/FooterMobile";
import { Breadcrumbs, Button, Grid, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import Config from "../../config";
import "./style.css";
import withLanguageMigrator from "../../hoc/language-migrator";
import { useParams } from "react-router-dom";

const FaqPageIn = ({ lng }) => {
  const { FAQ_CATEGORIES } = Config;
  const routeParams = useParams();
  const history = useHistory();

  const faqCategory = useMemo(() => {
    const { category } = routeParams;
    if (!isEmpty(category)) {
      for (const current of Object.values(FAQ_CATEGORIES)) {
        if (kebabCase(current) === category) {
          return current;
        }
      }
    }
    return FAQ_CATEGORIES.ACCOUNT_CREATION;
  }, [FAQ_CATEGORIES, routeParams]);

  const categorySchema = Schema[faqCategory];

  return (
    <>
      <PageHead title={`Help | ${Config.DOMAIN_DISPLAY_NAME}`} />
      {/* <Navbar handleLng={handleLng} lng={lng} /> */}
      <Grid
        container
        xs={12}
        sm={10}
        md={8}
        direction="column"
        className="cus-container privacy-policy-content"
        style={{ padding: 20 }}
      >
        <Grid>
          <Button
            className="back-btn-in-detail-pages"
            onClick={() => history.goBack()}
            style={{ justifyContent: "flex-start" }}
          >
            <i className="fas fa-angle-left"></i> &nbsp; Back
          </Button>
        </Grid>
        <Typography className="faq-page-heading">Help Center</Typography>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
          style={{ alignItems: "center" }}
        >
          <Typography
            onClick={() => history.goBack()}
            className="faq-page-breadcrumb-txt"
          >
            Help
          </Typography>
          <Typography className="faq-page-breadcrumb-txt cursor-default">
            {categorySchema.heading || ""}
          </Typography>
        </Breadcrumbs>

        <Grid container direction="column">
          {Array.isArray(categorySchema.questionList) &&
            categorySchema.questionList.map((item, index) => (
              <Fragment key={index}>
                <Typography className="faq-page-question-list-title">
                  {item.title || ""}
                </Typography>
                {item.questions.map((question, index) => (
                  <FaqItem
                    key={index}
                    question={question.question}
                    answers={question.answers}
                  />
                ))}
              </Fragment>
            ))}
        </Grid>
      </Grid>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </>
  );
};

export default withLanguageMigrator(FaqPageIn);
