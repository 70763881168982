import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import Config from "../../../../config";
import {
  isStringContainsEmoji,
  isStringContainsNumbers,
  isStringContainsSpecialCharacters,
} from "../../../../utils";
import FormMeta from "./formMeta.json";

const {
  OTHER_COUNTRY_CODE,
  BASE_COUNTRY_CODE,
  OTHER_PROFESSION_ID,
  PLATFORMS,
  CURRENT_PLATFORM,
} = Config;

const CommonSchema = (t, displayNameFormats) => {
  return Yup.object().shape({
    fname: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.firstname") })
      )
      .min(
        FormMeta.firstName.min,
        t("errors.formFieldTooShort", { fieldName: t("common.firstname") })
      )
      .max(
        FormMeta.firstName.max,
        t("errors.formFieldTooLong", { fieldName: t("common.firstname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.firstname"),
        }),
      }),
    lname: Yup.string()
      .required(
        t("errors.formRequiredField", { fieldName: t("common.lastname") })
      )
      .max(
        FormMeta.lastName.max,
        t("errors.formFieldTooLong", { fieldName: t("common.lastname") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.lastname"),
        }),
      }),
    accountOwner: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.accountCreatedBy"),
      })
    ),
    displayNameFormat: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.displayNameFormat"),
      })
    ),
    // .test(
    //   "validate-format-with-name-fields",
    //   "",
    //   (currentFormat, context) => {
    //     if (!isEmpty(currentFormat)) {
    //       const { parent: formValues, createError, path } = context;
    //       const currentDef = displayNameFormats.find(
    //         (current) => current.value === currentFormat
    //       );
    //       const dependBy = currentDef?.dependBy ?? [];
    //       for (const fieldName of dependBy) {
    //         const fieldMetaDef =
    //           Object.values(FormMeta).find((current) => {
    //             return current.fieldName === fieldName;
    //           }) ?? {};
    //         if (isEmpty(fieldMetaDef)) {
    //           continue;
    //         }
    //         const fieldValue = formValues[fieldName];
    //         if (typeof fieldValue === "string" && fieldValue.length < 2) {
    //           return createError(
    //             {
    //               message: t("errors.insufficientCharLength", {
    //                 fieldName: t(fieldMetaDef.label),
    //                 length: 2,
    //               }),
    //             },
    //             path
    //           );
    //         }
    //       }
    //     }
    //     return true;
    //   }
    // ),
    email: Yup.string()
      .optional()
      .matches(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        t("errors.invalidFieldValue", { fieldName: t("common.email") })
      ),
    dob: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.birthdate") })
    ),
    gender: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.gender") })
    ),
    height: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.height") })
    ),
    religionId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.religion") })
    ),
    caste: Yup.string()
      .max(
        FormMeta.caste.max,
        t("errors.formFieldTooLong", { fieldName: t("common.caste") })
      )
      .test({
        test: (value) => {
          return !(
            isStringContainsEmoji(value) ||
            isStringContainsNumbers(value) ||
            isStringContainsSpecialCharacters(value)
          );
        },
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.caste"),
        }),
      }),
    civilStatusId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.civilStatus") })
    ),
    children: Yup.string().when("civilStatusId", ([civilStatusId], schema) => {
      if (Boolean(civilStatusId) && civilStatusId !== "never_married") {
        return schema.required(
          t("errors.formRequiredField", {
            fieldName: t("common.doYouHaveChildren"),
          })
        );
      }
    }),
    residentCity: Yup.string()
      .required(t("errors.formRequiredField", { fieldName: t("common.city") }))
      .matches(/^\D+$/, {
        message: t("errors.invalidFieldValue", {
          fieldName: t("common.city"),
        }),
      }),
    residentCountryCode: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("common.countryOfResidence"),
      })
    ),
    residentRegionId: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (residentCountryCode !== Config.OTHER_COUNTRY_CODE) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("common.stateOrDistrict"),
            })
          );
        }
      }
    ),
    residentCountryCodeOther: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (
          Boolean(residentCountryCode) &&
          residentCountryCode === OTHER_COUNTRY_CODE
        ) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("common.country"),
            })
          );
        }
      }
    ),
    visaTypeId: Yup.string().when(
      "residentCountryCode",
      ([residentCountryCode], schema) => {
        if (
          Boolean(residentCountryCode) &&
          residentCountryCode !== BASE_COUNTRY_CODE
        ) {
          return schema.required(
            t("errors.formRequiredField", {
              fieldName: t("createflow.visaType"),
            })
          );
        }
      }
    ),
    educationLevelId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.educationLevel") })
    ),
    professionId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.profession") })
    ),
    otherProfession: Yup.string().when(
      "professionId",
      ([_professionId], schema) => {
        let professionId =
          typeof _professionId === "string"
            ? parseInt(_professionId)
            : _professionId;
        if (professionId === OTHER_PROFESSION_ID) {
          return schema
            .required(
              t("errors.formRequiredField", {
                fieldName: t("common.typeProfession"),
              })
            )
            .max(
              FormMeta.otherProfession.max,
              t("errors.formFieldTooLong", {
                fieldName: t("common.typeProfession"),
              })
            )
            .test({
              test: (value) => {
                return !(
                  isStringContainsEmoji(value) ||
                  isStringContainsNumbers(value) ||
                  isStringContainsSpecialCharacters(value)
                );
              },
              message: t("errors.invalidFieldValue", {
                fieldName: t("common.typeProfession"),
              }),
            });
        }
      }
    ),
    drinking: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.drinking") })
    ),
    smoking: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.smoking") })
    ),
    foodPreferenceId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.foodPreference") })
    ),
    additionalInfo: Yup.string().optional(),
  });
};

const LankaSpecificSchema = (t) => {
  return Yup.object().shape({
    ethnicityId: Yup.string().required(
      t("errors.formRequiredField", { fieldName: t("common.ethnicity") })
    ),
  });
};

const IndiaSpecificSchema = (t) => {
  return Yup.object().shape({
    communityId: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.communityField"),
      })
    ),
    motherTongueId: Yup.string().required(
      t("errors.formRequiredField", {
        fieldName: t("createflow.motherTounge"),
      })
    ),
    gothraId: Yup.string().when("communityId", ([communityId], schema) => {
      if (!isEmpty(communityId) && communityId === "hindu") {
        return schema.required(t("errors.formRequiredField"), {
          fieldName: t("createflow.gothra"),
        });
      }
    }),
  });
};

const PlatformSpecificSchema = (t) => {
  switch (CURRENT_PLATFORM) {
    case PLATFORMS.IN:
      return IndiaSpecificSchema(t);
    default:
      return LankaSpecificSchema(t);
  }
};

const FormSchema = (t, displayNameFormats) => {
  const schema = CommonSchema(t, displayNameFormats);
  const platformSchema = PlatformSpecificSchema(t);
  return schema.concat(platformSchema);
};

export default FormSchema;
