import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty, sortBy } from "lodash";
import { Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import ReactCountryFlag from "react-country-flag";
import {
  DialogContent,
  DialogFooter,
  TextButton,
} from "../../../../../components/molecules/dialog";
import { useFormikContext } from "formik";

const MODAL_CONTENT = Object.freeze({
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  DIAL_CODE_SELECTOR: "DIAL_CODE_SELECTOR",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});

const DialCodeSelectorView = ({ setDialogTitle }) => {
  const { t } = useTranslation();
  const { secondaryCountries } = useSelector((state) => state.genericData);
  const { values, setValues, setFieldValue } = useFormikContext();
  const [searchNeedle, setSearchNeedle] = React.useState("");

  const filteredCountries = React.useMemo(() => {
    let validCountries = sortBy(
      secondaryCountries.filter((current) => !isEmpty(current.dialCode)),
      ["name"]
    );
    if (!isEmpty(searchNeedle)) {
      validCountries = validCountries.filter((current) =>
        current.name.toLowerCase().includes(searchNeedle.toLowerCase())
      );
    }
    return validCountries;
  }, [searchNeedle, secondaryCountries]);

  const onClickListItem = (selected) => {
    setValues({
      ...values,
      dialCode: selected.dialCode,
      countryCode: selected.code,
      dialogContent: MODAL_CONTENT.PHONE_NUMBER_INPUT,
    });
  };

  const onClickBackButton = () => {
    setFieldValue("dialogContent", MODAL_CONTENT.PHONE_NUMBER_INPUT);
  };

  React.useEffect(() => {
    setDialogTitle(t("settingPage.selectDialCode"))
  })

  return (
    <>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="dial-code-selector-view"
        >
          <Grid container direction="column" className="content-contaier">
            <TextField
              variant="outlined"
              focused={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className="search-text"
              value={searchNeedle}
              onChange={(e) => {
                setSearchNeedle(e.target.value);
              }}
            />
            <Grid container direction="column" className="dial-code-list">
              {filteredCountries.map((current) => (
                <Grid
                  key={current.code}
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  className="list-item"
                  onClick={() => {
                    onClickListItem(current);
                  }}
                >
                  <Grid
                    item
                    xs
                    container
                    direction="row"
                    alignItems="center"
                    style={{ columnGap: 8, flexWrap: "nowrap" }}
                  >
                    <ReactCountryFlag countryCode={current.code} svg />
                    <Typography className="list-item-txt">
                      {current.name}
                    </Typography>
                  </Grid>
                  <Typography className="list-item-txt">
                    {current.dialCode}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogFooter>
        <TextButton
          fullWidth
          label={t("common.backBtnText")}
          onClick={onClickBackButton}
        />
      </DialogFooter>
    </>
  );
};

export default DialCodeSelectorView;
