import httpAdapter, {
  ApiVersion,
  ContentType,
  HttpMethod,
} from "../../../utils/http-adapter";
import { isObject } from "lodash";

const AuthApi = {
  triggerOTP: async ({
    number,
    webOTP = true,
    captchaToken,
    xClientId,
    xTimestamp,
  }) => {
    return await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "auth/trigger_otp",
      authneticated: false,
      body: {
        number,
        webOTP,
        captchaToken,
      },
      contentType: ContentType.FORM_URL_ENCODED,
      headers: {
        "x-timestamp": xTimestamp,
        "x-client-id": xClientId,
      },
    });
  },
  verifyOTP: async ({ token, code, utmCookies = {} }) =>
    await httpAdapter.request({
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "auth/login_otp",
      authneticated: false,
      contentType: ContentType.FORM_URL_ENCODED,
      body: {
        token,
        code,
        ...((isObject(utmCookies) && utmCookies) || {}),
      },
    }),
  updateEmail: async ({ emailAddress }) => {
    return await httpAdapter.request({
      throwOtherthanAxiosErrors: true,
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/update_email",
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
      body: {
        email: emailAddress,
      },
    });
  },
  verifyEmailByOTP: async ({ token, otp }) => {
    return await httpAdapter.request({
      throwOtherthanAxiosErrors: false,
      method: HttpMethod.POST,
      apiVersion: ApiVersion.V1,
      path: "account/verify_email",
      authneticated: true,
      contentType: ContentType.FORM_URL_ENCODED,
      body: { token, otp },
    });
  },
};

export default AuthApi;
