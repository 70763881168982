import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import qs from "qs";

import { CircularProgress, Button } from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";

import Male from "../../images/groom.png";
import Female from "../../images/bride.png";
import Empty from "../../images/empty-profile.png";

import UnmatchModal from "../modals/UnmatchModal";
import AlertModal from "./detail-page/AlertModal";

import { setPreLoading } from "../../actions/setPreLoading";
import { removePost } from "../../actions/removePost";
import { fetchAllInterest } from "../../actions/fetchAllInterest";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { getInnerHeight } from "./methods/getInnerHeight";
import PostServiceApi from "../../v2/services/api/post-service";
import { isEmptyOrNullOrUndefined } from "../../v2/utils";

function MessageView(props) {
  const [message, setMessage] = useState("");
  const [rawMessages, setRawMessages] = useState([]);
  const [allMessage, setAllMessage] = useState([]);
  const [avatar, setAvatar] = useState(Empty);
  const [preLoading, setPreLoading] = useState(false);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);

  const { authProfile } = useSelector((state) => state.application);
  const { post } = props;

  useEffect(() => {
    const postId = authProfile.id;
    const matchId = post.id;
    const imageId = post.avatar;

    if (
      !isEmptyOrNullOrUndefined(postId) &&
      !isEmptyOrNullOrUndefined(matchId) &&
      !isEmptyOrNullOrUndefined(imageId)
    ) {
      PostServiceApi.getMatchImages({
        postId,
        matchId,
        imageId,
        thumbnail:true,
      }).then((res) => {
        let image = URL.createObjectURL(res.body);
        setAvatar(image);
      }).catch((err) => {
        console.log("Error >>>>>", err);
      })
    }
    getInnerHeight("message-content", 294);
  }, [authProfile, post]);

  useEffect(() => {
    // clear existing messages
    setAllMessage([]);
    setRawMessages([]);
    props.fetchAllInterest();

    setPreLoading(true);
    props.setPreLoading(true);
    setTimeout(() => {
      getAllMessages();
    }, 1000);
  }, [props.id]);

  useEffect(() => {
    if ((!props.post.isDisabledProfile && !props.post.ignored) &&
      (((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && !JSON.parse(localStorage.getItem("post_details")).isManaged) || (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2"))) {
      window.ws.onmessage = (event) => {
        let msgData = event.data
          ? event.data === "pong"
            ? event.data
            : JSON.parse(event.data)
          : {};
        if (msgData !== "pong") {
          if (props.id === msgData.data.interestId) {
            const rawData = [...rawMessages, msgData.data];

            setRawMessages(rawData);
            getMessages(rawData);

            scrollToBottom();
            if (msgData.data.sender === "OTHER_PARTY" && document.getElementById("message-content")) {
              seenMessages();
            }
          }
          // props.fetchAllInterest();
        }
      };
    }
  });

  const getAllMessages = () => {
    if (
      moment().format() <
      moment
        .utc(sessionStorage.getItem("chat_token_expires_in"))
        .local()
        .format()
    ) {
      getAllMessagesFun();
    } else {
      // chat_token
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          sessionStorage.setItem("chat_token", res.data.token);
          sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

          getAllMessagesFun();
        })
        .catch((err) => console.log(err));
    }
  };

  const getAllMessagesFun = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/chat_messages?token=${sessionStorage.getItem(
          "chat_token"
        )}&interestId=${props.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setRawMessages(res.data);
        getMessages(res.data);

        if (
          res.data.length > 0 &&
          res.data[res.data.length - 1].seen === false
        ) {
          seenMessages();
        }
        setPreLoading(false);
        props.setPreLoading(false);
        scrollToBottom();
      })
      .catch((err) => {
        setPreLoading(false);
        props.setPreLoading(false);
        console.log(err);
      });
  };

  const getMessages = (data) => {
    const groups = data.reduce((groups, msg) => {
      const date = moment.utc(msg.createdAt).local().format("YYYY-MM-DD");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupByDate = Object.keys(groups).map((date) => {
      return {
        date,
        message: groups[date],
      };
    });

    setAllMessage(groupByDate);
  };

  const seenMessages = () => {
    const data = `token=${sessionStorage.getItem("chat_token")}&interestId=${
      props.id
    }`;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/chat_messages/mark_seen`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            ContentType: "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        props.fetchAllInterest();
      })
      .catch((err) => console.log(err));
  };

  const handleSentMessage = (e) => {
    e.preventDefault();
    if (
      moment().format() <
      moment
        .utc(sessionStorage.getItem("chat_token_expires_in"))
        .local()
        .format()
    ) {
      sendMessageFun();
    } else {
      // chat_token
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/account/posts/${localStorage.getItem("postId")}/chat_token`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          sessionStorage.setItem("chat_token", res.data.token);
          sessionStorage.setItem("chat_token_expires_in", res.data.expiresAt);

          sendMessageFun();
        })
        .catch((err) => console.log(err));
    }
  };

  const sendMessageFun = () => {
    if (message) {
      const data = qs.stringify({
        token: sessionStorage.getItem("chat_token"),
        interestId: props.id,
        message: message,
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/chat_messages`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            ContentType: "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          setMessage("");
          scrollToBottom();

          document.getElementById("send-msg-now-icon").style.color = "#333333";
        })
        .catch((err) => console.log(err));
    }
  };

  const scrollToBottom = () => {
    var div = document.getElementById("message-content");
    if (div !== null) {
      div.scrollTop = div.scrollHeight - div.clientHeight;
    }
  };

  const handleViewProfile = (id, post) => {
    if (props.post.isDisabledProfile) {
      sessionStorage.setItem("unavailable_profile", JSON.stringify(post));
      props.history.push("/unavailable-profile");
    } else {
      const interest = post;
      const searchParams = new URLSearchParams();
      searchParams.append("id", interest.post.id);
      props.history.push({
        pathname: "/post",
        search: `?${searchParams.toString()}`
      });
    }
  };

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.post.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingIgnore(false);
        setUnmatchModal(false);

        window.location.reload();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  return (
    <React.Fragment>
      <div
        className={
          props.isEmptyPost ? "message-view-div" : "message-view-div show-view"
        }
      >
        <span
          className="dis-back-btn back-btn-msg"
          onClick={() => props.removePost()}
          style={{ margin: 15 }}
        >
          <i className="fas fa-angle-left"></i>{" "}
          {props.lng ? "Back" : "ආපසු යන්න"}
        </span>

        {props.post.post ? (
          <div className="profile-summery-div">
            <img
              src={
                props.post.avatar
                  ? avatar
                  : props.post.post.personalInfo.gender === "MALE"
                  ? Male
                  : Female
              }
              className="profile-pic"
              alt="profile pic"
            />
            <p className="name">
              {/* {props.post.post.personalInfo.fname +
                " " +
                props.post.post.personalInfo.lnameMasked} */}
                {props.post.post.personalInfo.displayName}
            </p>
            <p className="message">
              {props.post.post.personalInfo.age}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
              {props.post.post.personalInfo.residentCity}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
              {props.post.post.personalInfo.profession.name === "Other"
                ? props.post.post.personalInfo.professionOther
                : props.lng
                ? props.post.post.personalInfo.profession.name
                : props.post.post.personalInfo.profession.name_si}{" "}
              <span style={{ color: "#8c8c8c" }}>&#8226;</span>
              {Math.floor(
                Math.round(props.post.post.personalInfo.height / 2.54) / 12
              )}
              ' {Math.round(props.post.post.personalInfo.height / 2.54) % 12}"
            </p>
            <Link
              to="#"
              onClick={() => handleViewProfile(props.post.post.id, props.post)}
            >
              View Profile <i className="fas fa-angle-right"></i>
            </Link>
          </div>
        ) : null}
        <div className="message-content" id="message-content">
          {preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            allMessage.map((el, i) => (
              <div className="message-div" key={i}>
                <p className="message-date">
                  {moment.utc(el.date).local().format("ddd, DD MMM")}
                </p>
                {el.message.map((el, i) => (
                  <div
                    className={
                      el.sender === "SELF" ? "outgoing-msg" : "incomming-msg"
                    }
                    key={i}
                  >
                    <p className="message">{el.message}</p>
                    <p className="message-time">
                      {moment.utc(el.createdAt).local().format("hh:mm a")}
                    </p>
                  </div>
                ))}
              </div>
            ))
          )}
        </div>

        <div className="message-input-container" style={{ height: 115 }}>
          <div className="input-container">
            {props.post.post.isExpired && (
              <span className="is-expired">
                {props.lng ? (
                  "This ad has expired. You may not receive a response."
                ) : (
                  <span className="sinhala-size-10">
                    මෙම දැන්වීම කල් ඉකුත් වී ඇත. ඇතැම්විට ඔබට ප්‍රතිචාරයක්
                    නොලැබෙනු ඇත.
                  </span>
                )}
              </span>
            )}
            {!props.post.ignored && props.post.isDisabledProfile && (
              <span
                onClick={handleUnmatchModal}
                className="unmatch-profile-link"
              >
                Unmatch this profile
              </span>
            )}
            <textarea
              className="msg-field"
              type="text"
              placeholder={
                props.post.isDisabledProfile
                  ? props.lng
                    ? "You cannot send messages to this profile as it has been taken down by the advertiser."
                    : "ඔබට මෙම ගැලපීම වලට පණිවිඩ යැවිය නොහැක. මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත."
                  : props.post.ignored
                  ? props.lng
                    ? "You cannot send messages to this profile"
                    : "ඔබට මෙම ගැලපීම වලට පණිවිඩ යැවිය නොහැක"
                  : props.lng
                  ? "Send a message"
                  : "පණිවිඩයක් යවන්න"
              }
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onClick={scrollToBottom}
              autoComplete="off"
              disabled={props.post.ignored || props.post.isDisabledProfile || ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)}
              maxLength="1000"
              rows="4"
            />
            <Button
              type="button"
              id="send-message-btn"
              className="send-message-btn"
              onClick={handleSentMessage}
              disabled={props.post.ignored || props.post.isDisabledProfile || (localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2" && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)}
            >
              <SendIcon
                className="send-icon"
                id="send-msg-now-icon"
                style={{
                  color:
                    props.post.ignored || props.post.isDisabledProfile || ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)
                      ? "#ccc"
                      : "",
                  cursor:
                    props.post.ignored || props.post.isDisabledProfile || ((localStorage.getItem("user_role") !== "OPERATOR" && localStorage.getItem("user_role") !== "OPERATOR_L2") && localStorage.getItem("post_details") && JSON.parse(localStorage.getItem("post_details")).isManaged)
                      ? "default"
                      : "",
                }}
              />
            </Button>
          </div>
        </div>
      </div>

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        loading={props.loading}
      />

      <UnmatchModal
        lng={props.lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
        profileUserName={post?.post?.personalInfo?.fname}
      />
    </React.Fragment>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removePost,
      setPreLoading,
      fetchAllInterest,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.matches.post.id,
    post: state.matches.post,
    isEmptyPost:
      Object.keys(state.matches.post).length === 0 &&
      state.matches.post.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(MessageView));
