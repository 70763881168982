import * as Yup from "yup";
import { isEmpty } from "lodash";
import AppLogger from "../../utils/logger";
import AuthApi from "../../services/api/auth";
import store from "../../../store";
import { setAppSnackNotification } from "../../store/actions/application";
import { getAuthUserAccount } from "../application";
import PhoneNumberDomain from "../phone-number";

const EMAIL_UPSERT_DIALOG_CONTENT = Object.freeze({
  EMAIL_INPUT_VIEW: "EMAIL_INPUT_VIEW",
  OTP_VERIFICATION_VIEW: "OTP_VERIFICATION_VIEW",
});
const NO_OF_OTP_DIGITS = 6;

const PHONE_NUMBER_UPSERT_DIALOG_CONTENT = Object.freeze({
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  DIAL_CODE_SELECTOR: "DIAL_CODE_SELECTOR",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});

const validateEmailAddress = async ({
  emailAddress,
  config: { fieldName, required = false, t },
  errorsCollector = {},
}) => {
  try {
    const fieldSchema = Yup.string()
      .required(t("errors.formRequiredField", { fieldName: t("common.email") }))
      .email(t("errors.invalidFieldValue", { fieldName: t("common.email") }));
    await fieldSchema.validate(emailAddress, {
      abortEarly: true,
      recursive: true,
    });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      errorsCollector[fieldName] = error.message;
      return;
    }
    throw error;
  } finally {
    return errorsCollector;
  }
};

const validateOneTimePassword = async ({
  oneTimePassword,
  config: { fieldName, required = false, t },
  errorsCollector,
}) => {
  try {
    const fieldSchema = Yup.string()
      .required(
        t("errors.formRequiredField", {
          fieldName: t("settingPage.oneTimePassword"),
        })
      )
      .min(
        NO_OF_OTP_DIGITS,
        t("errors.invalidFieldValue", {
          fieldName: t("settingPage.oneTimePassword"),
        })
      );
    await fieldSchema.validate(oneTimePassword, {
      abortEarly: true,
      recursive: true,
    });
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      errorsCollector[fieldName] = error.message;
      return;
    }
    throw error;
  } finally {
    return errorsCollector;
  }
};

const onSubmitEmailUpsertForm = ({
  currentView,
  values,
  t,
  setErrors,
  setDialogContent,
}) => {
  switch (currentView) {
    case EMAIL_UPSERT_DIALOG_CONTENT.EMAIL_INPUT_VIEW:
      handleTriggerEmailOTP({
        email: values.emailAddress,
        t,
        setErrors,
        setDialogContent,
      });
      break;
    default:
      break;
  }
};

export const handleTriggerEmailOTP = async ({
  emailAddress,
  t,
  setErrors,
  setFieldValue,
  isSubmitting,
  setSubmitting,
}) => {
  try {
    if (isSubmitting) {
      return;
    }
    setSubmitting(true);
    const errorsCollector = await validateEmailAddress({
      emailAddress,
      config: { fieldName: "emailAddress", required: true, t },
      errorsCollector: {},
    });
    if (!isEmpty(errorsCollector)) {
      setErrors(errorsCollector);
      return;
    }
    const response = await AuthApi.updateEmail({ emailAddress });
    if (!response?.success) {
      if (!response?.statusCode) {
        setErrors({ emailAddress: t("errors.unknownErrorOccurred") });
        return;
      }
      const errorCode = response.body?.code;
      switch (errorCode) {
        case 1201:
          setErrors({
            emailAddress: t("settingPage.otpRetryCountdown", {
              countdown: response.body?.retryIn || 30,
            }),
          });
          break;
        case 1202:
          setErrors({ emailAddress: t("settingPage.verificationCodeInvalid") });
          break;
        case 1108:
          setErrors({ emailAddress: t("settingPage.phoneNumberUsedAnotherAccount") });
          break;
        default:
          setErrors({ emailAddress: t("errors.unknownErrorOccurred") });
          break;
      }
      return;
    }
    setFieldValue("token", response.body?.id);
    setFieldValue("retryTimeout", response.body?.retryIn || 30);
    setFieldValue(
      "dialogContent",
      EMAIL_UPSERT_DIALOG_CONTENT.OTP_VERIFICATION_VIEW
    );
  } catch (error) {
    AppLogger.exception(error);
    setErrors({ emailAddress: t("errors.unknownErrorOccurred") });
  } finally {
    setSubmitting(false);
  }
};

const verifyEmailAddress = async ({
  token,
  oneTimePassword,
  t,
  setErrors,
  closeModal,
}) => {
  try {
    const errorsCollector = await validateOneTimePassword({
      oneTimePassword,
      config: { fieldName: "oneTimePassword", required: true, t },
      errorsCollector: {},
    });
    if (!isEmpty(errorsCollector)) {
      setErrors(errorsCollector);
      return;
    }
    const apiResponse = await AuthApi.verifyEmailByOTP({
      token,
      otp: oneTimePassword,
    });
    if (!apiResponse?.success) {
      if (!apiResponse?.statusCode) {
        setErrors({ oneTimePassword: t("errors.unknownErrorOccurred") });
        return;
      }
      const errorCode = apiResponse.body?.code;
      console.log(">>>>> errorCode >>>> ", errorCode);
      switch (errorCode) {
        case 1202:
          setErrors({
            oneTimePassword: t("settingPage.verificationCodeInvalid"),
          });
          break;
        default:
          setErrors({ oneTimePassword: t("errors.unknownErrorOccurred") });
          break;
      }
      return;
    }
    store.dispatch(
      setAppSnackNotification({
        severity: "success",
        message: t("common.updatedsuccessfully"),
      })
    );
    store.dispatch(getAuthUserAccount());
    closeModal();
  } catch (error) {
    AppLogger.exception(error);
    setErrors({ oneTimePassword: t("errors.unknownErrorOccurred") });
  } finally {
  }
  return true;
};

const triggerPhoneNumberOTP = async ({
  dialCode,
  phoneNumber,
  setFieldValue,
  setSubmitting,
  setFieldError,
  t,
}) => {
  try {
    setSubmitting(true);
    const response = await PhoneNumberDomain.addPhoneNumber({
      number: `${dialCode}${phoneNumber}`,
    });
    if (isEmpty(response) || !response?.success) {
      if (isEmpty(response)) {
        setFieldError("phoneNumber", t("errors.unknownErrorOccurred"));
        return;
      }
      const errorCode = response.body.code;
      if (!errorCode) {
        setFieldError("phoneNumber", t("errors.unknownErrorOccurred"));
      } else {
        if (errorCode === 1201) {
          setFieldError(
            "phoneNumber",
            t("settingPage.tooManyAttemptsTimeCountDown", { timeCountDown: 30 })
          );
        } else {
          setFieldError("phoneNumber", t(`serverErrors.${errorCode}`));
        }
      }
      if (errorCode) {
        setFieldError("phoneNumber", t(`serverErrors.${errorCode}`));
      } else {
        setFieldError("phoneNumber", t("errors.unknownErrorOccurred"));
      }
      return;
    }
    setFieldValue("token", response.body.id);
    setFieldValue(
      "dialogContent",
      PHONE_NUMBER_UPSERT_DIALOG_CONTENT.OTP_VERIFICATION
    );
  } catch (error) {
    AppLogger.exception(error);
  } finally {
    setSubmitting(false);
  }
};

const onClickPhoneNumberRetryTriggerOTP = async ({
  dialCode,
  phoneNumber,
  setFieldValue,
  setRetryOtpHelperText,
  t,
}) => {
  try {
    const response = await PhoneNumberDomain.addPhoneNumber({
      number: `${dialCode}${phoneNumber}`,
    });
    if (isEmpty(response) || !response.success) {
      const errorCode = response.body.code;
      if (errorCode) {
        setRetryOtpHelperText(t(`serverErrors.${errorCode}`));
      } else {
        setRetryOtpHelperText(t("errors.unknownErrorOccurred"));
      }
      return;
    }
    setFieldValue("token", response.body.id);
  } catch (error) {
    AppLogger.exception(error);
    setRetryOtpHelperText(t("errors.unknownErrorOccurred"));
  } finally {
  }
};

const retrySendEmailOTP = async ({
  emailAddress,
  setSubmitting,
  setRetryOTPError,
  setFieldValue,
  t,
}) => {
  try {
    setSubmitting(true);
    const response = await AuthApi.updateEmail({ emailAddress });
    if (!response?.success) {
      if (!response?.statusCode) {
        setRetryOTPError(t("errors.unknownErrorOccurred"));
        return;
      }
      const errorCode = response.body?.code;
      switch (errorCode) {
        case 1201:
          setRetryOTPError(
            t("settingPage.otpRetryCountdown", {
              countdown: response.body?.retryIn || 30,
            })
          );
          break;
        case 1202:
          setRetryOTPError(t("settingPage.verificationCodeInvalid"));
          break;
        default:
          setRetryOTPError(t("errors.unknownErrorOccurred"));
          break;
      }
      return;
    }
    setFieldValue("token", response.body?.id);
    setFieldValue("retryTimeout", response.body?.retryIn || 30);
  } catch (error) {
    AppLogger.exception(error);
  } finally {
    setSubmitting(false);
  }
};

const AccountPageDomain = {
  onSubmitEmailUpsertForm,
  handleTriggerEmailOTP,
  verifyEmailAddress,
  triggerPhoneNumberOTP,
  onClickPhoneNumberRetryTriggerOTP,
  retrySendEmailOTP,
  config: {
    EMAIL_UPSERT_DIALOG_CONTENT,
    NO_OF_OTP_DIGITS,
    PHONE_NUMBER_UPSERT_DIALOG_CONTENT,
  },
};
export default AccountPageDomain;
