import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Paper, Grid, Typography, CircularProgress } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { isEmpty } from "lodash";

import { connect, useSelector } from "react-redux";

import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";
import heart from "../../../images/heart.png";
import PostServiceApi from "../../../v2/services/api/post-service";
import { isEmptyOrNullOrUndefined } from "../../../v2/utils";

const Header = (props) => {
  const [imageData, setImageData] = useState([]);
  const {authProfile} = useSelector((state) => state.application);

  useEffect(() => {
    setImageData([]);
    const postId = authProfile.id;
    const matchId = props?.id ?? null;
    const images = props.images ?? [];

    if (
      !isEmptyOrNullOrUndefined(postId) &&
      !isEmptyOrNullOrUndefined(matchId) &&
      !isEmptyOrNullOrUndefined(images)
    ) {
      for (const imageId of images) {
        PostServiceApi.getMatchImages({
          postId,
          matchId,
          imageId,
          thumbnail: false,
        })
          .then((res) => {
            let data = URL.createObjectURL(res.body);
            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log("Error >>>>", err);
          });
      }
    }
  }, [props.id, props.images, authProfile]);

  let cancelTime =
    props.post.timeline &&
    props.post.timeline.filter((filter) => filter.eventType === "UNLIKED");

  let unmatched =
    props.post.timeline &&
    props.post.timeline.filter((filter) => filter.eventType === "UNMATCHED");

  let withdrawn =
    props.post.timeline &&
    props.post.timeline.filter((filter) => filter.eventType === "LIKE_REFUSED");

  const getLiked = () => {
    if (cancelTime.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getMatched = () => {
    if (unmatched.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getSent = () => {
    if (withdrawn.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ minHeight: "10vh", position: "relative" }}
          className="complete-ad-div settings-container post-details-con"
        >
          {props.preLoading ? (
            <div className="loading-div">
              <CircularProgress className="loading-circle" />
            </div>
          ) : (
            <React.Fragment>
              {props.post.post && (
                <div className="post-details-col ">
                  {props.post.post.personalInfo.gender === "MALE" ? (
                    <img src={groom} className="applicant-photo" alt="groom" />
                  ) : (
                    <img src={bride} className="applicant-photo" alt="bride" />
                  )}

                  <Typography variant="h6" className="name">
                    <span style={{ textTransform: "capitalize" }}>
                      {/* {props.post.post.personalInfo.fname}{" "}
                      {props.post.post.personalInfo.lnameMasked} */}
                      {props.isMatchActive && props.post.post.personalInfo && props.post.post.personalInfo.fname && getMatched() ?
                        props.post.post.personalInfo.fname+" "+props.post.post.personalInfo.lname
                        :
                        props.post.post.personalInfo.displayName
                      }
                    </span>
                  </Typography>
                  <p className="location">
                    {props.post.post.personalInfo.age}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.post.personalInfo.residentCity}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {props.post.post.personalInfo.profession.name === "Other"
                      ? props.post.post.personalInfo.professionOther
                      : props.lng
                      ? props.post.post.personalInfo.profession.name
                      : props.post.post.personalInfo.profession.name_si}{" "}
                    <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                    {Math.floor(
                      Math.round(props.post.post.personalInfo.height / 2.54) /
                        12
                    )}
                    '{" "}
                    {Math.round(props.post.post.personalInfo.height / 2.54) %
                      12}
                    "
                  </p>
                </div>
              )}
              {props.post.post && (
                <div className="post-date-col">
                  <p align="right" className="date-of-post">
                    {props.post.post.personalInfo.residentCountry.code ===
                    "" ? (
                      <i
                        className="fas fa-globe-americas"
                        data-tip="Other Country"
                      ></i>
                    ) : (
                      <ReactCountryFlag
                        countryCode={
                          props.post.post.personalInfo.residentCountry.code
                        }
                        svg
                        style={{
                          fontSize: "1.25em",
                        }}
                        aria-label={
                          props.lng
                            ? props.post.post.personalInfo.residentCountry.name
                            : props.post.post.personalInfo.residentCountry
                                .name_si
                        }
                        data-tip={
                          props.lng
                            ? props.post.post.personalInfo.residentCountry.name
                            : props.post.post.personalInfo.residentCountry
                                .name_si
                        }
                      />
                    )}
                  </p>
                </div>
              )}
              {props.post.post.createdBy ? (
                <label className="createdby-label">
                  {props.post.post.createdBy === "SELF"
                    ? props.lng
                      ? "Account created by: Self"
                      : "ගිණුම සාදන ලද්දේ : තමන් විසින්"
                    : props.lng
                    ? "Account created by : Parents / Family"
                    : "ගිණුම සාදන ලද්දේ: දෙමවුපියන් / පවුලේ අය"}
                </label>
              ) : null}

              {props.isSentActive &&
                (getSent() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; Interest Sent
                  </p>
                ) : null)}

              {props.isMatchActive &&
                (getMatched() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; Matched
                  </p>
                ) : null)}

              {props.isReceivedActive &&
                (getLiked() ? (
                  <p className="likes-you">
                    <img className="heart-icon" src={heart} alt="heart" />
                    &nbsp; Interest Received
                  </p>
                ) : null)}

              {props.post.isDisabledProfile ? null : props.isMatchActive ? (
                !props.post.ignored ? (
                  JSON.parse(localStorage.getItem("post_details")).images
                    .length > 0 ? (
                    imageData.length > 0 ? (
                      <PhotoProvider>
                        {imageData.map((item, index) => (
                          <PhotoConsumer key={index} src={item}>
                            <img src={item} alt="" className="photos" onContextMenu={(e) => { e.preventDefault() }} />
                          </PhotoConsumer>
                        ))}
                      </PhotoProvider>
                    ) : (
                      <p className="invisible-photos">
                        <i className="fas fa-eye-slash"></i>{" "}
                        {props.lng
                          ? "This user has not uploaded pictures to their profile. Your pictures will not be shared with them."
                          : "මෙම පරිශීලකයා ඔවුන්ගේ දැන්වීමට පින්තූර ඇතුලත් කර නොමැත. එම නිසා ඔබේ පින්තූර ඔවුන්ට පෙන්වන්නේ ද නොමැත."}
                      </p>
                    )
                  ) : (
                    <p className="invisible-photos" style={{ marginBottom: 0 }}>
                      <i className="fas fa-eye-slash"></i>{" "}
                      {props.lng
                        ? "Upload pictures to your profile to view pictures of your matches"
                        : "ඔබගේ ගැලපීම් වල පින්තූර බැලීමට ඔබගේ දැන්වීම සඳහා ද පින්තූර ඇතුලත් විය යුතුය."}{" "}
                      <a
                        href="/edit-my-ad?scroll=toPhotos"
                        style={{ color: "#2196F3" }}
                      >
                        {props.lng
                          ? "Upload your photos"
                          : "ඔබගේ පින්තූර ඇතුලත් කරන්න"}
                      </a>
                    </p>
                  )
                ) : props.post.restricted ? (
                  <p className="invisible-photos" style={{ marginBottom: 0 }}>
                    <i className="fas fa-eye-slash"></i>{" "}
                    {props.lng ? (
                      "You cannot view details of this profile once unmatched."
                    ) : (
                      <span className="sinhala-w-600 sinhala-size-14">
                        ගැලපුමක් ඉවත් කල පසු එහි විස්තර නොපෙන්වයි.
                      </span>
                    )}
                  </p>
                ) : null
              ) : null}

              {props.post.isDisabledProfile || props.post.restricted
                ? null
                : props.post.post.personalInfo.additionalInfo && (
                    <p
                      className="desc"
                      style={{ marginBottom: 0, marginTop: 20 }}
                    >
                      {props.post.post.personalInfo.additionalInfo}
                    </p>
                  )}

              {!props.isMatchActive && (
                <p
                  className="invisible-photos"
                  style={{ marginBottom: 0, marginTop: 32 }}
                >
                  <i className="fas fa-eye-slash"></i>{" "}
                  {props.lng
                    ? "Private information such as full name, birth date, pictures, contact details and horoscope information are only visible to matched profiles."
                    : "සම්පූර්ණ නම, උපන් දිනය, පින්තූර, දුරකථන අංක සහ කේන්දර තොරතුරු පෙන්වන්නේ ගැලපීම් වලට පමණි."}
                </p>
              )}
            </React.Fragment>
          )}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.matches.post.id,
    post: state.matches.post,
    images: state.matches.post
      ? state.matches.post.post
        ? state.matches.post.post.images
          ? state.matches.post.post.images
          : []
        : []
      : [],
    isMatchActive: state.matches.activeBtnType.match,
    isReceivedActive: state.matches.activeBtnType.received,
    isSentActive: state.matches.activeBtnType.sent,
  };
};

export default connect(mapStateToProps, null)(Header);
