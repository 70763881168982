import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import store from "../../../store";
import Config from "../../config";
import { useDispatch } from "react-redux";
import {
  getAuthUserAccount,
  getAuthUserProfile,
} from "../../features/application";
import isFunction from "lodash/isFunction";

const { AUTH_REVALIDATION_TIMESTAMP_KEY } = Config;

const handleBankTransferCompletionRedirect = (
  bankTxRedirectStatus,
  history
) => {
  switch (bankTxRedirectStatus) {
    case Config.BANK_TRANSFER_STATUS.REDIRECT_NEW:
      history.push("/newcash-success");
      break;
    case Config.BANK_TRANSFER_STATUS.REDIRECT_RENEW:
      history.push("/renewcash-success");
      break;
    default:
      break;
  }
};

const handleRevalidationScenarios = ({
  authProfile,
  setSholdRevalidate,
  history,
}) => {
  try {
    const { bankTxRedirectStatus } = authProfile;
    if (bankTxRedirectStatus) {
      handleBankTransferCompletionRedirect(bankTxRedirectStatus, history);
    }
  } catch (error) {
    console.log(">>>> Error ", error);
  } finally {
    if (isFunction(setSholdRevalidate)) {
      setSholdRevalidate(false);
    }
    sessionStorage.removeItem(AUTH_REVALIDATION_TIMESTAMP_KEY);
  }
};

const withRevalidateAuth = (BaseComponent) => {
  return (props) => {
    const history = useHistory();
    const { initialized, authProfile } = store.getState().application;
    const dispatch = useDispatch();
    const [shouldRevalidate, setSholdRevalidate] = useState(false);

    useEffect(() => {
      if (!initialized || shouldRevalidate) return;
      const checkerTimestamp = sessionStorage.getItem(
        Config.AUTH_REVALIDATION_TIMESTAMP_KEY
      );
      const currentTimestamp = Date.now();
      if (!checkerTimestamp) {
        sessionStorage.setItem(
          Config.AUTH_REVALIDATION_TIMESTAMP_KEY,
          currentTimestamp
        );
        return;
      }
      const lastRevalidation = JSON.parse(checkerTimestamp);
      const difference = Math.ceil(
        Math.abs(currentTimestamp - lastRevalidation) / 1000
      );
      if (difference >= Config.AUTH_REVALIDATION_INTERVAL) {
        setSholdRevalidate(true);
        return;
      }
    }, [dispatch, history, initialized, shouldRevalidate]);

    useEffect(() => {
      if (!shouldRevalidate) {
        return;
      }
      dispatch(getAuthUserProfile());
      dispatch(getAuthUserAccount());
    }, [dispatch, setSholdRevalidate, shouldRevalidate]);

    useEffect(() => {
      if (!shouldRevalidate) {
        return;
      }
      handleRevalidationScenarios({ authProfile, setSholdRevalidate, history });
    }, [authProfile, history, shouldRevalidate]);

    return <BaseComponent {...props} />;
  };
};

export default withRevalidateAuth;
