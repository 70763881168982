import * as Sentry from "@sentry/react";
import Config from "../../config";

const {
  SENTRY_ID,
  TARGET_ENVIRONMENT,
  TARGET_ENVIRONMENTS,
  NODE_ENV,
  CURRENT_PLATFORM,
  PLATFORMS,
} = Config;

class Logger {
  static LEVEL_PRIORITY = ["fatal", "error", "warning", "log", "info", "debug"];
  currentSeverity = [Logger.LEVEL_PRIORITY[0], Logger.LEVEL_PRIORITY[1]];

  constructor() {
    const shouldEnabled = this._getEnableConfig();
    if (shouldEnabled) {
      Sentry.init({
        dsn: SENTRY_ID,
        debug: true,
        environment: TARGET_ENVIRONMENT,
        enabled: true,
        tracesSampleRate: 1.0,
        beforeSend: (event) => {
          const memberId = localStorage.getItem("memberId");
          event.user = { memberId, id: memberId };
          if (this.currentSeverity.includes(event.level)) {
            return event;
          }
        },
      });
    }
  }

  isInitialized = () => {
    return Sentry.isInitialized();
  };

  _getEnableConfig() {
    if (NODE_ENV === "production") {
      if (CURRENT_PLATFORM === PLATFORMS.LK) {
        return true;
      }
      if (
        CURRENT_PLATFORM === PLATFORMS.IN &&
        TARGET_ENVIRONMENT === TARGET_ENVIRONMENTS.PRODUCTION
      ) {
        return true;
      }
    }
    // TODO: Remove when Sentry Optimization is done
    // if (
    //   NODE_ENV === "development" &&
    //   CURRENT_PLATFORM === PLATFORMS.LK &&
    //   TARGET_ENVIRONMENT === TARGET_ENVIRONMENTS.DEVELOP
    // ) {
    //   return true;
    // }
    return false;
  }

  log(message) {
    try {
      console.log("[LOG]:", message);
      if (this.isInitialized()) {
        Sentry.captureMessage(message, "log");
      }
    } catch (error) {
      console.error("[EXCEPTION][APPLOGGER#LOG]:", error);
    }
  }

  debug(...args) {
    try {
      console.log("[DEBUG]:", ...args);
    } catch (error) {
      console.error("Error! [AppLogger#debug] ", error);
    }
  }

  exception(exception) {
    try {
      console.error(exception);
      if (this.isInitialized()) {
        Sentry.captureException(exception);
      }
    } catch (error) {
      console.error("Error", error);
    }
  }
}

const AppLogger = new Logger();
export default AppLogger;
