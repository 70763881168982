import { createReduxAction } from "../../../utils";

export const SET_GENERIC_DISPLAY_NAME_FORMATS =
  "SET_GENERIC_DISPLAY_NAME_FORMATS";
export const SET_GENERIC_CIVIL_STATUS = "SET_GENERIC_CIVIL_STATUS";
export const SET_GENERIC_ETHNICITIES = "SET_GENERIC_ETHNICITIES";
export const SET_GENERIC_RELIGIONS = "SET_GENERIC_RELIGIONS";
export const SET_GENERIC_GENDERS = "SET_GENERIC_GENDERS";
export const SET_GENERIC_COUNTRIES = "SET_GENERIC_COUNTRIES";
export const SET_GENERIC_SECONDARY_COUNTRIES =
  "SET_GENERIC_SECONDARY_COUNTRIES";
export const SET_GENERIC_EDUCATION_LEVELS = "SET_GENERIC_EDUCATION_LEVELS";
export const SET_GENERIC_FOOD_PREFERENCES = "SET_GENERIC_FOOD_PREFERENCES";
export const SET_GENERIC_PROFESSIONS = "SET_GENERIC__PROFESSIONS";
export const SET_GENERIC_VISA_STATUS = "SET_GENERIC_VISA_STATUS";
export const SET_GENERIC_HIEGHT_OPTIONS = "SET_GENERIC_HIEGHT_OPTIONS";
export const SET_FORM_OPTIONS = "SET_FORM_OPTIONS";
export const SET_GENERIC_HAS_CHILDREN_OPTIONS = "SET_HAS_CHILDREN_OPTIONS";
export const SET_GENERIC_DRINKING_PREFERENCES =
  "SET_GENERIC_DRINKING_PREFERENCES";
export const SET_GENERIC_SMOKING_PREFERENCES =
  "SET_GENERIC_SMOKING_PREFERENCES";
export const SET_GENERIC_INITIALIZED = "SET_GENERIC_INITIALIZED";
export const SET_GENERIC_GOTHRAS = "SET_GENERIC_GOTHRAS";
export const SET_GENERIC_MOTHER_TOUNGES = "SET_GENERIC_MOTHER_TOUNGES";
export const SET_GENERIC_STAR_OPTIONS = "SET_GENERIC_STAR_OPTIONS";
export const SET_GENERIC_STAR_SIGN_OPTIONS = "SET_GENERIC_STAR_SIGN_OPTIONS";
export const SET_GENERIC_HAVE_DOSH_OPTIONS = "SET_GENERIC_HAVE_DOSH_OPTIONS";
export const SET_GENERIC_ACCOUNT_OWNERSHIP = "SET_GENERIC_ACCOUNT_OWNERSHIP";
export const SET_GENERIC_DIFFERENTLY_ABLED = "SET_GENERIC_DIFFERENTLY_ABLED";
export const SET_GENERIC_NIC_VERIFIED = "SET_GENERIC_NIC_VERIFIED";
export const SET_GENERIC_ALL_COLLECTION = "SET_GENERIC_ALL_COLLECTION";

export const setGenericDisplayNameFormats = (payload) =>
  createReduxAction(SET_GENERIC_DISPLAY_NAME_FORMATS, payload);

export const setGenders = (payload) =>
  createReduxAction(SET_GENERIC_GENDERS, payload);

export const setSortedCivilStatus = (payload) =>
  createReduxAction(SET_GENERIC_CIVIL_STATUS, payload);

export const setSortedEthnicities = (payload) =>
  createReduxAction(SET_GENERIC_ETHNICITIES, payload);

export const setSortedReligions = (payload) =>
  createReduxAction(SET_GENERIC_RELIGIONS, payload);

export const setSortedCountries = (payload) =>
  createReduxAction(SET_GENERIC_COUNTRIES, payload);

export const setSortedEducationLevels = (payload) =>
  createReduxAction(SET_GENERIC_EDUCATION_LEVELS, payload);

export const setSortedFoodPreferences = (payload) =>
  createReduxAction(SET_GENERIC_FOOD_PREFERENCES, payload);

export const setSortedProfessions = (payload) =>
  createReduxAction(SET_GENERIC_PROFESSIONS, payload);

export const setSortedVisaStatus = (payload) =>
  createReduxAction(SET_GENERIC_VISA_STATUS, payload);

export const setHeightOptions = (payload) =>
  createReduxAction(SET_GENERIC_HIEGHT_OPTIONS, payload);

export const setFormOptions = (payload) =>
  createReduxAction(SET_FORM_OPTIONS, payload);

export const setHasChildrenOpitons = (payload) =>
  createReduxAction(SET_GENERIC_HAS_CHILDREN_OPTIONS, payload);

export const setSortedSecondaryCountries = (payload) =>
  createReduxAction(SET_GENERIC_SECONDARY_COUNTRIES, payload);

export const setDrinkingPreferences = (payload) =>
  createReduxAction(SET_GENERIC_DRINKING_PREFERENCES, payload);

export const setSmokingPreferences = (payload) =>
  createReduxAction(SET_GENERIC_SMOKING_PREFERENCES, payload);

export const setGenericDataInitilized = (payload) =>
  createReduxAction(SET_GENERIC_INITIALIZED, payload);

export const setGenericGothraOptions = (payload) =>
  createReduxAction(SET_GENERIC_GOTHRAS, payload);

export const setGenericMotherTounges = (payload) =>
  createReduxAction(SET_GENERIC_MOTHER_TOUNGES, payload);

export const setGenericStarOptions = (payload) =>
  createReduxAction(SET_GENERIC_STAR_OPTIONS, payload);

export const setGenericStarSignOptions = (payload) =>
  createReduxAction(SET_GENERIC_STAR_SIGN_OPTIONS, payload);

export const setGenericHaveDoshOptions = (payload) =>
  createReduxAction(SET_GENERIC_HAVE_DOSH_OPTIONS, payload);

export const setAccountOwnershipOptions = (payload) =>
  createReduxAction(SET_GENERIC_ACCOUNT_OWNERSHIP, payload);

export const setDifferentlyAbledOptions = (payload) =>
  createReduxAction(SET_GENERIC_DIFFERENTLY_ABLED, payload);

export const setNicVerifiedOptions = (payload) =>
  createReduxAction(SET_GENERIC_NIC_VERIFIED, payload);

export const setGenericAllCollection = (payload) =>
  createReduxAction(SET_GENERIC_ALL_COLLECTION, payload);
