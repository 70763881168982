import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Typography,
  Box,
} from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { useTranslation } from "react-i18next";

import { isEmptyOrNullOrUndefined } from "../../v2/utils";

function UnmatchModal({
  unmatchModal,
  handleModalClose,
  loadingIgnore,
  handleIgnoreAllInt,
  profileUserName,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={unmatchModal}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-title">
        {t("modals.unmatchUser")}
      </DialogTitle>
      <DialogContent style={{ padding: "8px 15px" }}>
        <DialogContentText id="alert-dialog-description">
          {t("modals.sureToUnmatchUser")}
        </DialogContentText>
        <Box className="modal-banner">
          <WarningRoundedIcon
            style={{
              color: "#f54646",
            }}
          />
          <Box style={{ marginLeft: "5px" }}>
            <Typography variant="subtitle1" className="modal-banner-title">
              {t("modals.warning")}
            </Typography>
            <Typography className="modal-banner-description" variant="body2">
              {t("modals.notBeAbleToMatch", {
                profileUserName: !isEmptyOrNullOrUndefined(profileUserName)
                  ? profileUserName
                  : t("modals.them"),
              })}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} variant="outlined">
          {t("modals.cancel")}
        </Button>
        <Button
          onClick={() => handleIgnoreAllInt("unmatch")}
          variant="contained"
          className="modal-primary-btn"
        >
          {loadingIgnore ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            t("modals.unmatch")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnmatchModal;
