import React, { useEffect, useMemo } from "react";
import { Typography, Button, Breadcrumbs } from "@material-ui/core";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import AccountCreation from "../components/faq/AccountCreation";
import Matching from "../components/faq/Matching";
import SettingsFAQ from "../components/faq/SettingsFAQ";
import "../styles/new-faq.css";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { NavigateNext } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { kebabCase, isEmpty } from "lodash";
import RouteConfigHandler from "../v2/templates/route-config-handler";

const { FAQ_CATEGORIES } = Config;

const QuestionsAndAnswers = ({ category }) => {
  switch (category) {
    case FAQ_CATEGORIES.MATCHING_WITH_OTHER_ADS:
      return <Matching />;
    case FAQ_CATEGORIES.SETTINGS:
      return <SettingsFAQ />;
    default:
      return <AccountCreation />;
  }
};
/**
 * TODO: Move this Page into V2 Pages later
 */
const FAQPage = ({ routeId, history, lng, t }) => {
  const routeParams = useParams();
  const { category } = routeParams;

  const faqCategory = useMemo(() => {
    if (!isEmpty(category)) {
      for (const currentCategory of Object.values(FAQ_CATEGORIES)) {
        if (kebabCase(currentCategory) === category) {
          return currentCategory;
        }
      }
    }
    return FAQ_CATEGORIES.ACCOUNT_CREATION;
  }, [category]);

  const sectionTitle = useMemo(() => {
    switch (faqCategory) {
      case FAQ_CATEGORIES.MATCHING_WITH_OTHER_ADS:
        return t("supports.matchingWithOtherAds");
      case FAQ_CATEGORIES.SETTINGS:
        return t("supports.settings");
      default:
        return lng ? "Account Creation" : "ගිණුමක් සෑදීම";
    }
  }, [lng, faqCategory, t]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);
  
  return (
    <RouteConfigHandler id={routeId}>
      <div className="new-faq-wrapper">
        <div className="new-faq-div">
          <div className="new-faq-col-main">
            <Typography align="center" className="new-faq-page-heading">
              {t("supports.helpCenter")}
            </Typography>
            <div className="new-faq-mobile">
              <div className="new-faq-heading">
                <Breadcrumbs
                  separator={<NavigateNext />}
                  style={{ alignItems: "center" }}
                >
                  <Typography
                    onClick={() => history.push("/help")}
                    className="faq-page-breadcrumb-txt"
                  >
                    Help
                  </Typography>
                  <Typography className="faq-page-breadcrumb-txt cursor-default">
                    {sectionTitle}
                  </Typography>
                </Breadcrumbs>
                <Button
                  className="back-btn-in-detail-pages"
                  onClick={() => history.goBack()}
                  style={{ margin: "10px 0" }}
                >
                  <i className="fas fa-angle-left"></i> &nbsp;
                  {t("common.backBtnText")}
                </Button>
                <Typography className="faq-page-question-list-title">
                  {sectionTitle}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <QuestionsAndAnswers category={faqCategory} />
      </div>
      <div className="create-floor-footer-desktop">
        <Footer lng={lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={lng} />
      </div>
    </RouteConfigHandler>
  );
};

export default withRouter(withLanguageMigrator(FAQPage));
