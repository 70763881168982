import React from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  Dialog as MuiDialog,
  Typography,
  Button,
} from "@material-ui/core";
import CssClassNameBuilder from "../../../utils/css-class-name-builder";
import { isEmpty, isString } from "lodash";
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import DialogContext from "./context";

const DialogCloseButton = ({ onCloseModal, initialized }) => {
  return (
    <IconButton size="small" className="close-icon" onClick={onCloseModal}>
      {initialized ? (
        <CloseRoundedIcon />
      ) : (
        <Skeleton variant="circle" width={24} />
      )}
    </IconButton>
  );
};

const AppDialog = ({
  componentName,
  showModal,
  onCloseModal,
  language,
  title,
  initialized = false,
  keepMounted = true,
  children,
}) => {
  const rootCSSClasses = React.useMemo(() => {
    const builder = new CssClassNameBuilder("generic-dialog");
    builder.add(language);
    return `${builder.build()}${
      isString(componentName) && !isEmpty(componentName)
        ? ` ${componentName}`
        : ""
    }`;
  }, [componentName, language]);

  return (
    <DialogContext.Provider value={{ initialized }}>
      <MuiDialog
        open={showModal}
        className={rootCSSClasses}
        keepMounted={keepMounted}
      >
        <Grid container direction="column">
          <Grid className="header-container">
            <Grid item xs>
              <Typography variant="h6" className="heading-txt capitalized-text">
                {initialized ? (
                  !isEmpty(title) && isString(title) && title
                ) : (
                  <Skeleton variant="text" className="skeleton" />
                )}
              </Typography>
            </Grid>
            <DialogCloseButton
              initialized={initialized}
              onCloseModal={onCloseModal}
            />
          </Grid>
          {children}
        </Grid>
      </MuiDialog>
    </DialogContext.Provider>
  );
};

export const DialogContent = ({
  children,
  paddingY = 0,
  rowGap = 0,
  justifyContent = "center",
}) => {
  const { initialized } = React.useContext(DialogContext);
  return (
    <Grid
      container
      direction="column"
      className="dialog-content"
      style={{ paddingBlock: paddingY, rowGap, justifyContent }}
    >
      {initialized ? children : <Skeleton variant="rect" height={120} />}
    </Grid>
  );
};

export const DialogFooter = ({ children }) => {
  const { initialized } = React.useContext(DialogContext);

  return (
    <Grid container direction="row" className="dialog-footer">
      {initialized ? (
        children
      ) : (
        <Skeleton variant="rect" style={{ height: 30, flex: 1 }} />
      )}
    </Grid>
  );
};

export const PrimaryAction = ({
  onClick,
  processing = false,
  label,
  disabled = false,
  fullWidth,
}) => {
  return (
    <Button
      variant="contained"
      size="small"
      className="dialog-primary-action"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={processing && <CircularProgress size={12} color="inherit" />}
    >
      {label}
    </Button>
  );
};

export const TextButton = ({
  onClick,
  label,
  disabled = false,
  fullWidth,
  processing = false,
}) => {
  return (
    <Button
      variant="text"
      size="small"
      className="secondary-text-button"
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      startIcon={processing && <CircularProgress size={12} color="inherit" />}
    >
      {label}
    </Button>
  );
};

export default AppDialog;
