import { isEmpty, isFunction } from "lodash";
import store from "../../../store";
import moment from "moment";
import { APP_LANG } from "../../utils/locale-handler";
import Constants from "../../config/constant";
import {
  getCommunitiesByReligionId,
  getRegionsByCountryCode,
} from "../generic-data";
import {
  OfflineResponseDisplayModel,
  ParentDisplayModel,
  PrivateInformationDisplayModel,
} from "../../models/profile";
import {
  ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS,
  ACCOUNT_CREATED_BY_OPTIONS,
  ADDITIONAL_COMMUNITY_OPTIONS,
  ENABLE_BOOLEAN_OPTIONS,
  HAS_CHILDREN_OPTIONS,
  HOROSCOPE_MATCHING_OPTIONS,
  PARENT_OPTIONS,
} from "../../static-data";
import Config from "../../config";
import { calculateNextStep } from "../../pages/create-flow/templates/stepper/extensions";
import { setAppCreateFlowInitialized } from "../../store/actions/application";
import AppLogger from "../../utils/logger";

const { CURRENT_PLATFORM, PLATFORMS } = Config;
const DATE_PATTERN = "YYYY-MM-DD";

export const DataDisplaySection = Object.freeze({
  personalInformation: "personalInformation",
  parentInformation: "parentInformation",
  privateInformation: "privateInformation",
  offlineResponses: "offlineResponses",
});

const generateExtractionKeyByLang = (lang, extractionKey) => {
  const isEnglish = lang === APP_LANG.EN;
  return isEnglish ? extractionKey : `${extractionKey}_${lang}`;
};

const generateDisplayValueByMeta = (meta, extractionKey, lang) => {
  return isEmpty(meta)
    ? ""
    : meta[generateExtractionKeyByLang(lang, extractionKey)];
};

const generatePersonalInformation = async (tempProfile, lang) => {
  const { genericData } = store.getState();
  const {
    genderOptions,
    ethnicities,
    religions,
    civilStatusOptions,
    countries,
    secondaryCountries,
    visaStatusOptions,
    educationLevels,
    professions,
    drinkingPreferences,
    foodPreferences,
    smokingPrefernces,
    heightOptions,
    motherTongues,
    gothraOptions,
  } = genericData;
  const output = {};

  const personalInfo = tempProfile.postData?.personalInfo ?? {};
  const accountOwnerMeta = ACCOUNT_CREATED_BY_OPTIONS.find(
    (element) => element.value === tempProfile.postData?.accountOwner
  );

  if (!isEmpty(accountOwnerMeta)) {
    output.accountOwner = generateDisplayValueByMeta(
      accountOwnerMeta,
      "label",
      lang
    );
  }

  output.firstName = personalInfo.fname ?? "";
  output.lastName = personalInfo.lname ?? "";
  // Age calculation
  if (!isEmpty(personalInfo.dob)) {
    output.age = moment().diff(moment(personalInfo.dob, DATE_PATTERN), "years");
  }
  // Gender
  const genderMeta = genderOptions.find(
    (element) => element.value === personalInfo.gender
  );
  output.gender = generateDisplayValueByMeta(genderMeta, "label", lang);
  // Ethnicity
  const ethnicityMeta = ethnicities.find(
    (element) => element.id === personalInfo.ethnicityId
  );
  output.ethnicity = generateDisplayValueByMeta(ethnicityMeta, "name", lang);
  // Religion
  const religionMeta = religions.find(
    (element) => element.id === personalInfo.religionId
  );
  output.religion = generateDisplayValueByMeta(religionMeta, "name", lang);

  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    if (personalInfo?.gothraId) {
      const gothraMeta = gothraOptions.find(
        (element) => element.id === personalInfo?.gothraId
      );
      output.gothra = gothraMeta.name || "";
    }
    if (personalInfo.communityId) {
      if (personalInfo?.religionId) {
        const religionId = personalInfo.religionId;
        try {
          const response = await getCommunitiesByReligionId({ religionId });
          if (!response.success) {
            throw new Error("unable to fetch communities");
          }
          const communities = response.body || [];

          const communityMeta = communities.find(
            (element) => element.id === personalInfo.communityId
          );
          output.community = communityMeta?.name || "";
        } catch (e) {
          AppLogger.exception(e);
        }
      }
    } else {
      const communityMeta = ADDITIONAL_COMMUNITY_OPTIONS.find(
        (element) => element.id === personalInfo?.communityOther
      );
      output.community =
        generateDisplayValueByMeta(communityMeta, "name", lang) || "";
    }

    if (personalInfo?.subCommunity) {
      output.subCommunity = personalInfo.subCommunity || "";
    }
    if (personalInfo?.motherTongueId) {
      const motherTongueMeta = motherTongues.find(
        (element) => element.id === personalInfo?.motherTongueId
      );
      output.motherTongue = motherTongueMeta?.name || "";
    }
  }
  // Caste
  output.caste = personalInfo.caste ?? "";
  // Civil Status
  const civilStatusMeta = civilStatusOptions.find(
    (element) => element.id === personalInfo.civilStatusId
  );
  // Height option
  const heightOption = heightOptions.find(
    (element) => element.value === personalInfo.height
  );
  output.height = heightOption?.name ?? "";
  output.civilStatus = generateDisplayValueByMeta(
    civilStatusMeta,
    "status",
    lang
  );

  if (personalInfo.children) {
    const hasChildrenMeta = HAS_CHILDREN_OPTIONS.find(
      (element) => element.value === personalInfo.children
    );
    output.hasChildren = generateDisplayValueByMeta(
      hasChildrenMeta,
      "label",
      lang
    );
  }

  // Resident Details
  let residentCountry = "";
  let residentRegion = "";
  let visaType = "";
  if (personalInfo.residentCountryCode !== Constants.OTHER_COUNTRY_CODE) {
    const countryMeta = countries.find(
      (element) => element.code === personalInfo.residentCountryCode
    );
    residentCountry = generateDisplayValueByMeta(countryMeta, "name", lang);
    const countryRegions = await getRegionsByCountryCode({
      countryCode: personalInfo.residentCountryCode,
      appLanguage: lang,
    });
    const regionMeta = countryRegions.find(
      (element) => element.id === personalInfo.residentRegionId
    );
    residentRegion = generateDisplayValueByMeta(regionMeta, "regionName", lang);
  } else {
    const countryMeta = secondaryCountries.find(
      (element) => element.code === personalInfo.residentCountryCodeOther
    );
    residentCountry = isEmpty(countryMeta) ? "" : countryMeta.name;
  }

  // if (personalInfo.residentCountryCode !== Constants.LANKA_COUNTRY_CODE) {
  if (personalInfo.residentCountryCode !== Config.CURRENT_PLATFORM) {
    const visaStatusMeta = visaStatusOptions.find(
      (element) => element.id === personalInfo.visaTypeId
    );
    visaType = generateDisplayValueByMeta(visaStatusMeta, "visaType", lang);
  }

  output.residentCountry = residentCountry;
  output.residentRegion = residentRegion;
  output.visaType = visaType;
  output.residentCity = personalInfo.residentCity ?? "";
  // Education Level
  const educationMeta = educationLevels.find(
    (element) => element.id === personalInfo.educationLevelId
  );
  output.educationLevel = generateDisplayValueByMeta(
    educationMeta,
    "level",
    lang
  );
  let profession = "";
  if (personalInfo.professionId === Config.OTHER_PROFESSION_ID) {
    profession = personalInfo.otherProfession;
  } else {
    const professionMeta = professions.find(
      (element) => element.id === personalInfo.professionId
    );
    profession = generateDisplayValueByMeta(professionMeta, "name", lang);
  }
  output.profession = profession;

  // habits
  const drinkingMeta = drinkingPreferences.find(
    (element) => element.value === personalInfo.drinking
  );
  output.drinking = generateDisplayValueByMeta(drinkingMeta, "label", lang);
  const foodPreferenceMeta = foodPreferences.find(
    (element) => element.id === personalInfo.foodPreferenceId
  );
  output.foodPreference = generateDisplayValueByMeta(
    foodPreferenceMeta,
    "type",
    lang
  );
  const smokingMeta = smokingPrefernces.find(
    (element) => element.value === personalInfo.smoking
  );
  output.smoking = generateDisplayValueByMeta(smokingMeta, "label", lang);
  output.additionalInfo =
    tempProfile.postData?.personalInfo?.additionalInfo ?? "";

  return output;
};

const generateParentInformation = async (lang) => {
  const { PARENT_TYPE } = Constants;
  const output = {
    [PARENT_TYPE.FATHER]: ParentDisplayModel(PARENT_TYPE.FATHER),
    [PARENT_TYPE.MOTHER]: ParentDisplayModel(PARENT_TYPE.MOTHER),
  };
  const { application, genericData } = store.getState();
  const { authTempProfile } = application;
  const { ethnicities, religions, professions, countries } = genericData;

  if (
    isEmpty(authTempProfile) ||
    isEmpty(authTempProfile.postData?.parentInfo)
  ) {
    return output;
  }

  const parentInfo = authTempProfile.postData.parentInfo;
  if (Array.isArray(parentInfo)) {
    for (const current of parentInfo) {
      const currentOutput = output[current.type];
      currentOutput.additionalInfo = current.additionalInfo ?? "";
      currentOutput.caste = current.caste ?? "";
      const parentTypeMeta = PARENT_OPTIONS.find(
        (element) => element.value === current.type
      );
      currentOutput.parentType = generateDisplayValueByMeta(
        parentTypeMeta,
        "label",
        lang
      );
      const ethnicityMeta = ethnicities.find(
        (element) => element.id === current.ethnicityId
      );
      currentOutput.ethnicity = generateDisplayValueByMeta(
        ethnicityMeta,
        "name",
        lang
      );
      const religionMeta = religions.find(
        (element) => element.id === current.religionId
      );
      currentOutput.religion = generateDisplayValueByMeta(
        religionMeta,
        "name",
        lang
      );
      let profession = "";

      if (CURRENT_PLATFORM === PLATFORMS.IN) {
        if (current?.communityId) {
          if (current.religionId) {
            const religionId = current.religionId;
            try {
              const response = await getCommunitiesByReligionId({ religionId });
              if (!response.success) {
                throw new Error();
              }
              const communities = response?.body || [];
              const communityMeta = communities.find(
                (element) => element.id === current?.communityId
              );
              currentOutput.community = communityMeta?.name || "";
            } catch (e) {
              AppLogger.exception(e);
            }
          }
        } else {
          const communityMeta = ADDITIONAL_COMMUNITY_OPTIONS.find(
            (element) => element.id === current?.communityOther
          );
          currentOutput.community =
            generateDisplayValueByMeta(communityMeta, "name", lang) || "";
        }

        if (!isEmpty(current?.subCommunity)) {
          currentOutput.subCommunity = current.subCommunity || "";
        }
      }

      if (current.professionId === Config.OTHER_PROFESSION_ID) {
        profession = current.otherProfession;
      } else {
        const professionMeta = professions.find(
          (element) => element.id === current.professionId
        );
        profession = generateDisplayValueByMeta(professionMeta, "name", lang);
      }
      currentOutput.profession = profession;
      const countryMeta = countries.find(
        (element) => element.code === current.residentCountryCode
      );
      currentOutput.residentCountry = generateDisplayValueByMeta(
        countryMeta,
        "name",
        lang
      );
    }
  }

  return output;
};

const generatePrivateInformation = async (lang) => {
  const output = { ...PrivateInformationDisplayModel };
  const { application, genericData } = store.getState();
  const { countries, starOptions, starSignOptions, haveDoshOptions } =
    genericData;
  const { authTempProfile, authAccount } = application;
  if (isEmpty(authTempProfile?.postData)) {
    return output;
  }
  const { postData } = authTempProfile;
  output.birthCity = postData.birthCity ?? "";
  output.email = authAccount.email ?? "";
  output.birthdate = postData.personalInfo?.dob ?? "";
  output.additionalInfo = postData.horoscopeDetail ?? "";
  output.images = postData.images ?? [];
  const horoscopeMatchingMeta = HOROSCOPE_MATCHING_OPTIONS.find(
    (element) => element.value === postData.horoscopeMatching?.toString()
  );
  output.horoscopeMatching = generateDisplayValueByMeta(
    horoscopeMatchingMeta,
    "label",
    lang
  );
  if (!isEmpty(postData.birthTime)) {
    output.birthTime = moment(postData.birthTime, "kk:mm").format("hh:mm A");
  }

  if (!isEmpty(postData.personalInfo?.originCountryCode)) {
    const { originCountryCode } = postData.personalInfo;
    const countryMeta = countries.find(
      (element) => element.code === originCountryCode
    );
    output.birthCountry = generateDisplayValueByMeta(countryMeta, "name", lang);
  }
  if (!isEmpty(authAccount)) {
    output.phoneNumber = authAccount.phoneNumber ?? "";
  }

  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    if (postData?.acceptOtherCaste) {
      const acceptOtherCasteMeta = ACCEPT_FROM_OTHER_COMMUNITY_OPTIONS.find(
        (element) => element.value === postData?.acceptOtherCaste
      );
      output.acceptOtherCaste = acceptOtherCasteMeta?.label || "";
    }

    const starId = postData?.personalInfo?.starId;
    if (starId) {
      const starMeta = starOptions.find((element) => element.id === starId);
      output.star = starMeta?.name || "";
    }

    const startSignId = postData?.personalInfo?.starSignId;

    if (startSignId) {
      const startSignMeta = starSignOptions.find(
        (element) => element.id === startSignId
      );
      output.startSign = startSignMeta.name || "";
    }

    if (postData?.hasDosh) {
      const haveDoshMeta = haveDoshOptions.find(
        (element) => element?.value === postData?.hasDosh
      );
      output.haveDosh = haveDoshMeta?.label || "";
    }
  }

  return output;
};

const generateOfflineResponseInformation = async (lang) => {
  const output = { ...OfflineResponseDisplayModel };
  const { application, genericData } = store.getState();
  const { authAccount } = application;
  const { countries } = genericData;
  if (isEmpty(authAccount)) {
    return output;
  }
  const { userPreferences, address } = authAccount;
  if (!isEmpty(userPreferences)) {
    const showOfflineIdMeta = ENABLE_BOOLEAN_OPTIONS.find(
      (element) => element.value === userPreferences.showOfflineId.toString()
    );
    output.showOfflineId = generateDisplayValueByMeta(
      showOfflineIdMeta,
      "label",
      lang
    );
    const subscribeMagazineMeta = ENABLE_BOOLEAN_OPTIONS.find(
      (element) =>
        element.value === userPreferences.subscribeMagazine.toString()
    );
    output.subscribeMagazine = generateDisplayValueByMeta(
      subscribeMagazineMeta,
      "label",
      lang
    );

    if (!isEmpty(address)) {
      output.nameOfRecipient = address.name ?? "";
      output.addressLine1 = address.line1 ?? "";
      output.addressLine2 = address.line2 ?? "";
      output.city = address.city ?? "";
      if (!isEmpty(address.country)) {
        const countryMeta = countries.find(
          (element) => element.code === address.country.code
        );
        output.country = generateDisplayValueByMeta(countryMeta, "name", lang);
      }
    }
  }

  return output;
};

export const generateDisplayValues = async ({ section, lang }) => {
  const rootState = store.getState();
  const { authTempProfile } = rootState.application;

  if (isEmpty(authTempProfile)) {
    return {};
  }

  switch (section) {
    case DataDisplaySection.personalInformation:
      return generatePersonalInformation(authTempProfile, lang);
    case DataDisplaySection.parentInformation:
      return generateParentInformation(lang);
    case DataDisplaySection.privateInformation:
      return generatePrivateInformation(lang);
    case DataDisplaySection.offlineResponses:
      return generateOfflineResponseInformation(lang);
    default:
      return {};
  }
};

export const redirectToCreateFlowStep =
  ({ setActiveStep, history }) =>
  (dispatch) => {
    const {
      initialized,
      createflowInitialized,
      authProfile,
      authAccount,
      authTempProfile,
    } = store.getState().application;
    if (!initialized || createflowInitialized) {
      return;
    }
    if (!isEmpty(authProfile) && isFunction(setActiveStep)) {
      setActiveStep(3);
    }
    const tempProfile = authTempProfile?.postData ?? {};
    const nextStep = calculateNextStep({ tempProfile, authAccount });
    if (
      history &&
      history.constructor === Object &&
      isFunction(history.push) &&
      isFunction(setActiveStep)
    ) {
      history.push(`${nextStep.route}`);
      setActiveStep(nextStep.index);
    }
    dispatch(setAppCreateFlowInitialized(true));
  };
