import React from "react";
import { Paper, Grid } from "@material-ui/core";
import RegisterAccOTP from "./RegisterAccOTP";

function RegisterNewCom(props) {
  return (
    <Grid container>
      <Paper elevation={0}>
        <RegisterAccOTP lng={props.lng} />
      </Paper>
    </Grid>
  );
}

export default RegisterNewCom;
