import { Button, Grid, Typography } from "@material-ui/core";
import { BlockOutlined as BlockIcon } from "@material-ui/icons";
import React from "react";

import "./style.css";
import { useSelector } from "react-redux";
import { ROUTE_ACCESS_LEVEL } from "../../../config/constant";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const getProhibitedReasonCode = ({
  pageConfig,
  currentRouteAccessLevel: authRouteAccess,
}) => {
  const pageRouteAccesses = pageConfig.accessLevels ?? [];

  const isOnlyProfileUser =
    Array.isArray(pageRouteAccesses) &&
    pageRouteAccesses.length === 1 &&
    pageRouteAccesses[0] === ROUTE_ACCESS_LEVEL.PROFILE_USER;
  if (
    isOnlyProfileUser &&
    authRouteAccess === ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER
  ) {
    return 1001;
  }

  const isOnlyPublicUser =
    Array.isArray(pageRouteAccesses) &&
    pageRouteAccesses.length === 1 &&
    pageRouteAccesses[0] === ROUTE_ACCESS_LEVEL.PUBLIC_USER;
  if (
    isOnlyPublicUser &&
    authRouteAccess !== ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER
  ) {
    return 1002;
  }

  return 1003;
};

const AccessDeniedView = ({ pageConfig }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { authRouteAccess } = useSelector((state) => state.application);

  const reasonCode = getProhibitedReasonCode({
    pageConfig,
    authRouteAccess,
  });

  const buttonText =
    reasonCode === 1001
      ? t(`errors.prohibitedViewDescriptionButtonText${reasonCode}`)
      : t("errors.prohibitedViewGotoHomePage");

  const handleMainActionBtn = () => {
    let nextRoute = "/search";
    switch (reasonCode) {
      case 1001:
        nextRoute = "/complete-ad-personal";
        break;
      default:
        break;
    }
    history.replace(nextRoute);
  };

  return (
    <Grid
      container
      direction="row"
      className="prohibited-access-view"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid
        item
        xs={11}
        md={8}
        lg={6}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="content-container"
      >
        <Grid item container direction="column" alignItems="center">
          <BlockIcon className="block-icon" />
          <Typography variant="h6" align="center" className="page-heading">
            {t("errors.prohibitedViewDescriptionHeading")}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            className="error-description"
          >
            {t(`errors.prohibitedViewDescription${reasonCode}`)}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          style={{ rowGap: 16 }}
        >
          <Button
            variant="contained"
            className="action-button"
            onClick={handleMainActionBtn}
          >
            {buttonText}
          </Button>
          {reasonCode === 1001 && (
            <Grid container direction="column" alignItems="center">
              <Typography variant="caption" align="center">
                {t("errors.prohibitedViewOR")}
              </Typography>
              <Button
                variant="text"
                style={{ textDecorationLine: "underline" }}
                // onClick={}
              >
                {t(`errors.prohibitedViewGotoHomePage`)}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccessDeniedView;
