import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import Header from "./detail-page/InterestProfileHeader";
import Connect from "./detail-page/InterestProfileConnect";
import TimeLine from "./detail-page/ProfileTimeLine";
import UnmatchModal from "../modals/UnmatchModal";
import AlertModal from "./detail-page/AlertModal";
import { fetchInterestPost } from "../../actions/fetchInterestPost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Info from "../../images/info.png";
import UnavailableProfile from "./interest-com/UnavailableProfile";
import Config from "../../v2/config";

function PersonalProfile(props) {
  const [preLoading, setPreLoading] = useState(false);
  const [unmatchModal, setUnmatchModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [loadingIgnore, setLoadingIgnore] = useState(false);
  const [timeLine, setTimeLine] = useState({});
  const [postDetails, setPostDetails] = useState([]);
  const [post, setPost] = useState([]);
  const [postId, setPostId] = useState("");

  useEffect(() => {
    if (props.loading.constructor === Boolean) {
      setPreLoading(props.loading);
    }
  }, [props.loading]);

  useEffect(() => {
    getProfile();
  }, [props.intId, props.messagePostData]);

  const handleUnmatchModal = () => {
      setUnmatchModal(true);
  };

  const handleIgnoreAllInt = (type) => {
    setLoadingIgnore(true);
    axios
      .delete(
        `${
          process.env.REACT_APP_API_URL
        }/v1/account/posts/${localStorage.getItem("postId")}/interests/${
          props.intId
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingIgnore(false);
        setUnmatchModal(false);

        window.location.reload();
      })
      .catch((err) => {
        setLoadingIgnore(false);
      });
  };

  const handleModalClose = () => {
    setUnmatchModal(false);
    setAlertModal(false);
  };

  const getProfile = () => {
    if (props.messagePostData) {
      setPostId(props.messagePostData.post.id);
      setPost(props.messagePostData);
      setPostDetails(props.messagePostData);
      setTimeLine(props.messagePostData.timeline);
    }
  };

  const handleDeleteInterest = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem("postId")}/interests/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        }
      )
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="profile-view-div">
        {props.loading ? (
          <div className="loading-div">
            <CircularProgress className="loading-circle" />
          </div>
        ) : (
          <>
          {props.messagePostData.archived ? <UnavailableProfile  lng={props.lng} /> : (
            <>
            <Header postDetails={postDetails} lng={props.lng} intId={props.intId} isMatchActive={true}/>
            {post.isDisabledProfile ||
            (timeLine.length > 0 &&
              timeLine.filter((el) => el.eventType === "UNMATCHED")
                .length > 0) ? null : (
              <Connect post={post} lng={props.lng} isMatchActive={true} />
            )}
            <TimeLine lng={props.lng} handleUnmatchModal={handleUnmatchModal} post={post} />
            {((Object.keys(postDetails).length > 0) && (postDetails.isDisabledProfile || postDetails.restricted)) ? null : (
              <Link
                to={`/post?id=${postId}`}
                className="view-profile"
              >
                {props.lng ? "View Full Profile >" : "සම්පූර්ණ දැන්වීම බලන්න"}
              </Link>
            )}
            {(Config.TARGET_ENVIRONMENT === Config.TARGET_ENVIRONMENTS.DEVELOP) &&
              <button onClick={() => handleDeleteInterest(props.intId)}>Remove Interest</button>
            }

            {postDetails.isDisabledProfile && (
              <div className="disable-div" style={{ marginTop: 0 }}>
                <p align="center" style={{ marginBottom: 0 }}>
                  <img
                    src={Info}
                    alt="info"
                    style={{ width: 50, opacity: 0.7 }}
                  />
                </p>
                <p className="message">
                  {props.lng ? "Ad is unavailable" : "දැන්වීම නොමැත"}
                </p>
                <p align="center" className="desc">
                  {props.lng ? (
                    "This advertisement has been taken down by its owner."
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-14">
                      මෙම දැන්වීම එහි හිමිකරු විසින් ඉවත් කරගෙන ඇත.
                    </span>
                  )}
                </p>
              </div>
            )} 
            </>
          )}
          </>
        )}
      </div>

      <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        loading={props.loading}
      />

      <UnmatchModal
        lng={props.lng}
        unmatchModal={unmatchModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
        profileUserName={props.post?.post?.personalInfo?.fname}
      />
    </React.Fragment>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInterestPost,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    messagePostData: state.matches.messagePostData
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(PersonalProfile));
