import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import Footer from "../components/common/Footer";
import Status from "../components/settings/Status";
import LoginInfo from "../components/settings/LoginInfo";
import PwdChange from "../components/settings/PwdChange";
import Notification from "../components/settings/Notification";
import Mailing from "../components/settings/Mailing";
import RemoveAd from "../components/settings/RemoveAd";
import HideAd from "../components/settings/HideAd";
import NicUpload from "../components/settings/NicUpload";
import Config from "../v2/config";
import withLanguageMigrator from "../v2/hoc/language-migrator";
import { scrollIntoElement, scrollToTop } from "../v2/utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { SettingsProvider } from "../components/settings/settingsContext";
import RouteConfigHandler from "../v2/templates/route-config-handler";

const Settings = ({ lng, routeId, t }) => {
  const location = useLocation();
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const authToken = localStorage.getItem("auth_token");
    scrollToTop();

    const state = location.state;
    if (authToken && state) {
      scrollIntoElement({ id: state });
    }
  }, [location]);

  return (
    <RouteConfigHandler id={routeId}>
      <SettingsProvider value={{ emailError, setEmailError }}>
        <div style={{ background: "#dfdfdf", paddingTop: 5 }}>
          <Typography
            variant="h3"
            align="center"
            className="create-new-ad"
            style={{ paddingBottom: 30, fontWeight: 500 }}
          >
            {t("common.settings")}
          </Typography>
          <Grid container className="cus-container complete-ad-container">
            <Status lng={lng} />
            <LoginInfo lng={lng} />
            <PwdChange lng={lng} />
            <Notification lng={lng} />
            {Config.OFFLINE_RESPONSES_VISIBILITY && <Mailing lng={lng} />}
            <NicUpload lng={lng} />
            {localStorage.getItem("postId") && <HideAd lng={lng} />}
            {localStorage.getItem("postId") && <RemoveAd lng={lng} />}
          </Grid>
        </div>
        <Footer lng={lng} />
      </SettingsProvider>
    </RouteConfigHandler>
  );
};

export default withLanguageMigrator(Settings);
