import React, { useContext, useEffect, useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { Grid, Button } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEqual } from "lodash";

import BasicInformation from "./templates/basic-infomation";
import AppContext from "../../../context";
import CreateFlowPage from "../templates/create-flow-page";
import ResidencyInfo from "./templates/residency-info";
import EducationProfession from "./templates/eduction-and-profession";
import Habbits from "./templates/habbits";
import AdditionalInfomation from "./templates/additional-infomation";
import FormSchema from "./schema";
import "./style.css";
import "../../../../styles/complete-ad.css";
import { useDispatch, useSelector } from "react-redux";
import { bindTemporaryProfile, saveTempProfile } from "./extensions";
import { setAppSnackNotification } from "../../../store/actions/application";
import formMeta from "./schema/formMeta.json";
import { buildModelByFormMeta } from "../../../utils";
import { scrollOnError } from "../../../utils";

const Form = ({ children }) => {
  const { authTempProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { values, setFieldValue, resetForm, dirty } = useFormikContext();
  const [shadowData, setShadowData] = useState({});

  const formData = useMemo(() => {
    const metaModel = buildModelByFormMeta(formMeta);
    // Bindings: Default Values
    metaModel.differentlyAbled = formMeta.differentlyAbled.defaultValue;
    // Bindings: AuthTempProfile & AuthAccount
    bindTemporaryProfile({ input: metaModel, authTempProfile, authAccount });
    return metaModel;
  }, [authTempProfile, authAccount]);

  useEffect(() => {
    resetForm({ values: formData });
    setShadowData(formData);
  }, [formData, resetForm]);

  useEffect(() => {
    if (!(dirty && !isEqual(values, shadowData))) {
      return;
    }

    if (shadowData.residentCountryCode !== values.residentCountryCode) {
      setFieldValue("residentRegionId", "");
      setFieldValue("residentCity", "");
    }
    if (shadowData.residentRegionId !== values.residentRegionId) {
      setFieldValue("residentCity", "");
    }
    if (shadowData.religionId !== values.religionId) {
      setFieldValue("communityId", "");
    }
    setShadowData(values);
  }, [values, dirty, setFieldValue, shadowData]);

  return <>{children}</>;
};

const initialValues = buildModelByFormMeta(formMeta);

const PersonalCreateAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authTempProfile, authAccount } = useSelector(
    (state) => state.application
  );
  const { nameFormatOptions } = useSelector((state) => state.genericData);
  const { appLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const validationSchema = useMemo(() => {
    if (!Boolean(appLanguage) || !Boolean(t)) {
      return {};
    }
    return FormSchema(t, nameFormatOptions);
  }, [appLanguage, nameFormatOptions, t]);

  const onSubmitForm = async (formValues, { setFieldError }) => {
    try {
      const currentProfile = authTempProfile.postData ?? {};
      await saveTempProfile({
        dispatch,
        formValues,
        currentProfile,
        authAccount,
        history,
        setFieldError,
        t,
        setSubmitBtnDisabled,
      });
    } catch (e) {
      dispatch(
        setAppSnackNotification({
          severity: "error",
          message: "Unknown Error Occurred, Try Again",
        })
      );
    }
  };

  return (
    <CreateFlowPage>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <BasicInformation formMeta={formMeta} />
            <ResidencyInfo formMeta={formMeta} />
            <EducationProfession formMeta={formMeta} />
            <Habbits formMeta={formMeta} />
            <AdditionalInfomation formMeta={formMeta} />
            <Grid container direction="row" justifyContent="space-between">
              <Button
                variant="text"
                startIcon={<ArrowBackIos />}
                className={`back-button-${appLanguage}`}
              >
                {t("common.backBtnText")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={`button-${appLanguage}`}
                disabled={submitBtnDisabled}
                onClick={() => {
                  handleSubmit();
                  setTimeout(() => {
                    scrollOnError();
                  }, 500);
                }}
              >
                {t("common.saveAndContinueBtn")}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </CreateFlowPage>
  );
};

export default PersonalCreateAccount;
