import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import Config from "../../../v2/config";
import { setAppCreateFlowInitialized } from "../../../v2/store/actions/application";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const { CURRENT_PLATFORM, PLATFORMS, CREATE_FLOW_STEP_META } = Config;

const PROFILE_COMPLETION_ALERT_TIME_STAMP =
  "Profile-Completion-Alert-Timestamp";

const ProfileCompletionAlert = () => {
  const [visibility, setVisibility] = useState(false);
  const { authProfile, authenticated, initialized } = useSelector(
    (state) => state.application
  );
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (!authenticated) {
      const lastNotifiedTime = localStorage.getItem(
        PROFILE_COMPLETION_ALERT_TIME_STAMP
      );

      if (isEmpty(lastNotifiedTime)) {
        setVisibility(true);
        return;
      } else {
        if (moment().isAfter(moment(lastNotifiedTime).add(2, "hours"))) {
          setVisibility(true);
          return;
        }
      }
    } else {
      if (authenticated && initialized && isEmpty(authProfile)) {
        setVisibility(true);
        return;
      }
    }
  }, [authProfile, authenticated, initialized]);

  const closeButtonVisibility = useMemo(() => {
    if (!authenticated) {
      if (localStorage.getItem(PROFILE_COMPLETION_ALERT_TIME_STAMP)) {
        return true;
      }
    } else {
      return false;
    }
  }, [authenticated]);

  const updateTimeStamp = () => {
    const lastClickedTime = moment();
    localStorage.setItem(PROFILE_COMPLETION_ALERT_TIME_STAMP, lastClickedTime);
  };

  const onClickCompleAd = () => {
    const abortRedirect = CREATE_FLOW_STEP_META.some((currentMeta) =>
      location.pathname.includes(currentMeta.route)
    );
    if (!authenticated) {
      updateTimeStamp();
      history.push("/auth");
    } else {
      if (!abortRedirect) {
        history.push("/complete-ad-personal");
        dispatch(setAppCreateFlowInitialized(false));
      }
    }
  };

  const onClickCloseBanner = () => {
    updateTimeStamp();
    setVisibility(false);
  };

  if (CURRENT_PLATFORM === PLATFORMS.LK) {
    return null;
  }

  return (
    visibility && (
      <Grid
        container
        direction="row"
        className="temp-banner-root-container"
        justifyContent="center"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="content-text-container column-gap-8 row-gap-8"
          style={{
            marginLeft: closeButtonVisibility && "auto",
          }}
        >
          <Grid item>
            <Typography variant="body2">
              Complete your ad today and claim our special promotional offer.
              Hurry, this offer won’t last long!
            </Typography>
          </Grid>
          <Button
            variant="outlined"
            style={{ textTransform: "capitalize" }}
            onClick={() => onClickCompleAd()}
          >
            Complete Profile
          </Button>
        </Grid>

        {closeButtonVisibility && (
          <Close
            className="close-icon"
            onClick={() => {
              onClickCloseBanner();
            }}
          />
        )}
      </Grid>
    )
  );
};

export default ProfileCompletionAlert;
