import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import AppDialog, {
  DialogContent,
  DialogFooter,
  PrimaryAction,
  TextButton,
} from "../../../../components/molecules/dialog";
import AccountPageDomain from "../../../../features/account-page";
import OtpInput from "../../../../components/molecules/otp-input";
import FormTextInput from "../../../../components/molecules/form-text-input";

import "./style.css";

const { EMAIL_UPSERT_DIALOG_CONTENT, NO_OF_OTP_DIGITS } =
  AccountPageDomain.config;

const EmailInputView = ({ setDialogTitle }) => {
  const { t, i18n } = useTranslation();
  const { authAccount } = useSelector((state) => state.application);
  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    setSubmitting,
    setErrors,
  } = useFormikContext();

  const { emailAddress } = values;

  const onSubmitForm = () => {
    AccountPageDomain.handleTriggerEmailOTP({
      emailAddress,
      setErrors,
      t,
      setFieldValue,
      isSubmitting,
      setSubmitting,
    });
  };

  React.useEffect(() => {
    setDialogTitle(
      !authAccount.email
        ? t("settingPage.addNewEmailAddress")
        : t("settingPage.updateEmailAddress")
    );
  }, [authAccount, setDialogTitle, t]);

  return (
    <>
      <DialogContent paddingY={8} rowGap={4}>
        <FormTextInput
          fieldName="emailAddress"
          label={t("common.email")}
          labelPosition="top"
          lang={i18n.language}
          value={values.emailAddress}
          onChange={handleChange}
          errorText={errors.emailAddress}
          hideCharactorLength={true}
        />
      </DialogContent>
      <DialogFooter>
        <PrimaryAction
          label={t("common.continue")}
          onClick={onSubmitForm}
          disabled={false}
          processing={isSubmitting}
          fullWidth
        />
      </DialogFooter>
    </>
  );
};

const OTPVerificationView = ({ onCloseModal, setDialogTitle }) => {
  const { t } = useTranslation();
  const {
    setFieldValue,
    values,
    errors,
    setErrors,
    isSubmitting,
    setSubmitting,
  } = useFormikContext();
  const {
    oneTimePassword,
    token,
    retryTimeout: initTimeout,
    emailAddress,
  } = values;
  const [retryTimeout, setRetryTimeout] = React.useState(initTimeout ?? 30);
  const [retryOTPError, setRetryOTPError] = React.useState("");

  const onClickBackBtn = (event) => {
    event.preventDefault();
    setFieldValue(
      "dialogContent",
      EMAIL_UPSERT_DIALOG_CONTENT.EMAIL_INPUT_VIEW
    );
  };

  const onClickSubmitForm = async () => {
    try {
      if (!isSubmitting) {
        await AccountPageDomain.verifyEmailAddress({
          token,
          oneTimePassword,
          t,
          setErrors,
          closeModal: onCloseModal,
        });
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const onClickRetrySendOTP = async () => {
    AccountPageDomain.retrySendEmailOTP({
      emailAddress,
      setSubmitting,
      setRetryOTPError,
      setFieldValue,
      t,
    });
  };

  React.useEffect(() => {
    console.log(">>>> errors ", errors);
  }, [errors]);

  React.useEffect(() => {
    setDialogTitle(t("settingPage.verifyYourEmailAddress"));
    const interval = setInterval(() => {
      if (retryTimeout > 0) setRetryTimeout(retryTimeout - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <>
      <DialogContent paddingY={16}>
        <Typography className="field-text text-center">
          {t("settingPage.yourEmailAddress")}
        </Typography>
        <Typography className="email-preview">{values.emailAddress}</Typography>
        <Typography
          variant="caption"
          className="clickable-text pt-4 cursor-pointer"
          onClick={onClickBackBtn}
        >
          {t("common.change")}
        </Typography>
        <OtpInput
          noOfDigits={NO_OF_OTP_DIGITS}
          value={oneTimePassword}
          onChange={(value) => setFieldValue("oneTimePassword", value)}
          helperText={errors.oneTimePassword}
          state={!isEmpty(errors.oneTimePassword) && "error"}
        />
        <Grid container direction="column" className="pt-12">
          <Typography className="field-text text-center text-semi-bold">
            {t("settingPage.didnotRecieveOTP")}
          </Typography>
          {!isEmpty(retryOTPError) && (
            <Typography className="field-text text-center text-error text-bold">
              {retryOTPError}
            </Typography>
          )}
          {retryTimeout > 0 ? (
            <Typography className="field-text text-center">
              {t("settingPage.otpRetryCountdown", {
                countdown: retryTimeout,
              })}
            </Typography>
          ) : (
            <Typography
              variant="caption"
              className="text-underline text-center capitalized-text accent-1-text cursor-pointer"
              onClick={onClickRetrySendOTP}
            >
              {t("settingPage.sendAgain")}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogFooter>
        <PrimaryAction
          label={t("common.continue")}
          onClick={onClickSubmitForm}
          disabled={false}
          processing={false}
          fullWidth={true}
        />
        <TextButton
          label={t("common.backBtnText")}
          onClick={onClickBackBtn}
          fullWidth
        />
      </DialogFooter>
    </>
  );
};

const UpsertEmailModal = ({ showModal, onCloseModal }) => {
  const { i18n } = useTranslation();
  const { initialized } = useSelector((state) => state.application);
  const [dialogTitle, setDialogTitle] = useState("");

  return (
    <AppDialog
      componentName="upsert-email-modal"
      showModal={showModal}
      onCloseModal={onCloseModal}
      language={i18n.language}
      title={dialogTitle}
      initialized={initialized}
    >
      <Formik
        initialValues={{
          emailAddress: "",
          oneTimePassword: "",
          token: "",
          retryTimeout: 30,
          dialogContent: EMAIL_UPSERT_DIALOG_CONTENT.EMAIL_INPUT_VIEW,
        }}
      >
        {({ values }) => (
          <>
            {values.dialogContent ===
            EMAIL_UPSERT_DIALOG_CONTENT.EMAIL_INPUT_VIEW ? (
              <EmailInputView setDialogTitle={setDialogTitle} />
            ) : (
              <OTPVerificationView
                onCloseModal={onCloseModal}
                setDialogTitle={setDialogTitle}
              />
            )}
          </>
        )}
      </Formik>
    </AppDialog>
  );
};

export default UpsertEmailModal;
