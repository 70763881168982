import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { isEmpty, kebabCase } from "lodash";
import Config from "../config";

import HomePage from "../../pages/Home";
import PostPage from "../pages/post";
import PrivacyPageIN from "../pages/privacy-policy-page";
import PrivacyPageLK from "../../pages/Privacy";
import ReviewPage from "../../pages/Reviews";
import MagazinePage from "../../pages/Magazine";
import PricingPageIN from "../pages/pricing";
import PricingPageLK from "../../pages/Pricing";
import HelpPage from "../../pages/Help";
import FAQPageIN from "../pages/faq-page";
import FAQPageLK from "../../pages/FAQ";
import TermsPageLK from "../../pages/Terms";
import TermsPageIN from "../pages/terms-and-conditions-page";
import SettingsPage from "../../pages/Settings";
import CheckoutPage from "../../pages/Checkout";
import BankTransferPage from "../../pages/BankTransfer";
import InvalidPaymentLinkPage from "../../pages/InvalidPaymentLink";
import PaymentForAdPage from "../../pages/PaymentForAd";
import RestrictedProfile from "../../pages/RestrictedProfile";
import IncorrectToken from "../../pages/IncorrectToken";
import DisabledAcc from "../../pages/DisabledAcc";
import PaymentSuccess from "../../pages/PaymentSuccess";
import Unsubscribed from "../../pages/Unsubscribed";
import PaymentError from "../../pages/PaymentError";
import EmailActivation from "../../pages/EmailActivation";
import EmailAlreadyActivated from "../../pages/EmailAlreadyActivated";
import Outage from "../../pages/Outage";
import NetworkError from "../../pages/NetworkError";
import BankTransferSuccess from "../pages/bank-transfer-success";
import PasswordReset from "../../pages/PasswordReset";
import MyMatches from "../../pages/MyMatches";
import InterestsMatched from "../../pages/InterestsMatched";
import InterestsSent from "../../pages/InterestsSent";
import ViewMyAd from "../pages/view-my-ad";
import EditMyAd from "../pages/edit-my-ad";
import PartnerPref from "../../pages/PartnerPref";
import ReviewComments from "../../pages/ReviewComments";
import SentInterests from "../../pages/SentInterests";
import Interests from "../../pages/Interests";
import Messages from "../../pages/Messages";
import PreferenceMismatchInterests from "../../pages/PreferenceMismatchInterests";
import CompleteYourAd from "../../pages/CompleteYourAd";
import PersonalCreateAccount from "../pages/create-flow/personal-create-account";
import PrivateCreateAccount from "../pages/create-flow/private-create-account";
import ParentsCreateAccount from "../pages/create-flow/parents-create-account";
import ReviewAndPay from "../pages/create-flow/review-and-pay";
import TopAdBankTransfer from "../../pages/TopAdBankTransfer";
import TopAdPaymentStatus from "../../pages/TopAdPaymentStatus";
import ManagePosts from "../../pages/ManagePosts";
import Login from "../../pages/Login";
import OTPLogin from "../../pages/OTPLogin";
import RegisterNew from "../../pages/RegisterNew";
import Unauthorize from "../../pages/Unauthorize";
import RedirectIntPost from "../../pages/RedirectIntPost";
import RedirectMatchPost from "../../pages/RedirectMatchPost";
import PaymentLink from "../../pages/PaymentLink";
import SampleDatePicker from "../../pages/SampleDatePicker";
import PageNotFound from "../../pages/PageNotFound";

const { PLATFORMS, CURRENT_PLATFORM, FAQ_CATEGORIES } = Config;

const AppRoutes = () => {
  return (
    <Switch>
      {/* Page:Home Page(Post Search) */}
      <Route exact path="/search">
        <HomePage routeId="VJVDGA" />
      </Route>
      {/* Page:Post View */}
      <Route exact path="/post">
        <PostPage routeId="RPVQTG" />
      </Route>
      {/* Page:Privacy Policy */}
      <Route exact path="/privacy">
        {CURRENT_PLATFORM === PLATFORMS.IN ? (
          <PrivacyPageIN routeId="PZTCBC" />
        ) : (
          <PrivacyPageLK routeId="PZTCBC" />
        )}
      </Route>
      {/* Page:Terms and Conditions */}
      <Route exact path="/terms">
        {CURRENT_PLATFORM === PLATFORMS.IN ? (
          <TermsPageIN routeId="WKRAFE" />
        ) : (
          <TermsPageLK routeId="WKRAFE" />
        )}
      </Route>
      <Route exact path="/pricing">
        {CURRENT_PLATFORM === PLATFORMS.IN ? (
          <PricingPageIN routeId="HPKTWK" />
        ) : (
          <PricingPageLK routeId="HPKTWK" />
        )}
      </Route>
      <Route exact path="/reviews">
        <ReviewPage routeId="CWNMME" />
      </Route>
      <Route exact path="/magazine">
        <MagazinePage routeId="JAELDC" />
      </Route>
      <Route exact path="/help">
        <HelpPage routeId="ALBLWT" />
      </Route>
      <Route exact path="/faq/:category">
        {CURRENT_PLATFORM === PLATFORMS.IN ? (
          <FAQPageIN routeId="EMQKDU" />
        ) : (
          <FAQPageLK routeId="EMQKDU" />
        )}
      </Route>
      <Route exact path="/settings">
        <SettingsPage routeId="ELNKAP" />
      </Route>
      <Route exact path="/checkout">
        <CheckoutPage routeId="KEWNTU" />
      </Route>
      <Route exact path="/checkout/bank-transfer">
        <BankTransferPage routeId="FTKNUK" />
      </Route>
      <Route exact path="/invalid-payment-link">
        <InvalidPaymentLinkPage routeId="DUVEJX" />
      </Route>
      <Route exact path="/pay-for-ad">
        <PaymentForAdPage routeId="ZACBJE" />
      </Route>
      <Route exact path="/restricted">
        <RestrictedProfile routeId="ERHEXR" />
      </Route>
      <Route exact path="/unauthorized-user">
        <IncorrectToken routeId="HFGULX" />
      </Route>
      <Route exact path="/unavailable-profile">
        <DisabledAcc routeId="YCATGL" />
      </Route>
      <Route exact path="/payment-success">
        <PaymentSuccess routeId="HNSVVR" />
      </Route>
      <Route exact path="/renew/success">
        <PaymentSuccess routeId="AQRXAN" />
      </Route>
      <Route exact path="/unsubscribe">
        <Unsubscribed routeId="CBXDQW" />
      </Route>
      <Route exact path="/payment-failed">
        <PaymentError routeId="YUVGSE" />
      </Route>
      <Route exact path="/activated">
        <EmailActivation routeId="HMJZPP" />
      </Route>
      <Route exact path="/already-activated">
        <EmailAlreadyActivated routeId="JGWLAS" />
      </Route>
      <Route exact path="/outage">
        <Outage routeId="SNPTYP" />
      </Route>
      <Route exact path="/network-error">
        <NetworkError routeId="KHTYDY" />
      </Route>
      <Route exact path="/newcash-success">
        <BankTransferSuccess routeId="XMEJCB" />
      </Route>
      <Route exact path="/renewcash-success">
        <BankTransferSuccess routeId="FXNTTY" />
      </Route>
      <Route exact path="/reset-password">
        <PasswordReset routeId="ZPCXSG" />
      </Route>
      <Route exact path="/my-matches">
        <MyMatches routeId="HSLHDZ" />
      </Route>
      <Route exact path="/interests-matched">
        <InterestsMatched routeId="YKZTSQ" />
      </Route>
      <Route exact path="/interests-sent">
        <InterestsSent routeId="WRGPST" />
      </Route>
      <Route exact path="/my-ad">
        <ViewMyAd routeId="WTEZYS" />
      </Route>
      <Route exact path="/edit-my-ad">
        <EditMyAd routeId="GXEGZP" />
      </Route>
      <Route exact path="/interest-preferences">
        <PartnerPref routeId="BNFJHW" />
      </Route>
      <Route exact path="/review-comments">
        <ReviewComments routeId="TTFNQH" />
      </Route>
      <Route exact path="/sent-interests">
        <SentInterests routeId="GATQKY" />
      </Route>
      <Route exact path="/interests">
        <Interests routeId="MYKZWW" />
      </Route>
      <Route exact path="/messages">
        <Messages routeId="QTVGST" />
      </Route>
      <Route exact path="/preference-mismatch-interests">
        <PreferenceMismatchInterests routeId="QRTQQY" />
      </Route>
      <Route exact path="/complete-your-ad">
        <CompleteYourAd routeId="ELKZXK" />
      </Route>
      <Route exact path="/complete-ad-personal">
        <PersonalCreateAccount routeId="HHEQMV" />
      </Route>
      <Route exact path="/complete-ad-private">
        <PrivateCreateAccount routeId="OAYPGE" />
      </Route>
      <Route exact path="/complete-ad-parent">
        <ParentsCreateAccount routeId="NBWWHJ" />
      </Route>
      <Route exact path="/complete-ad-review">
        <ReviewAndPay routeId="WAYPGT" />
      </Route>
      <Route exact path="/payments/top-ad">
        <PaymentForAdPage routeId="QQGARN" />
      </Route>
      <Route exact path="/checkout/top-ad-bank-transfer">
        <TopAdBankTransfer routeId="NYWAEL" />
      </Route>
      <Route exact path="/checkout/top-ad">
        <TopAdPaymentStatus routeId="AWYAGD" />
      </Route>
      <Route exact path="/manage-posts">
        <ManagePosts routeId="FSBFMJ" />
      </Route>
      <Route exact path="/login">
        <Login routeId="UHAHMM" />
      </Route>
      <Route exact path="/auth">
        <OTPLogin routeId="TSLCWT" />
      </Route>
      <Route exact path="/register">
        <RegisterNew routeId="HSGNCA" />
      </Route>
      <Route exact path="/access-denied">
        <Unauthorize routeId="PMJUDA" />
      </Route>
      <Route exact path="/post-interests">
        <RedirectIntPost routeId="ZGWUTK" />
      </Route>
      <Route exact path="/match">
        <RedirectMatchPost routeId="KTHFLM" />
      </Route>
      <Route exact path="/pay/:id">
        <PaymentLink routeId="DZPUPT" />
      </Route>
      <Route exact path="/sample-date-picker">
        <SampleDatePicker routeId="USCKEG" />
      </Route>
      {!isEmpty(Object.values(FAQ_CATEGORIES)) && (
        <Route exact path="/faq">
          <Redirect
            to={`/faq/${kebabCase(Object.values(FAQ_CATEGORIES)[0])}`}
          />
        </Route>
      )}
      <Route exact path="/">
        <Redirect to="/search" />
      </Route>
      <Route exact component={PageNotFound} />
    </Switch>
  );
};

export default AppRoutes;
