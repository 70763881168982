import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";

const Dropdown = ({
  fieldMeta,
  options,
  value,
  onChange,
  menuItemProps = {},
}) => {
  return (
    <FormControl className="post-sort-by">
      <Select name={fieldMeta.fieldName} value={value} onChange={onChange}>
        {options.map((item, i) => (
          <MenuItem
            key={item[fieldMeta.keyExtractor]}
            value={item[fieldMeta.keyExtractor]}
            className={menuItemProps.className ?? ""}
          >
            {item[fieldMeta.labelExtractor]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
