import { parsePhoneNumberFromString } from "libphonenumber-js";

class PhoneNumberUtil {
  static validateForCountry(phoneNumber, countryCode) {
    const processor = parsePhoneNumberFromString(phoneNumber, {
      defaultCountry: countryCode,
      extract: false,
    });
    return (
      !!processor && processor.country === countryCode && processor.isValid()
    );
  }
}

export default PhoneNumberUtil;
