import React from "react";
import { Helmet } from "react-helmet";

const PageHead = ({ title, name, description }) => {
  return (
    <Helmet>
      {(typeof title === "string" || typeof name === "string") && (
        <title>{`${title ?? ""} ${name ?? ""}`}</title>
      )}
      {typeof description === "string" && (
        <meta name="description" content={description} />
      )}
    </Helmet>
  );
};

export default PageHead;
