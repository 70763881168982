// import React, { useState } from "react";
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

// const Example = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return (
//     <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
//   );
// };


import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { removePost } from "../actions/removePost";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import "../styles/registration.css";

function DisabledAcc(props) {
  
  const [startDate, setStartDate] = useState("");
  const years = _.range(new Date().getFullYear(), 1990);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleStartDate = (date) => {
    console.log(date)
    setStartDate(date);
  }
  return (
    <DatePicker
    selected={startDate}
      // onChange={(date) => setStartDate(date)}
      timeInputLabel="Time:"
      dateFormat="MM/dd/yyyy h:mm aa"
      showTimeInput
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button> */}
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
            style={{marginRight: '10px', background: 'white', borderStyle: 'solid', borderColor: 'black', borderWidth: '1px', borderRadius: '4px'}}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            style={{marginRight: '10px', background: 'white', borderStyle: 'solid', borderColor: 'black', borderWidth: '1px', borderRadius: '4px'}}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{marginRight: '10px', background: 'white', borderStyle: 'solid', borderColor: 'black', borderWidth: '1px', borderRadius: '4px'}}>
            {"<"}
          </button>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} style={{background: 'white', borderStyle: 'solid', borderColor: 'black', borderWidth: '1px', borderRadius: '4px'}}>
            {">"}
          </button>
        </div>
      )}
      // selected={startDate}
      onChange={(date) => handleStartDate(date)}
    />
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ removePost }, dispatch);
};

export default connect(null, matchDispatchToProps)(DisabledAcc);
