import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { ArrowDropDown as ArrowDropdownIcon } from "@material-ui/icons";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import PhoneNumberUti from "../../../../../utils/phone-number-util";
import { useFormikContext } from "formik";
import {
  DialogContent,
  DialogFooter,
  PrimaryAction,
  TextButton,
} from "../../../../../components/molecules/dialog";
import { useSelector } from "react-redux";
import AccountPageDomain from "../../../../../features/account-page";

const MODAL_CONTENT = Object.freeze({
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  DIAL_CODE_SELECTOR: "DIAL_CODE_SELECTOR",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});

const PhoneNumberInputView = ({ setDialogTitle, onCloseModal }) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    setFieldError,
    handleChange,
  } = useFormikContext();
  const { countryCode, dialCode, phoneNumber } = values;
  const { appConfig } = useSelector((state) => state.application);

  const onClickDialCode = (event) => {
    event.preventDefault();
    setFieldValue("dialogContent", MODAL_CONTENT.DIAL_CODE_SELECTOR);
  };

  const onClickSubmit = async () => {
    AccountPageDomain.triggerPhoneNumberOTP({
      dialCode,
      phoneNumber,
      setFieldValue,
      setSubmitting,
      setFieldError,
      t,
    });
  };

  const isSubmitDisabled = React.useMemo(() => {
    return !PhoneNumberUti.validateForCountry(
      `${dialCode}${phoneNumber}`,
      countryCode
    );
  }, [countryCode, dialCode, phoneNumber]);

  React.useEffect(() => {
    if (!isEmpty(appConfig) && (isEmpty(dialCode) || isEmpty(countryCode))) {
      setFieldValue(
        "dialCode",
        appConfig.dialCode ? `+${appConfig.dialCode}` : ""
      );
      setFieldValue("countryCode", appConfig.countryCode ?? "");
    }
  }, [appConfig, countryCode, dialCode, setFieldValue]);

  React.useEffect(() => {
    setDialogTitle(t("settingPage.updatePhoneNumber"));
  });

  return (
    <>
      <DialogContent paddingY={8} rowGap={4}>
        <Typography variant="caption">{t("common.phoneNumber")}</Typography>
        <Grid container direction="row" className="column-gap-8">
          <Grid
            item
            xs={4}
            container
            direction="row"
            alignItems="center"
            className="country-code-input"
            onClick={onClickDialCode}
          >
            <Grid
              xs
              container
              direction="row"
              alignItems="center"
              className="column-gap-8 digit-container"
            >
              <ReactCountryFlag countryCode={countryCode} svg />
              <Typography variant="body2">{dialCode}</Typography>
            </Grid>
            <ArrowDropdownIcon />
          </Grid>
          <Grid item xs>
            <TextField
              name="phoneNumber"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        {!isEmpty(errors.phoneNumber) && (
          <Typography variant="caption" class="error-text">
            {errors.phoneNumber}
          </Typography>
        )}
      </DialogContent>
      <DialogFooter>
        <PrimaryAction
          label={t("common.continue")}
          onClick={onClickSubmit}
          disabled={isSubmitDisabled || isSubmitting}
          processing={isSubmitting}
          fullWidth
        />
        <TextButton
          fullWidth
          label={t("common.cancel")}
          onClick={onCloseModal}
        />
      </DialogFooter>
    </>
  );
};

export default PhoneNumberInputView;
