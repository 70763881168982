import React, { useState, useEffect } from "react";
import axios from "axios";

import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";
import User from "../../../images/user.png";

import { removePost } from "../../../actions/removePost";
import { fetchRestrictedUnmatch } from "../../../actions/fetchRestrictedUnmatch";
import { fetchIntPostData } from "../../../actions/fetchIntPostData";
import { fetchAllInterest } from "../../../actions/fetchAllInterest";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PostServiceApi from "../../../v2/services/api/post-service";
import { isEmptyOrNullOrUndefined } from "../../../v2/utils";

const UnseenMatches = (props) => {
  const [avatar, setAvatar] = useState(User);

  const { authProfile } = useSelector((state) => state.application);
  const { post = {} } = props;

  useEffect(() => {
  const postId = authProfile.id;
  const matchId = post?.id;
  const imageId = post?.avatar;

  if (
    !isEmptyOrNullOrUndefined(postId) &&
    !isEmptyOrNullOrUndefined(matchId) &&
    !isEmptyOrNullOrUndefined(imageId)
  ) {
    PostServiceApi.getMatchImages({
      postId,
      matchId,
      imageId,
      thumbnail: true,
    })
      .then((res) => {
        const data = URL.createObjectURL(res.body);
        setAvatar(data);
      })
      .catch((err) => {
        console.log("Error >>>> ", err);
      });
  }
  }, [authProfile, post]);

  const handlePost = (post, type) => {
    props.fetchIntPostData(post, type);
    setTimeout(() => props.fetchAllInterest(), 1000);
  };

  const handleRestrict = (post) => {
    props.removePost();
    props.fetchRestrictedUnmatch(post);
  };

  return (
    <div
      className="new-matches-notifi"
      onClick={() =>
        props.post.restricted || props.post.isDisabledProfile
          ? handleRestrict(props.post)
          : handlePost(props.post, "matches")
      }
    >
      <img
        src={
          props.post.avatar
            ? avatar
            : props.post.post.personalInfo.gender === "MALE"
            ? Male
            : Female
        }
        alt="profile pic"
      />
      <p>{props.post.post.personalInfo.displayName}</p>
    </div>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostData,
      fetchAllInterest,
      removePost,
      fetchRestrictedUnmatch,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    intId: state.matches.post.id,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(UnseenMatches);
