import store from "../../../store";
import PhoneNumberApi from "../../services/api/phone-number";
import { setAppSnackNotification } from "../../store/actions/application";
import AppLogger from "../../utils/logger";

const addPhoneNumber = async ({ number }) => {
  try {
    let ALPHABET =
      "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
    let bb = Math.round(new Date().getTime() / 1000);
    const tso = bb;
    let aa = "";
    while (bb > 0) {
      let tss = ALPHABET[bb % ALPHABET.length].toString();
      aa = aa + tss;
      bb = parseInt(bb / ALPHABET.length);
    }
    const response = PhoneNumberApi.addPhoneNumber({
      number,
      xClientId: aa,
      xTimestamp: tso,
    });
    return response;
  } catch (error) {
    AppLogger.exception(error);
    throw error;
  }
};

const updateUserAccountPhoneNumber = async ({ token, otp }) => {
  const response = PhoneNumberApi.updateUserAccountPhoneNumber({ token, otp });
  return response;
};

const onClickTriggerOTP = async ({
  setBtnSubmitting,
  setErrorCode,
  dialCodeCountry,
  phoneNumber,
  setPhoneToken,
  setModalContent,
  MODAL_CONTENT,
  countdownTimerRef,
}) => {
  try {
    setBtnSubmitting(true);
    setErrorCode(undefined);
    const response = await PhoneNumberDomain.addPhoneNumber({
      number: `${dialCodeCountry.dialCode}${phoneNumber}`,
    });
    if (!response.success) {
      const errorCode = response.body?.code;
      setErrorCode(errorCode);
      if (errorCode === 1201) {
        countdownTimerRef.current = 30;
      }
      return;
    }
    setPhoneToken(response.body.id);
    setModalContent(MODAL_CONTENT.OTP_VERIFICATION);
  } catch (error) {
    AppLogger.exception(error);
  } finally {
    setBtnSubmitting(false);
  }
};

const handleInterval = ({
  errorCode,
  setErrorCode,
  setErrorText,
  countdownTimerRef,
  t,
}) => {
  switch (errorCode) {
    case 1000:
      setErrorText(t("settingPage.coundnotSendSMSToNumber"));
      break;
    case 1201:
      if (countdownTimerRef.current > 0) {
        countdownTimerRef.current -= 1;
        setErrorText(
          t("settingPage.tooManyAttemptsTimeCountDown", {
            timeCountDown: countdownTimerRef.current,
          })
        );
      } else {
        setErrorCode(undefined);
      }

      break;
    case 1203:
      setErrorText(t("settingPage.invalidPhoneNumber"));
      break;
    default:
      setErrorText("");
      countdownTimerRef.current = 0;
      break;
  }
};

const onTriggerOTPOnVerificationView = async ({
  dialCodeCountry,
  phoneNumber,
  setPhoneToken,
  setOtpFieldState,
  setRetryOtpHelperText,
  t,
}) => {
  try {
    const response = await PhoneNumberDomain.addPhoneNumber({
      number: `${dialCodeCountry.dialCode}${phoneNumber}`,
    });
    if (!response.success) {
      const errorCode = response.body.code;
      switch (errorCode) {
        case 1201:
          setRetryOtpHelperText(
            t("settingPage.tooManyAttemptsTimeCountDown", {
              timeCountDown: 30,
            })
          );
          setOtpFieldState("error");
          break;
        default:
          break;
      }

      return;
    }
    setPhoneToken(response.body.id);
  } catch (error) {
    AppLogger.exception(error);
    store.dispatch(
      setAppSnackNotification({
        severity: error,
        message: "Unknown Error Occurred",
      })
    );
  }
};

const PhoneNumberDomain = {
  addPhoneNumber,
  updateUserAccountPhoneNumber,
  onClickTriggerOTP,
  handleInterval,
  onTriggerOTPOnVerificationView,
};
export default PhoneNumberDomain;
