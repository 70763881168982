import { isEmpty, isString } from "lodash";

class CssClassNameBuilder {
  constructor(componentName) {
    this.container = [];
    this.componentPrefix = componentName;
    this.container.push(componentName);
  }

  add = (suffix) => {
    if (
      isString(suffix) &&
      !isEmpty(suffix) &&
      suffix !== this.componentPrefix
    ) {
      this.container.push(`${this.componentPrefix}-${suffix}`);
    }
    return this;
  };

  remove = (suffix) => {
    if (suffix !== this.componentPrefix && this.container.includes(suffix)) {
      this.container = this.container.filter((current) => current !== suffix);
    }
    return this;
  };

  build = () => {
    const className = this.container.join(" ");
    return className;
  };
}

export default CssClassNameBuilder;
