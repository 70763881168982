import React, { useContext, useEffect, useState } from "react";
import DataColumn from "../../../../components/molecules/data-column";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../context";
import {
  DataDisplaySection,
  generateDisplayValues,
} from "../../../../features/profile-view";
import { isEmpty } from "lodash";
import { Grid, Link, Typography } from "@material-ui/core";
import DataSection from "../../../../components/organisms/data-section";
import formMeta from "../../form-meta/horoscope-form-meta";
import "./style.css";
import { fieldVisibility, isEmptyOrNullOrUndefined } from "../../../../utils";
import { useSelector } from "react-redux";
import {
  getMatchHoroscopeImg,
  getPostHoroscopeImage,
} from "../../../../features/post";
import moment from "moment";
import PhotoGrid from "../../../../components/molecules/PhotoGrid";
import { variantDirectionOptions } from "../..";

const HoroscopeInformation = ({ data, interestData, variant, direction }) => {
  const [horoscopeImages, setHoroscopeImages] = useState([]);

  const { t, i18n } = useTranslation();
  const { appLanguage } = useContext(AppContext);
  const [values, setValues] = useState([]);
  const { authProfile } = useSelector((state) => state.application);

  const horoscopeDeatilsOther = !!(
    interestData?.post?.horoscopeInfo?.birthCity &&
    interestData?.post?.horoscopeInfo?.birthTime
  );

  const horoscopeDetailsSelf = !!(
    authProfile.horoscopeInfo?.birthCity && authProfile.horoscopeInfo?.birthTime
  );

  const horoscopeInfoVisibility =
    (horoscopeDeatilsOther && horoscopeDetailsSelf) ||
    (variant === variantDirectionOptions.SELF &&
      direction === variantDirectionOptions.MATCHES);

  let isMatch = false;

  if (!isEmpty(interestData?.timeline)) {
    const matched = interestData.timeline.filter(
      (filter) => filter.eventType === "MATCHED"
    );
    if (!isEmpty(matched)) {
      isMatch = true;
    }
  }

  const downloadMatchHoroscopeImages = async () => {
    const horoscopeImgs = data?.horoscopeInfo?.images ?? [];
    const postId = authProfile.id;
    const interestId = interestData.id;
    if (
      !isEmptyOrNullOrUndefined(postId) &&
      !isEmptyOrNullOrUndefined(interestId) &&
      !isEmptyOrNullOrUndefined(horoscopeImgs)
    ) {
      try {
        const images = [];
        for (const img of horoscopeImgs) {
          const image = await getMatchHoroscopeImg({
            postId,
            interestId,
            img,
          });
          images.push(image);
        }
        setHoroscopeImages(images);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const downloadProfileHoroscopeImages = async () => {
    if (!isEmpty(data.horoscopeInfo?.images)) {
      const horoscopeImgs = data.horoscopeInfo?.images;
      const postId = authProfile.id;
      try {
        const images = [];
        for (let i = 0; i < horoscopeImgs.length; i++) {
          const image = await getPostHoroscopeImage({
            postId: postId,
            imageId: horoscopeImgs[i],
          });
          images.push(image);
        }
        setHoroscopeImages(images);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    try {
      if (!isEmpty(data)) {
        generateDisplayValues({
          section: DataDisplaySection.horoscopeInformation,
          data: data,
          interestData: interestData,
          lang: appLanguage,
          variant: variant,
          direction: direction,
        }).then((values) => setValues(values));
      }
    } catch (e) {
      console.log(e);
    }
  }, [data, interestData, appLanguage, variant, direction]);

  useEffect(() => {
    setHoroscopeImages([]);
    if (horoscopeInfoVisibility && !isEmpty(interestData)) {
      downloadMatchHoroscopeImages();
      return;
    }
    if (
      variant === variantDirectionOptions.SELF &&
      direction === variantDirectionOptions.MATCHES &&
      !isEmpty(data.horoscopeInfo?.images)
    ) {
      downloadProfileHoroscopeImages();
    }
  }, [
    authProfile,
    data,
    interestData,
    i18n.language,
    variant,
    direction,
    horoscopeInfoVisibility,
  ]);

  return (
    !isEmpty(data) && (
      <DataSection heading={t("post.horoscopeInfo")}>
        <Grid
          container
          direction="row"
          className="data-column-container"
          alignItems="flex-start"
        >
          <DataColumn
            label={t(formMeta.horoscopeMatching.label)}
            value={values.horoscopeMatching}
            visibility={fieldVisibility(formMeta.horoscopeMatching)}
          />
          <DataColumn
            label={t(formMeta.horoscopeCompatibility.label)}
            value={values.horoscopeMatching}
            visibility={fieldVisibility(formMeta.horoscopeCompatibility)}
          />
          <DataColumn
            label={t(formMeta.acceptOtherCaste.label)}
            value={values.acceptOtherCaste}
            visibility={fieldVisibility(formMeta.acceptOtherCaste)}
          />
          {((variant === variantDirectionOptions.SELF &&
            direction === variantDirectionOptions.MATCHES) ||
            isMatch) && (
            <DataColumn
              label={t(formMeta.birthday.label)}
              value={values.birthday}
              visibility={fieldVisibility(formMeta.birthday)}
            />
          )}
          {horoscopeInfoVisibility && (
            <>
              <DataColumn
                label={t(formMeta.originCountryCode.label)}
                value={values.birthCountry}
                visibility={fieldVisibility(formMeta.originCountryCode)}
              />
              <DataColumn
                label={t(formMeta.birthTime.label)}
                value={
                  values.birthTime
                    ? moment(values.birthTime, "hh:mm").format("hh.mm A")
                    : ""
                }
                visibility={fieldVisibility(formMeta.birthTime)}
              />
              <DataColumn
                label={t(formMeta.birthCity.label)}
                value={values.birthCity}
                visibility={fieldVisibility(formMeta.birthCity)}
              />
              <DataColumn
                label={t(formMeta.starId.label)}
                value={values.star}
                visibility={fieldVisibility(formMeta.starId)}
              />
              <DataColumn
                label={t(formMeta.starSignId.label)}
                value={values.starSign}
                visibility={fieldVisibility(formMeta.starSignId)}
              />
              <DataColumn
                label={t(formMeta.haveDosh.label)}
                value={values.haveDosh}
                visibility={fieldVisibility(formMeta.haveDosh)}
              />
              <DataColumn
                label={t(formMeta.horoscopeDetail.label)}
                value={values.additionalInfo}
                visibility={fieldVisibility(formMeta.horoscopeDetail)}
              />
            </>
          )}
        </Grid>

        <Grid container>
          <PhotoGrid images={horoscopeImages} />
        </Grid>

        {!(horoscopeDeatilsOther && horoscopeDetailsSelf) &&
          variant !== variantDirectionOptions.SELF &&
          isMatch && (
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="center"
              className="horoscope-notice"
            >
              <i className="fas fa-eye-slash horoscope-notice-icon" />
              <Typography className="horoscope-notice-txt">
                {!horoscopeDetailsSelf
                  ? t("post.addHoroscopeInfoNotice")
                  : !horoscopeDeatilsOther
                  ? t("post.userNotAddedHoroscopeInfoNotice")
                  : ""}
                {!horoscopeDetailsSelf && (
                  <Link
                    href="/edit-my-ad"
                    className="horoscope-notice-nav-link"
                  >
                    {t("post.addHoroscopeInfo")}
                  </Link>
                )}
              </Typography>
            </Grid>
          )}
      </DataSection>
    )
  );
};

export default HoroscopeInformation;
