import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PhoneNumberDomain from "../../../../../features/phone-number";
import {
  getAuthUserAccount,
  getAuthUserProfile,
} from "../../../../../features/application";
import { setAppSnackNotification } from "../../../../../store/actions/application";
import AppLogger from "../../../../../utils/logger";
import { Grid, Typography } from "@material-ui/core";
import OTPInput from "../../../../../components/molecules/otp-input";
import { isEmpty } from "lodash";
import { useFormikContext } from "formik";
import {
  DialogContent,
  DialogFooter,
  PrimaryAction,
} from "../../../../../components/molecules/dialog";
import AccountPageDomain from "../../../../../features/account-page";

const MODAL_CONTENT = Object.freeze({
  PHONE_NUMBER_INPUT: "PHONE_NUMBER_INPUT",
  DIAL_CODE_SELECTOR: "DIAL_CODE_SELECTOR",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});
const NO_OF_OTP_DIGITS = 6;

const OTPVerificationView = ({ setDialogTitle, closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    values,
    isSubmitting,
    errors,
    setSubmitting,
    setFieldValue,
    setFieldError,
  } = useFormikContext();
  const { dialCode, token, phoneNumber, oneTimePassword } = values;
  const [countdown, setCountdown] = React.useState(30);
  const [retryOTPHelperText, setRetryOtpHelperText] = React.useState("");

  const onClickBackButton = () => {
    setFieldValue("dialogContent", MODAL_CONTENT.PHONE_NUMBER_INPUT);
  };

  const onClickRetrySendOTP = () => {
    AccountPageDomain.onClickPhoneNumberRetryTriggerOTP({
      dialCode,
      phoneNumber,
      setRetryOtpHelperText,
      setFieldValue,
      t,
    });
  };

  const onClickSubmit = async () => {
    setSubmitting(true);
    try {
      const response = await PhoneNumberDomain.updateUserAccountPhoneNumber({
        token,
        otp: oneTimePassword,
      });
      if (!response?.statusCode) {
        setFieldError("oneTimePassword", t("errors.unknownErrorOccurred"));
        return;
      }
      if (!response.success) {
        const errorCode = response.body?.code;
        switch (errorCode) {
          case 1107:
            setFieldError(
              "oneTimePassword",
              t("settingPage.phoneNumberUsedAnotherAccount")
            );
            break;
          case 1202:
            setFieldError(
              "oneTimePassword",
              t("settingPage.verificationCodeInvalid")
            );
            break;
          default:
            setFieldError("oneTimePassword", t("errors.unknownErrorOccurred"));
            break;
        }
        return;
      }
      dispatch(getAuthUserAccount(true));
      dispatch(getAuthUserProfile(true));
      closeModal();
      dispatch(
        setAppSnackNotification({
          severity: "success",
          message: t("common.updatedsuccessfully"),
        })
      );
    } catch (error) {
      AppLogger.exception(error);
    } finally {
      setSubmitting(false);
    }
  };

  const isSubmitDisabled = React.useMemo(() => {
    return oneTimePassword.length !== NO_OF_OTP_DIGITS || isSubmitting;
  }, [oneTimePassword.length, isSubmitting]);

  React.useEffect(() => {
    setDialogTitle(t("settingPage.verifyPhoneNumberOTP"));
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <>
      <DialogContent paddingY={16}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className="otp-verification-view"
        >
          <Typography variant="caption" align="center">
            {t("settingPage.yourPhoneNumber")}
          </Typography>
          <Typography
            variant="caption"
            className="phone-number"
          >{`${dialCode}${phoneNumber}`}</Typography>
          <Typography
            className="change-text"
            onClick={onClickBackButton}
          >{`(${t("common.change")})`}</Typography>
          <OTPInput
            noOfDigits={6}
            value={oneTimePassword}
            onChange={(value) => {
              setFieldValue("oneTimePassword", value);
            }}
            helperText={errors.oneTimePassword}
            state={errors.oneTimePassword && "error"}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            className="pt-8"
          >
            <Typography className="caption-text-1">
              {t("settingPage.didnotRecieveOTP")}
            </Typography>
            {!isEmpty(retryOTPHelperText) ? (
              <Typography className="caption-text-1 capitalized-text text-error">
                {retryOTPHelperText}
              </Typography>
            ) : countdown === 0 ? (
              <Typography
                className="caption-text-1 capitalized-text text-underline"
                onClick={onClickRetrySendOTP}
              >
                {t("settingPage.sendAgain")}
              </Typography>
            ) : (
              <Typography className="caption-text-1 capitalized-text text-underline">
                {t("settingPage.otpRetryCountdown", { countdown })}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogFooter>
        <PrimaryAction
          label={t("common.continue")}
          onClick={onClickSubmit}
          disabled={isSubmitDisabled || isSubmitting}
          processing={isSubmitting}
          fullWidth
        />
      </DialogFooter>
    </>
  );
};

export default OTPVerificationView;
