import { isEmpty, isEqual, isObject } from "lodash";
import store from "../../../store";
import Constants from "../../config/constant";
import TempProfile from "../../models/profile/TempProfile";
import UserApi from "../../services/api/user";
import {
  setAppBoostrappedStates,
  setAppConfig,
  setAppInitialized,
  setAuthAccount,
  setAuthProfile,
  setAuthTempProfile,
} from "../../store/actions/application";
import Extensions from "./extensions";
import GenericDataAPI from "../../services/api/generic";
import AppLogger from "../../utils/logger";
import Config from "../../config";
import PageConfigContainer from "../../routes/configs/page-config-container";

export const CreateFlowSection = Object.freeze({
  PERSONAL_DATA: "PERSONAL_DATA",
  PARENT_DATA: "PARENT_DATA",
  PRIVATE_DATA: "PRIVATE_DATA",
});

export const initializeApp = () => async (dispatch) => {
  let appInitalized = false;
  let newStates = {};
  try {
    dispatch(setAppInitialized(false));
    const appConfig = await dispatch(initializeConfig(false));
    handleAppConfigs(appConfig, newStates);
    const authStates = await dispatch(initializeAuthV2());
    newStates = { ...authStates, appConfig, ...newStates };
    appInitalized = true;
  } catch (e) {
    AppLogger.exception(e);
    appInitalized = false;
  } finally {
    newStates.initialized = appInitalized;
    dispatch(setAppBoostrappedStates(newStates));
  }
};

const handleAppConfigs = (config, nextStates) => {
  bindEnableCreateflowRouteConfig(config, nextStates);
};

const bindEnableCreateflowRouteConfig = (config, nextStates) => {
  if (!(isObject(config) && Array.isArray(config.appConfig))) {
    return;
  }
  const routeId = "WAYPGT";
  const pageConfig = PageConfigContainer.getConfig({ id: routeId });
  if (isEmpty(pageConfig)) {
    return;
  }
  const enableCreateFlowReviewPage =
    config.appConfig.find((current) => current.id === "createflow_review_page")
      ?.enabled ?? true;

  nextStates.enabledCreateFlowReviewPage = enableCreateFlowReviewPage;

  if (Array.isArray(pageConfig.platforms)) {
    if (
      enableCreateFlowReviewPage &&
      !pageConfig.platforms.includes(Config.CURRENT_PLATFORM)
    ) {
      pageConfig.platforms.push(Config.CURRENT_PLATFORM);
    }
    if (
      !enableCreateFlowReviewPage &&
      pageConfig.platforms.includes(Config.CURRENT_PLATFORM)
    ) {
      pageConfig.platforms = pageConfig.platforms.filter(
        (current) => current !== Config.CURRENT_PLATFORM
      );
    }
  }
};

const initializeAuthV2 = () => async (dispatch) => {
  const output = {};
  output.authenticated = localStorage.getItem("auth_token") !== null;
  if (output.authenticated) {
    output.authAccount = await dispatch(getAuthUserAccount(false));
    output.authProfile = await dispatch(getAuthUserProfile(false));
    if (isEmpty(output.authProfile)) {
      const authTempProfile = await dispatch(getAuthTempProfile(false));
      if (!isEmpty(authTempProfile)) {
        output.authTempProfile = authTempProfile;
        output.authRouteAccess = Config.ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER;
      } else {
        output.createflowInitialized = false;
        output.authRouteAccess = Config.ROUTE_ACCESS_LEVEL.PUBLIC_USER;
      }
    } else {
      output.authRouteAccess = Config.ROUTE_ACCESS_LEVEL.PROFILE_USER;
      output.createflowInitialized = true;
    }
  }
  return output;
};

// export const initializeAuth = () => async (dispatch) => {
//   let initialized = false;
//   try {
//     const authenticated = localStorage.getItem("auth_token") !== null;
//     dispatch(setAuthenticated(authenticated));
//     if (authenticated) {
//       await dispatch(getAuthUserAccount());
//       const userProfile = await dispatch(getAuthUserProfile());
//       if (isEmpty(userProfile)) {
//         await dispatch(getAuthTempProfile());
//       }
//     }
//     initialized = true;
//   } catch (e) {
//     AppLogger.exception(e);
//   } finally {
//     dispatch(setAppInitialized(initialized));
//   }
// };

export const initializeConfig =
  (persistToAppState = true) =>
  async (dispatch) => {
    let output = {};
    try {
      const response = await GenericDataAPI.getAppConfig();
      if (response.success && isObject(response.body)) {
        output = response.body;
        if (persistToAppState) {
          dispatch(setAppConfig(output));
        }
      }
    } catch (e) {
      AppLogger.exception(e);
    }
    return output;
  };

export const getAuthUserAccount =
  (persistToAppState = true) =>
  async (dispatch) => {
    const response = await UserApi.getUserAccount();
    if (response.success) {
      const userAccount = response.body ?? {};
      if (persistToAppState) {
        dispatch(setAuthAccount(userAccount));
      }
      localStorage.setItem("memberId", userAccount.memberId ?? "");
      localStorage.setItem("userId", userAccount.memberId ?? "");
      localStorage.setItem("user_role", userAccount.role ?? "");
      localStorage.setItem("user_name", userAccount.name ?? "");
      localStorage.setItem("user_email", userAccount.email ?? "");
      return userAccount;
    }
    return {};
  };

export const getAuthUserProfile =
  (persistToAppState = true) =>
  async (dispatch) => {
    const { authProfile } = store.getState().application;
    const response = await UserApi.getUserProfiles();
    // if (!response.success) {
    //   throw new Error("Error! [getAuthUserProfile] unable to get profile");
    // }
    const userProfile =
      response.success &&
      Array.isArray(response.body) &&
      response.body.length > 0
        ? response.body[0]
        : {};
    if (!isEqual(authProfile, userProfile) && persistToAppState) {
      dispatch(setAuthProfile(userProfile));
    }

    if (isEmpty(userProfile)) {
      localStorage.removeItem("postId");
      localStorage.removeItem("postDetails");
      // @Deprecated: create flow storage values
      localStorage.removeItem("personalInfo");
      localStorage.removeItem("parentInfo");
      localStorage.removeItem("privateInfo");
    } else {
      localStorage.setItem("postId", userProfile.id);
      localStorage.setItem("post_details", JSON.stringify(userProfile));
    }

    return userProfile;
  };

export const getAuthTempProfile =
  (persistToAppState = true) =>
  async (dispatch) => {
    const response = await UserApi.getUserTempProfile();
    let tempProfile = {};
    if (response.statusCode === 200) {
      tempProfile = response.success ? response.body : {};
      // TODO: Remove followings when new create flow stabled
      const postData = tempProfile.postData ?? {};
      if (!isEmpty(postData.personalInfo)) {
        localStorage.setItem(
          "personalInfo",
          JSON.stringify(postData.personalInfo)
        );
      }
      if (!isEmpty(postData.parentInfo)) {
        localStorage.setItem("parentInfo", JSON.stringify(postData.parentInfo));
      }

      if (postData.horoscopeMatching !== undefined) {
        localStorage.setItem(
          "privateInfo",
          JSON.stringify({
            horoscopeMatching: postData.horoscopeMatching,
          })
        );
      }
    }
    if (persistToAppState) {
      dispatch(setAuthTempProfile(tempProfile));
    }
    return tempProfile;
  };

export const saveAuthTempProfile =
  ({ values, section }) =>
  async (dispatch) => {
    try {
      const { application: applicatonState } = store.getState();
      const { authTempProfile, authAccount } = applicatonState;
      let postData =
        (authTempProfile.constructor === Object && authTempProfile.postData) ||
        {};

      switch (section) {
        case CreateFlowSection.PERSONAL_DATA:
          postData.description = values.description ?? postData.description;
          postData.email = values.email ?? postData.email ?? "";
          postData.differentlyAbled =
            values.differentlyAbled ?? postData.differentlyAbled;
          postData.makePhonePublic = values.makePhonePublic ?? false;
          postData.personalInfo = Extensions.generatePersonalInfo({
            newValues: values,
            oldValues: postData.personalInfo ?? {
              ...TempProfile.PersonalInfoModel,
            },
          });

          if (
            postData.personalInfo.residentCountryCode ===
            Constants.LANKA_COUNTRY_CODE
          ) {
            postData.personalInfo.visaTypeId = "citizen";
          }
          break;
        case CreateFlowSection.PARENT_DATA:
          postData.parentInfo = values;
          break;
        case CreateFlowSection.PRIVATE_DATA:
          // update user address fields
          const existingAddress = { ...(authAccount.address ?? {}) };
          if (!isEmpty(existingAddress.country)) {
            delete existingAddress.country;
          }
          const newAddress = {
            name: values.nameOfRecipient,
            line1: values.addressLineOne,
            line2: values.addressLineTwo,
            city: values.city,
          };

          if (!isEqual(existingAddress, newAddress)) {
            await updateAuthUserAddress({
              name: values.nameOfRecipient,
              line1: values.addressLineOne,
              line2: values.addressLineTwo,
              city: values.city,
            });
          }

          if (
            values.showOfflineId !== postData.showOfflineId ||
            values.subscribeMagazine !== postData.subscribeMagazine
          ) {
            await saveUserPreferences({
              preferences: {
                showOfflineId: values.showOfflineId,
                subscribeMagazine: values.subscribeMagazine,
              },
            });
          }

          // Upload profile images
          if (Array.isArray(values.images)) {
            const profileImages = [...values.images];
            for (let index = 0; index < profileImages.length; index++) {
              const file = profileImages[index];
              if (file instanceof Blob) {
                const formData = new FormData();
                formData.append("file", file);
                const response = await GenericDataAPI.postFileUpload({
                  formData,
                });
                if (!response.success || response.body === undefined) {
                  throw new Error("unable to upload profile images");
                }
                const fileName = response.body.name;
                values.images[index] = fileName;
              }
            }
          }

          // Upload horoscope images
          if (Array.isArray(values.horoscopeImages)) {
            const horoscopeImages = [...values.horoscopeImages];
            for (let index = 0; index < horoscopeImages.length; index++) {
              const file = horoscopeImages[index];
              if (file instanceof Blob) {
                const formData = new FormData();
                formData.append("file", file);
                const response = await GenericDataAPI.postFileUpload({
                  formData,
                });
                if (!response.success || response.body === undefined) {
                  throw new Error("unable to upload horoscope images");
                }
                const fileName = response.body.name;
                values.horoscopeImages[index] = fileName;
              }
            }
          }

          postData.images = values.images;
          postData.horoscopeMatching = values.horoscopeMatching ?? false;
          postData.horoscopeImages = values.horoscopeImages;
          postData.horoscopeDetail = values.horoscopeDetail ?? "";
          postData.birthTime = values.birthTime || null;
          postData.birthCity = values.birthCity ?? "";

          postData.personalInfo = postData.personalInfo ?? {};
          postData.personalInfo.originCountryCode = values.originCountryCode;

          break;
        default:
          throw new Error("Unsupported createflow section");
      }
      const response = await UserApi.postUserTempProfile({
        tempProfile: postData,
      });
      if (!response.success) {
        throw new Error("Unable to save temp profile data");
      }
      await dispatch(getAuthTempProfile());
      await dispatch(getAuthUserAccount());
      return true;
    } catch (e) {
      AppLogger.exception(e);
      throw e;
    }
  };

const saveUserPreferences = async ({ preferences }) => {
  const response = await UserApi.postUserPreferences({ preferences });
  if (!response.success) {
    throw new Error("Unable to save user preferences");
  }
  return response;
};

export const updateAuthUserEmail =
  ({ email }) =>
  async () => {
    const response = await UserApi.postUserEmail({ email });
    if (!response.success) {
      if (response.body?.code === 1108) {
        return false;
      }
      throw new Error("Unable to update user email");
    }
    return true;
  };

export const removeTempUserEmail = () => async (dispatch) => {
  const response = await UserApi.removeUserEmail();
  if (!response.success) {
    throw new Error("Unable to remove user email");
  }
  dispatch(getAuthUserAccount());
  return response;
};

const updateAuthUserAddress = async ({
  name,
  line1,
  line2,
  city,
  countryCode = Constants.LANKA_COUNTRY_CODE,
}) => {
  const response = await UserApi.postUserAddress({
    addressFields: { name, line1, line2, city, countryCode },
  });
  if (!response.success) {
    throw new Error("Unable to update user address");
  }
  return response;
};

export const createAuthProfile = async ({ tempProfile }) => {
  try {
    const response = await UserApi.postUserProfile({ tempProfile });
    if (!response.success) {
      throw new Error("Unable to create post");
    }
    return response;
  } catch (error) {
    AppLogger.exception(error);
    throw error;
  }
};

export const getAuthTempProfileImage = async ({ id }) => {
  try {
    const response = await UserApi.getTempProfileImage({ id });
    if (!response.success) {
      throw new Error("Unable to download image");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    AppLogger.exception(e);
    throw e;
  }
};

export const getPublicProfile = async ({ id, context }) => {
  try {
    const response = await UserApi.getPublicPost({ id, context });
    if (!response.success) {
      throw new Error("Unable to get profile");
    }
    return response;
  } catch (e) {
    throw e;
  }
};

export const getAuthPostImages = async ({ postId, imageId }) => {
  try {
    const response = await UserApi.getPostImages({ postId, imageId });
    if (!response.success) {
      throw new Error("Unable to download image");
    }
    return URL.createObjectURL(response.body);
  } catch (e) {
    AppLogger.exception(e);
    throw e;
  }
};
