import { FETCH_UNMATCHED_POSTS, SET_UNMATCHED_ALL_FETCHED } from "./types";
import axios from "axios";
import { isEmpty } from "lodash";

export const getUnmatchedPosts =
  (page = 0) =>
  (dispatch) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${localStorage.getItem(
            "postId"
          )}/interests/matches?pageNum=${page}&pageSize=25&type=unmatched`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
          }
        )
        .then((res) => {
          let allUnmatches = Array.isArray(res.data.results) ? res.data.results : [];
          // filter out interest objects where only contains profile
          allUnmatches = allUnmatches.filter((interest) => !isEmpty(interest.post));
          const page = res.data.pageNum;
          

          dispatch({
            type: FETCH_UNMATCHED_POSTS,
            payload: {
              unmatched: allUnmatches,
              page: page,
              pageSize: 25,
            },
          });
        });
    } catch (e) {
      console.log(e);
    }
  };


  export const setUnmatchesAllFetched = (status = false) => (dispatch) => {
    dispatch({
      type: SET_UNMATCHED_ALL_FETCHED,
      payload: {
        status: status,
      },
    });
  };