import {
  SET_GENERIC_CIVIL_STATUS,
  SET_GENERIC_COUNTRIES,
  SET_GENERIC_DISPLAY_NAME_FORMATS,
  SET_GENERIC_EDUCATION_LEVELS,
  SET_GENERIC_ETHNICITIES,
  SET_GENERIC_FOOD_PREFERENCES,
  SET_GENERIC_HIEGHT_OPTIONS,
  SET_GENERIC_PROFESSIONS,
  SET_GENERIC_RELIGIONS,
  SET_GENERIC_VISA_STATUS,
  SET_GENERIC_GENDERS,
  SET_FORM_OPTIONS,
  SET_GENERIC_HAS_CHILDREN_OPTIONS,
  SET_GENERIC_SECONDARY_COUNTRIES,
  SET_GENERIC_DRINKING_PREFERENCES,
  SET_GENERIC_SMOKING_PREFERENCES,
  SET_GENERIC_INITIALIZED,
  SET_GENERIC_GOTHRAS,
  SET_GENERIC_MOTHER_TOUNGES,
  SET_GENERIC_STAR_OPTIONS,
  SET_GENERIC_STAR_SIGN_OPTIONS,
  SET_GENERIC_HAVE_DOSH_OPTIONS,
  SET_GENERIC_ACCOUNT_OWNERSHIP,
  SET_GENERIC_DIFFERENTLY_ABLED,
  SET_GENERIC_NIC_VERIFIED,
  SET_GENERIC_ALL_COLLECTION,
} from "../../actions/generic-data";

const initialState = {
  initialized: false,
  countries: [],
  secondaryCountries: [],
  genderOptions: [],
  civilStatusOptions: [],
  nameFormatOptions: [],
  ethnicities: [],
  religions: [],
  professions: [],
  educationLevels: [],
  foodPreferences: [],
  visaStatusOptions: [],
  heightOptions: [],
  formOptions: [],
  hasChildrenOptions: [],
  drinkingPreferences: [],
  smokingPrefernces: [],
  gothraOptions: [],
  motherTongues: [],
  starOptions: [],
  starSignOptions: [],
  haveDoshOptions: [],
  accountOwnership: [],
  differentlyAbledOptions: [],
  nicVerifiedOptions: [],
};

const genericDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GENERIC_GENDERS:
      return { ...state, genderOptions: payload };
    case SET_FORM_OPTIONS:
      return { ...state, formOptions: payload };
    case SET_GENERIC_DISPLAY_NAME_FORMATS:
      return { ...state, nameFormatOptions: payload };
    case SET_GENERIC_CIVIL_STATUS:
      return { ...state, civilStatusOptions: payload };
    case SET_GENERIC_ETHNICITIES:
      return { ...state, ethnicities: payload };
    case SET_GENERIC_RELIGIONS:
      return { ...state, religions: payload };
    case SET_GENERIC_COUNTRIES:
      return { ...state, countries: payload };
    case SET_GENERIC_EDUCATION_LEVELS:
      return { ...state, educationLevels: payload };
    case SET_GENERIC_FOOD_PREFERENCES:
      return { ...state, foodPreferences: payload };
    case SET_GENERIC_PROFESSIONS:
      return { ...state, professions: payload };
    case SET_GENERIC_VISA_STATUS:
      return { ...state, visaStatusOptions: payload };
    case SET_GENERIC_HIEGHT_OPTIONS:
      return { ...state, heightOptions: payload };
    case SET_GENERIC_HAS_CHILDREN_OPTIONS:
      return { ...state, hasChildrenOptions: payload };
    case SET_GENERIC_SECONDARY_COUNTRIES:
      return { ...state, secondaryCountries: payload };
    case SET_GENERIC_DRINKING_PREFERENCES:
      return { ...state, drinkingPreferences: payload };
    case SET_GENERIC_SMOKING_PREFERENCES:
      return { ...state, smokingPrefernces: payload };
    case SET_GENERIC_INITIALIZED:
      return { ...state, initialized: payload };
    case SET_GENERIC_GOTHRAS:
      return { ...state, gothraOptions: payload };
    case SET_GENERIC_MOTHER_TOUNGES:
      return { ...state, motherTongues: payload };
    case SET_GENERIC_STAR_OPTIONS:
      return { ...state, starOptions: payload };
    case SET_GENERIC_STAR_SIGN_OPTIONS:
      return { ...state, starSignOptions: payload };
    case SET_GENERIC_HAVE_DOSH_OPTIONS:
      return { ...state, haveDoshOptions: payload };
    case SET_GENERIC_ACCOUNT_OWNERSHIP:
      return { ...state, accountOwnership: payload };
    case SET_GENERIC_DIFFERENTLY_ABLED:
      return { ...state, differentlyAbledOptions: payload };
    case SET_GENERIC_NIC_VERIFIED:
      return { ...state, nicVerifiedOptions: payload };
    case SET_GENERIC_ALL_COLLECTION:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default genericDataReducer;
