// import React, { useState, useEffect } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
// import axios from "axios";
import moment from "moment";

import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";

// import AlertModal from "../detail-page/AlertModal";
// import IgnoreModal from "../detail-page/IgnoreModal";

// import { fetchIntPostData } from "../../../actions/fetchIntPostData";
// import { fetchIntPostWithoutSeen } from "../../../actions/fetchIntPostWithoutSeen";
// import { fetchAllInterest } from "../../../actions/fetchAllInterest";
// import { accordianSubTab } from "../../../actions/accordianSubTab";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { clearInterestPosts, getInterestPosts } from "../../../actions/getInterestPosts";
// import { fetchNotificationCount } from "../../../actions/fetchNotificationCount";

function InterestListCards(props) {
  const history = useHistory();
  // const [id, setId] = useState("");
  // const [alertModal, setAlertModal] = useState(false);
  // const [ignoreModal, setIgnoreModal] = useState(false);
  // const [ignoreReason, setIgnoreReason] = useState("");
  // const [gender, setGender] = useState("");
  // const [fname, setFname] = useState("");
  // const [lnameMasked, setLnameMasked] = useState("");
  // const [commingMsg, setCommingMsg] = useState("");
  // const [loadingIgnore, setLoadingIgnore] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState([]);

  // useEffect(() => {
  //   // setPost(props.post);
  // }, [props.post]);


  // const handleIgnoreModal = (id, gender, fname, lnameMasked, commingMsg) => {
    // setId(id);
    // setGender(gender);
    // setFname(fname);
    // setLnameMasked(lnameMasked);
    // setCommingMsg(commingMsg);

    // if (JSON.parse(localStorage.getItem("post_details")).status === "EXPIRED") {
      // setAlertModal(true);
    // } else {
      // setIgnoreModal(true);
    // }
  // };

  // const handleIgnoreReason = (e) => {
  //   setIgnoreReason(e.target.value);
  // };

  // const handleIgnoreAllInt = () => {
  //   setLoadingIgnore(true);
    // axios
    //   .delete(
    //     `${
    //       process.env.REACT_APP_API_URL
    //     }/v1/account/posts/${localStorage.getItem(
    //       "postId"
    //     )}/interests/${id}?rejectReason=${ignoreReason}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     setLoadingIgnore(false);
    //     setIgnoreModal(false);
    //     props.fetchAllInterest();
    //     props.accordianSubTab(false);
    //     if (typeof props.onIgoreRequest === "function") {
    //       props.onIgoreRequest();
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoadingIgnore(false);
    //   });
  // };

  // const freeRenew = () => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       `${
  //         process.env.REACT_APP_API_URL
  //       }/v1/account/posts/${localStorage.getItem("postId")}/free_renew`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       let url = "";
  //       if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
  //         url = `operator/managed_posts/${localStorage.getItem("postId")}`;
  //       } else {
  //         url = "account/posts";
  //       }
  //       axios
  //         .get(`${process.env.REACT_APP_API_URL}/v1/${url}`, {
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //           },
  //         })
  //         .then((res) => {
  //           if (localStorage.getItem("user_role") === "OPERATOR" || localStorage.getItem("user_role") === "OPERATOR_L2") {
  //             res.data[0] = res.data;
  //           }
  //           if (res.data.length > 0) {
  //             localStorage.setItem("post_details", JSON.stringify(res.data[0]));
  //           }
  //           setAlertModal(false);
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           this.setState({ loading: false });
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       this.setState({ loading: false });
  //     });
  // };

  // const handleModalClose = () => {
  //   setAlertModal(false);
  //   setIgnoreModal(false);
  // };

  // const handleExternalProfile = (post) => {
  //   props.fetchIntPostData(post, "external");
  // }

  return (
    <>
      {props.posts.map((el, i) => (
        <div
          className={
            el.id === props.intId
              ? "interest-post-container active-interest"
              : "interest-post-container"
          }
          key={i}
        >
          <div
            onClick={()=>
              el.ignorePreferences ?
                history.push(`/preference-mismatch-interests?id=${el.id}`)
                : history.push(`/interests?id=${el.id}`)
            }
          >
            {!el.extPostId ?
              <img
                src={el.post.personalInfo.gender === "MALE" ? Male : Female}
                className="profile-pic"
                alt="profile pic"
              />
              :
              <img
                src={el.gender === "MALE" ? Male : Female}
                className="profile-pic"
                alt="profile pic"
              />
            }
            <p className="name">
              {!el.extPostId ? el.post.personalInfo.displayName : el.extName}
            </p>
            {!el.extPostId ?
              <p
                className="message"
                style={{ fontWeight: el.likeSeen || el.ignored ? 300 : 500 }}
              >
                {el.post.personalInfo.age}{" "}
                <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                {el.post.personalInfo.residentCity}{" "}
                <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                {el.post.personalInfo.profession.name === "Other"
                  ? el.post.personalInfo.professionOther
                  : props.lng
                  ? el.post.personalInfo.profession.name
                  : el.post.personalInfo.profession.name_si}{" "}
                <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                {Math.floor(Math.round(el.post.personalInfo.height / 2.54) / 12)}'{" "}
                {Math.round(el.post.personalInfo.height / 2.54) % 12}"
              </p>
              :
              <div style={{display: 'flex'}}>
                <p
                  className="message"
                  style={{ fontWeight: 500 }}
                >
                  {el.extBirthYear}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {el.extCity}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {el.extProfession}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                </p>
                <label className="hytch-label">hytch</label>
              </div>
            }
            {!el.extPostId ?
              <p className="msg-time">
                {moment
                  .utc(
                    !el.ignored
                      ? el.likedAt
                      : el.timeline.filter(
                          (el) => el.eventType === "LIKE_REFUSED"
                        ).length > 0
                      ? el.timeline.filter(
                          (el) => el.eventType === "LIKE_REFUSED"
                        )[0].eventTime
                      : el.timeline.filter((el) => el.eventType === "UNLIKED")
                          .length > 0
                      ? el.timeline.filter((el) => el.eventType === "UNLIKED")[0]
                          .eventTime
                      : el.likedAt
                  )
                  .local()
                  .fromNow()}
              </p>
              :
              <p className="msg-time">
                {moment
                  .utc(
                    el.createdAt
                  )
                  .local()
                  .fromNow()}
              </p>
            }
            {!el.extPostId && !el.ignored && !el.likeSeen && (el.id !== props.intId) && <i className="fas fa-circle"></i>}
            {el.extPostId && <i style={{top: '23px'}} className="fas fa-circle"></i>}
          </div>
          {!el.ignored && (
            <>
              <div className="action-div">
                <span
                  className="view-btn"
                  onClick={() =>
                      el.ignorePreferences
                        ? history.push(
                            `/preference-mismatch-interests?id=${el.id}`
                          )
                        : history.push(`/interests?id=${el.id}`)
                  }
                >
                  View
                </span>
                {/* <span
                  className="view-btn"
                  onClick={() =>
                    handleIgnoreModal(
                      el.id,
                      el.post.personalInfo.gender,
                      el.post.personalInfo.fname,
                      el.post.personalInfo.displayName,
                      el.post.likeMessage
                    )
                  }
                >
                  Reject
                </span> */}
              </div>
              {el.autoIgnoreAt && (
                <p className="auto-reject">
                  Expires {moment.utc(el.autoIgnoreAt).local().fromNow()}
                </p>
              )}
            </>
          )}
        </div>
      ))}

      {/* <AlertModal
        lng={props.lng}
        alertModal={alertModal}
        handleModalClose={handleModalClose}
        freeRenew={freeRenew}
        loading={loading}
      /> */}

      {/* <IgnoreModal
        lng={props.lng}
        ignoreModal={ignoreModal}
        handleModalClose={handleModalClose}
        handleIgnoreAllInt={handleIgnoreAllInt}
        loadingIgnore={loadingIgnore}
        // commingMsg={commingMsg}
        // fname={fname}
        // lnameMasked={lnameMasked}
        // gender={gender}
        ignoreReason={ignoreReason}
        handleIgnoreReason={handleIgnoreReason}
      /> */}
    </>
  );
}

// function matchDispatchToProps(dispatch) {
//   return bindActionCreators(
//     {
//       // fetchIntPostData,
//       // fetchAllInterest,
//       // fetchIntPostWithoutSeen,
//       // accordianSubTab,
//       // clearInterestPosts,
//       // getInterestPosts,
//       // fetchNotificationCount,
//     },
//     dispatch
//   );
// }

// const mapStateToProps = (state) => {
//   return {
//     // intId: state.matches.post.id,
//     // preLoading: state.matches.preLoading,
//   };
// };

export default InterestListCards;
