import { Grid, Typography } from "@material-ui/core";
import React, { memo, useMemo, useRef } from "react";
import { isEmpty, isFunction, isString } from "lodash";

import "./style.css";
import CssClassNameBuilder from "../../../utils/css-class-name-builder";

const isValidOTPDigit = (input) => {
  const pattern = new RegExp(/^\d{1}$/);
  return pattern.test(input);
};

const OTPInput = ({ noOfDigits, value, onChange, helperText, state }) => {
  const digitIteratorRef = useRef(
    Array(noOfDigits)
      .fill(null)
      .map(() => React.createRef(null))
  );
  const digitIterator = digitIteratorRef.current;
  const digitRecorderRef = useRef(Array(noOfDigits).fill(""));

  const onChangeOTPDigit = (position, event) => {
    event.preventDefault();
    try {
      const refContainer = digitIteratorRef.current;
      const element = refContainer[position].current;
      if (!(element instanceof HTMLInputElement)) return;
      const currentValue = element.value.trim();
      const digitLength = isString(currentValue) ? currentValue.length : 0;
      const lastPosition = noOfDigits - 1;
      if (
        digitLength === 1 &&
        isValidOTPDigit(currentValue) &&
        position < lastPosition
      ) {
        const nextElement = refContainer[position + 1]?.current;
        if (nextElement instanceof HTMLInputElement) {
          nextElement.focus();
        }
      } else if (digitLength > 1) {
        for (let temp = position; temp < noOfDigits; temp++) {
          const inputElement = refContainer[temp]?.current;
          const inputValue = currentValue[temp - position];
          if (
            inputElement instanceof HTMLInputElement &&
            !isEmpty(inputValue)
          ) {
            inputElement.value = inputValue;
            const nextElement = refContainer[temp + 1]?.current;
            if (nextElement instanceof HTMLInputElement) {
              nextElement.focus();
            }
          }
        }
      }

      if (isFunction(onChange)) {
        const digits = refContainer.map((currentRef) => {
          const element = currentRef.current;
          return element instanceof HTMLInputElement ? element.value : "";
        });
        const value = digits.join("");
        onChange(value);
      }
    } catch (error) {
      console.log(">>> error ", error);
    }
  };

  const onKeyUpOTPDigit = (position, event) => {
    event.preventDefault();
    const inputElement = digitIterator[position].current;
    if (!(inputElement instanceof HTMLInputElement)) {
      return;
    }
    if (event.keyCode === 8) {
      const currentValue = digitIterator[position]?.current?.value;
      if (currentValue !== "") {
        digitRecorderRef.current[position] = "";
        if (isFunction(onChange)) onChange(digitRecorderRef.current.join(""));
      } else if (position > 0) {
        const previousElement = digitIterator[position - 1]?.current;
        if (previousElement instanceof HTMLInputElement) {
          previousElement.focus();
        }
      }
    }
  };

  const digitClassName = useMemo(() => {
    const builder = new CssClassNameBuilder("digit");
    builder.add(state);
    return builder.build();
  }, [state]);

  const helperTextClassName = useMemo(() => {
    const builder = new CssClassNameBuilder("helper-text");
    builder.add(state);
    return builder.build();
  }, [state]);

  return (
    <Grid container direction="column" className="mobile-otp-input">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        className="otp-digit-container column-gap-8"
      >
        {digitIterator.map((currentEl, idx) => {
          return (
            <input
              key={`otp-digit-${idx}`}
              ref={currentEl}
              variant="outlined"
              className={digitClassName}
              onChange={(e) => onChangeOTPDigit(idx, e)}
              onKeyUp={(e) => onKeyUpOTPDigit(idx, e)}
              disabled={state === "disabled"}
              type="number"
            />
          );
        })}
      </Grid>
      {isString(helperText) && !isEmpty(helperText) && (
        <Typography variant="caption" className={helperTextClassName}>
          {helperText}
        </Typography>
      )}
    </Grid>
  );
};

export default memo(OTPInput);
