import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
// import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import PageHead from "../components/common/PageHead";

import Info from "../images/required-login.png";

import "../styles/disable-profile.css";
import withLanguageMigrator from "../v2/hoc/language-migrator";

class Unauthorize extends Component {
  state = {};

  handleBack = () => {
    this.props.history.goBack();
  };

  handleLogin = () => {
    if (localStorage.getItem("auth_token")) {
      this.props.history.goBack();
    } else {
      document.getElementById("drawer-login-dialog-btn").click();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Grid
          container
          xs={12}
          sm={10}
          md={8}
          direction="column"
          className="cus-container"
        >
          <div className="disable-div">
            <p className="message">
              {this.props.lng ? "Login Required" : "ප්‍රවේශ  වීම අවශ්‍යයි"}
            </p>
            <p align="center" style={{ marginBottom: 0 }}>
              <img src={Info} alt="info" style={{ width: 100, opacity: 0.7 }} />
            </p>
            <p align="center" className="desc">
              {this.props.lng ? (
                "Please log in to your ad account to access this content."
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  මෙම අන්තර්ගතය නැරඹීමට කරුණාකර ඔබේ දැන්වීම් ගිණුමට පිවිසෙන්න.
                </span>
              )}
            </p>

            <Button
              variant="contained"
              onClick={this.handleLogin}
              className="login-btn"
              style={{ width: 120 }}
            >
              {this.props.lng ? (
                "Login"
              ) : (
                <span className="sinhala-w-600 sinhala-size-14">
                  ප්‍රවේශ වන්න
                </span>
              )}
            </Button>
          </div>
        </Grid>
        <Footer lng={this.props.lng} />
      </React.Fragment>
    );
  }
}

export default withRouter(withLanguageMigrator(Unauthorize));
