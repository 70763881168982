import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Male from "../../../images/groom.png";
import Female from "../../../images/bride.png";
import Empty from "../../../images/empty-profile.png";

import { removePost } from "../../../actions/removePost";
import { fetchIntPostWithoutSeen } from "../../../actions/fetchIntPostWithoutSeen";
import { fetchRestrictedUnmatch } from "../../../actions/fetchRestrictedUnmatch";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PostServiceApi from "../../../v2/services/api/post-service";
import { isEmptyOrNullOrUndefined } from "../../../v2/utils";

function MatchCards(props) {
  const [avatar, setAvatar] = useState(Empty);
  const history = useHistory();
  const { authProfile } = useSelector((state) => state.application);

  const { post = {} } = props;

  useEffect(() => {
    const postId = authProfile.id;
    const matchId = post?.id;
    const imageId = post?.avatar;

    if (
      !post.ignored &&
      !isEmptyOrNullOrUndefined(postId) &&
      !isEmptyOrNullOrUndefined(matchId) &&
      !isEmptyOrNullOrUndefined(imageId)
    ) {
      PostServiceApi.getMatchImages({
        postId,
        matchId,
        imageId,
        thumbnail: true,
      })
        .then((res) => {
          let data = URL.createObjectURL(res.body);
          setAvatar(data);
        })
        .catch((err) => {
          console.log("Error >>>> ", err);
        });
    }
  }, [authProfile, post]);

  const handlePostWithoutSeen = () => {
    // history.push(`/my-matches?id=${props.post.post.id}`);
    props.removePost();
    props.fetchIntPostWithoutSeen(props.post);
  };

  const handleRestrict = () => {
    history.push(`/my-matches?id=${props.post.post.id}`);
    props.removePost();
    props.fetchRestrictedUnmatch(props.post);
  };

  return (
    <div
      className={
        props.post.id === props.intId
          ? "interest-post-container active-interest"
          : "interest-post-container"
      }
      onClick={() =>
        props.post.id === props.intId || props.preLoading
          ? null
          : props.post.restricted || props.post.isDisabledProfile
          ? handleRestrict()
          : handlePostWithoutSeen()
      }
      id={props.post.post.id}
    >
      <img
        src={
          !props.post.ignored &&
          !props.post.isDisabledProfile &&
          props.post.avatar
            ? avatar
            : props.post.post.personalInfo.gender === "MALE"
            ? Male
            : Female
        }
        className="avatar"
        alt="profile pic"
      />
      <p className="name">
        {/* {props.post.post.personalInfo.fname}{" "}
        {props.post.post.personalInfo.lnameMasked} */}
        {props.post.post.personalInfo.displayName}
      </p>
      {props.post.ignored || !props.post.latestChat ? (
        <p className="message" style={{ fontWeight: 300 }}>
          {props.post.post.personalInfo.age}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {props.post.post.personalInfo.residentCity}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {props.post.post.personalInfo.profession.name === "Other"
            ? props.post.post.personalInfo.professionOther
            : props.lng
            ? props.post.post.personalInfo.profession.name
            : props.post.post.personalInfo.profession.name_si}{" "}
          <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
          {Math.floor(
            Math.round(props.post.post.personalInfo.height / 2.54) / 12
          )}
          ' {Math.round(props.post.post.personalInfo.height / 2.54) % 12}"
        </p>
      ) : (
        <p
          className="message"
          style={{
            fontWeight:
              !props.post.ignored &&
              props.post.latestChat &&
              props.post.latestChat.sender === "OTHER_PARTY"
                ? 500
                : 300,
          }}
        >
          {props.post.latestChat && props.post.latestChat.message}
        </p>
      )}

      <p className="msg-time">
        {moment
          .utc(
            props.post.timeline.filter((el) => el.eventType === "UNMATCHED")
              .length > 0
              ? props.post.timeline.filter(
                  (el) => el.eventType === "UNMATCHED"
                )[0].eventTime
              : props.post.latestChat
              ? props.post.latestChat.createdAt
              : props.post.matchSeenAt
          )
          .local()
          .fromNow()}
      </p>
      {!props.post.ignored &&
        props.post.latestChat &&
        props.post.latestChat.sender === "OTHER_PARTY" &&
        !props.post.latestChat.seen && <i className="fas fa-circle"></i>}
    </div>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchIntPostWithoutSeen,
      fetchRestrictedUnmatch,
      removePost,
    },
    dispatch
  );
}

const mapStateToProps = (state) => {
  return {
    intId: state.matches.post.id,
    preLoading: state.matches.preLoading,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(MatchCards);
