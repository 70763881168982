import Config from "../../config";
import PageConfigContainer from "./page-config-container";

const { ROUTE_ACCESS_LEVEL, PLATFORMS, ROUTE_PAGE_TEMPLATE } = Config;

const initPageConfigs =  () => {
  //PageConfig:Home Page
  PageConfigContainer.addConfig({
    id: "VJVDGA",
    pathname: "/search",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `${Config.DOMAIN_DISPLAY_NAME} | ${
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Sri Lankan Matrimony"
        : "Indian Matrimony"
    }`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find your perfect match in Sri Lanka with Poruwa.lk | Sri Lankan Matrimony , the confidential matrimony site. Browse profiles securely for efficient Sri Lanka marriage proposals. Join us for modern love with traditional values."
        : undefined,
  });

  //PageConfig:Post Page
  PageConfigContainer.addConfig({
    id: "RPVQTG",
    pathname: "/post",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  // PageConfig: Privacy Page
  PageConfigContainer.addConfig({
    id: "PZTCBC",
    pathname: "/privacy",
    platforms: [PLATFORMS.IN, PLATFORMS.LK],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.TEMPALTE_1,
    title: `Privacy | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "100% secured and privacy-focused Sri Lankan mangala yojana ads. Poruwa.lk connects professionals in Sri Lanka and abroad with privacy and data security."
        : undefined,
  });

  // PageConfig: Pricing Page
  PageConfigContainer.addConfig({
    id: "HPKTWK",
    pathname: "/pricing",
    platforms: [PLATFORMS.IN, PLATFORMS.LK],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    tempalate: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Pricing | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Explore affordable pricing on Poruwa.lk, Sri Lanka's trusted mangala yojana platform. Connect globally for Sri Lankan matrimony needs with privacy and security."
        : undefined,
  });

  // PageConfig: Reviews Page
  PageConfigContainer.addConfig({
    id: "CWNMME",
    pathname: "/reviews",
    platforms: [PLATFORMS.LK],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    tempalate: ROUTE_PAGE_TEMPLATE.TEMPALTE_1,
    title: `Reviews | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Read reviews of Poruwa.lk, Sri Lanka's top mangala yojana platform for matrimony Sri Lanka. Safely connect brides, grooms, and families globally for a successful journey"
        : undefined,
  });

  // PageConfig: Magazine Page
  PageConfigContainer.addConfig({
    id: "JAELDC",
    pathname: "/magazine",
    platforms: [PLATFORMS.LK],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    tempalate: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Magazines | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find your Sri Lankan match on Poruwa.lk. Explore expert advice, Mangala Yojana tips, and success stories. Privacy-focused for your peace of mind."
        : undefined,
  });

  // PageConfig: FAQ Page
  PageConfigContainer.addConfig({
    id: "EMQKDU",
    pathname: "/faq/:category",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    tempalate: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Insights | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find Sri Lankan matches on Poruwa.lk! Get Mangala Yojana help, FAQs, & privacy info. Secure & successful matrimony journey."
        : undefined,
  });

  // PageConfig: Help Page
  PageConfigContainer.addConfig({
    id: "ALBLWT",
    pathname: "/help",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Help | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Find your Sri Lankan match with Poruwa.lk! Get expert guidance, Mangala Yojana help, & secure your happily ever after. Trusted Sri Lankan matrimony."
        : undefined,
  });

  // PageConfig: Terms Page
  PageConfigContainer.addConfig({
    id: "WKRAFE",
    pathname: "/terms",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,

    title: `Terms | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Secure your Sri Lankan matrimony journey on Poruwa.lk. Understand our terms & conditions for a private & safe Mangala Yojana experience."
        : undefined,
  });

  // PageConfig: Settings Page
  PageConfigContainer.addConfig({
    id: "ELNKAP",
    pathname: "/settings",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Settings | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  // PageConfig: Checkout Page
  PageConfigContainer.addConfig({
    id: "KEWNTU",
    pathname: "/checkout",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,

    title: `Checkout | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:CheckoutBankTransfer Page
  PageConfigContainer.addConfig({
    id: "FTKNUK",
    pathname: "/checkout/bank-transfer",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Confirm Bank Transfer | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:InvalidPaymentLink Page
  PageConfigContainer.addConfig({
    id: "DUVEJX",
    pathname: "/invalid-payment-link",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:paymentForAd Page
  PageConfigContainer.addConfig({
    id: "ZACBJE",
    pathname: "/pay-for-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:Restricted Page
  PageConfigContainer.addConfig({
    id: "ERHEXR",
    pathname: "/restricted",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:IncorrectToken Page
  PageConfigContainer.addConfig({
    id: "HFGULX",
    pathname: "/unauthorized-user",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:UnavailableProfile Page
  PageConfigContainer.addConfig({
    id: "YCATGL",
    pathname: "/unavailable-profile",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Unavailable Profile | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:PaymentSuccess Page
  PageConfigContainer.addConfig({
    id: "HNSVVR",
    pathname: "/payment-success",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:RenewSuccess Page
  PageConfigContainer.addConfig({
    id: "AQRXAN",
    pathname: "/renew/success",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:Unsubscribe Page
  PageConfigContainer.addConfig({
    id: "CBXDQW",
    pathname: "/unsubscribe",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Unsubscribe | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:PaymentFailed Page
  PageConfigContainer.addConfig({
    id: "YUVGSE",
    pathname: "/payment-failed",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  //PageConfig:EmailActivation Page
  PageConfigContainer.addConfig({
    id: "HMJZPP",
    pathname: "/activated",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Email Activation | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:EmailAlreadyActivated Page
  PageConfigContainer.addConfig({
    id: "JGWLAS",
    pathname: "/already-activated",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Email Activation | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:Outage Page
  PageConfigContainer.addConfig({
    id: "SNPTYP",
    pathname: "/outage",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Service Unavailable | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:Network-Error Page
  PageConfigContainer.addConfig({
    id: "KHTYDY",
    pathname: "/network-error",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Network Error | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:NewcashSuccess Page
  PageConfigContainer.addConfig({
    id: "XMEJCB",
    pathname: "/newcash-success",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:RenewCashSuccess Page
  PageConfigContainer.addConfig({
    id: "FXNTTY",
    pathname: "/renewcash-success",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:ResetPassword Page
  PageConfigContainer.addConfig({
    id: "ZPCXSG",
    pathname: "/reset-password",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Reset Password | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:MyMatches Page
  PageConfigContainer.addConfig({
    id: "HSLHDZ",
    pathname: "/my-matches",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Matches | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:InterestsMatched Page
  PageConfigContainer.addConfig({
    id: "YKZTSQ",
    pathname: "/interests-matched",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Matches | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:InterestsSent Page
  PageConfigContainer.addConfig({
    id: "WRGPST",
    pathname: "/interests-sent",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Interest sent | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:MyAd Page
  PageConfigContainer.addConfig({
    id: "WTEZYS",
    pathname: "/my-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `My Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:EditMyAd Page
  PageConfigContainer.addConfig({
    id: "GXEGZP",
    pathname: "/edit-my-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Edit My Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:InterestPreferences Page
  PageConfigContainer.addConfig({
    id: "BNFJHW",
    pathname: "/interest-preferences",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Interest Preferences | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:ReviewComments Page
  PageConfigContainer.addConfig({
    id: "TTFNQH",
    pathname: "/review-comments",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  //PageConfig:SentInterests Page
  PageConfigContainer.addConfig({
    id: "GATQKY",
    pathname: "/sent-interests",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Sent Interests | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:ReceivedInterests Page
  PageConfigContainer.addConfig({
    id: "MYKZWW",
    pathname: "/interests",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:Messages Page
  PageConfigContainer.addConfig({
    id: "QTVGST",
    pathname: "/messages",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Messages | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:PreferenceMismatchInterests Page
  PageConfigContainer.addConfig({
    id: "QRTQQY",
    pathname: "/preference-mismatch-interests",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Received Interests | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
  });

  //PageConfig:CompleteYourAd Page(AttractMoreResponses)
  PageConfigContainer.addConfig({
    id: "ELKZXK",
    pathname: "/complete-your-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Attract More Responses | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:CompleteAdPersonal
  PageConfigContainer.addConfig({
    id: "HHEQMV",
    pathname: "/complete-ad-personal",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:CompleteAdParent
  PageConfigContainer.addConfig({
    id: "NBWWHJ",
    pathname: "/complete-ad-parent",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:CompleteAdprivate
  PageConfigContainer.addConfig({
    id: "OAYPGE",
    pathname: "/complete-ad-private",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:CompleteAdReview
  PageConfigContainer.addConfig({
    id: "WAYPGT",
    pathname: "/complete-ad-review",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create Ad | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:PaymentForTopAd
  PageConfigContainer.addConfig({
    id: "QQGARN",
    pathname: "/payments/top-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:TopAdCheckout
  PageConfigContainer.addConfig({
    id: "JBXNAM",
    pathname: "/top-ad-checkout",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Checkout | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:TopAdBankTransfer
  PageConfigContainer.addConfig({
    id: "NYWAEL",
    pathname: "/checkout/top-ad-bank-transfer",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Confirm Bank Transfer | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:TopAdPaymentStatus
  PageConfigContainer.addConfig({
    id: "AWYAGD",
    pathname: "/checkout/top-ad",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Success of Payment | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:ManagePosts
  PageConfigContainer.addConfig({
    id: "FSBFMJ",
    pathname: "/manage-posts",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Manage Posts | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:Login
  PageConfigContainer.addConfig({
    id: "UHAHMM",
    pathname: "/login",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PUBLIC_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:OTPLogin
  PageConfigContainer.addConfig({
    id: "TSLCWT",
    pathname: "/auth",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PUBLIC_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`,
    description:
      Config.CURRENT_PLATFORM === Config.PLATFORMS.LK
        ? "Discover private matrimony at Poruwa.lk | Sri Lankan Matrimony , Sri Lanka's top site. Genuine profiles, secure environment. Find your soulmate discreetly with us. Join now for meaningful connections!"
        : undefined,
    hideFooter: true,
    hideNavBar: true,
  });

  //PageConfig:RegisterNew
  PageConfigContainer.addConfig({
    id: "HSGNCA",
    pathname: "/register",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PUBLIC_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Create an Account | ${Config.DOMAIN_DISPLAY_NAME}`,
    hideFooter: true,
    hideNavBar: true,
  });

  //PageConfig:AccessDenied
  PageConfigContainer.addConfig({
    id: "PMJUDA",
    pathname: "/access-denied",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  //PageConfig:RedirectIntPost(postInterests)
  PageConfigContainer.addConfig({
    id: "ZGWUTK",
    pathname: "/post-interests",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  //PageConfig:RedirectMatchPost(match)
  PageConfigContainer.addConfig({
    id: "KTHFLM",
    pathname: "/match",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [ROUTE_ACCESS_LEVEL.PROFILE_USER],
    template: ROUTE_PAGE_TEMPLATE.NONE,
  });

  //PageConfig:PaymentLink Page
  PageConfigContainer.addConfig({
    id: "DZPUPT",
    pathname: "/pay/:id",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    title: `Payment Invoice | ${Config.DOMAIN_DISPLAY_NAME}`,
  });

  //PageConfig:SampleDatePicker Page
  PageConfigContainer.addConfig({
    id: "USCKEG",
    platforms: [PLATFORMS.LK, PLATFORMS.IN],
    pathname: "/sample-date-picker",
    accessLevels: [
      ROUTE_ACCESS_LEVEL.PROFILE_USER,
      ROUTE_ACCESS_LEVEL.PUBLIC_USER,
      ROUTE_ACCESS_LEVEL.TEMP_PROFILE_USER,
    ],
    template: ROUTE_PAGE_TEMPLATE.NONE,
    hideFooter: true,
  });
};

export default initPageConfigs;
