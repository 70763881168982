import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Footer from "../components/common/Footer";
import FooterMobile from "../components/common/FooterMobile";
import ReviewComponent from "../components/common/ReviewComponent";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import "../styles/all-ads-container.css";
import "../styles/pagination.css";
import "../styles/reviews.css";
import Rating from "react-rating";
import ProgressBar from "@ramonak/react-progress-bar";
import StarIcon from "@material-ui/icons/Star";
import { orderBy } from "lodash";
import withLanguageMigrator from "../v2/hoc/language-migrator";
import RouteConfigHandler from "../v2/templates/route-config-handler";

function Reviews(props) {
  const [preLoading, setPreLoading] = useState(true);
  const [noOfPages, setNoOfPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  // const [lang, setLang] = useState(localStorage.getItem("lng") ? localStorage.getItem("lng") === "true" ? true : false: true);
  const [averageRating, setAverageRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    getReviews(page - 1, 12);
  }, [page]);

  useEffect(() => {
    getReviewSummary();
  }, [props]);

  // const handleLng = () => {
  //   setLang(!lang)
  //   localStorage.setItem("lng", !lang);
  // };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(value);
  };

  const getReviews = (pageNum, pageSize) => {
    setPreLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/generic/reviews?pageNum=${pageNum}&pageSize=${pageSize}`,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((res) => {
        if (res.data) {
          setNoOfPages(res.data.pageCount);
          setTotalCount(res.data.total);
          setReviews(res.data.results);
          setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPreLoading(false);
      });
  };

  const getReviewSummary = () => {
    // setPreLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/review_summary`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((res) => {
        if (res.data) {
          setAverageRating(res.data.averageRating);
          setReviewCount(res.data.reviewCount);
          setGroups(orderBy(res.data.groups, ["rating"], ["desc"]));
          // setPreLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setPreLoading(false);
      });
  };

  const progresssBarLblStyles = {
    flexDirection: "row",
    marginRight: "5px",
    alignItems: "center",
    display: "flex",
    fontWeight: "400",
    fontSize: "14px",
    columnGap: 5,
  };

  return (
    <RouteConfigHandler id={props.routeId}>
      <div className="all-ads-main-container">
        <Grid container xs={12} sm={10} md={8} className="all-ads-container">
          <Grid
            item
            style={{ margin: "auto", width: "100%", minHeight: "25vh" }}
          >
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: "20px" }}
            >
              {props.lng ? (
                "Reviews and Ratings"
              ) : (
                <span className="sinhala-w-600 sinhala-size-28">
                  නිර්දේශ කිරීම් සහ ශ්‍රේණිගත කිරීම්
                </span>
              )}{" "}
            </Typography>
            <p
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                fontSize: "14px",
                color: "#333333",
              }}
            >
              {props.lng
                ? "Reviews and ratings we have received from our users from platforms like Google, Facebook and Email. This is what our users have to say... "
                : ""}
            </p>
            <div className="main-rating-div">
              <div className="total-avg-div">
                <div className="average-rating">{averageRating.toFixed(1)}</div>
                <div>
                  <Rating
                    initialRating={averageRating.toFixed(1)}
                    style={{ color: "#f8b805" }}
                    emptySymbol="far fa-star"
                    fullSymbol="fas fa-star"
                    readonly={true}
                  />
                </div>
                <div className="review-count">
                  {props.lng
                    ? reviewCount + " Reviews"
                    : "නිර්දේශ කිරීම් " + reviewCount}
                </div>
              </div>
              <div className="progress-div">
                {groups.map((group, i) => (
                  <div>
                    <ProgressBar
                      customLabel={
                        <>
                          <Typography variant="body1">
                            {group.rating.toString()}
                          </Typography>
                          <StarIcon style={{ color: "#F8B805" }} />
                        </>
                      }
                      labelAlignment="outside"
                      completed={
                        group.count > 0 ? (group.count / reviewCount) * 100 : 0
                      }
                      baseBgColor="#f0efeb"
                      bgColor="#f8b805"
                      labelColor="#333333"
                      className="review-progress"
                      customLabelStyles={progresssBarLblStyles}
                    />
                  </div>
                ))}
              </div>
            </div>
            <p
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                fontSize: "16px",
                color: "#333333",
              }}
            >
              <a
                className="ignore-int-text-btn"
                href="https://g.page/r/CZE2EBzRkvukEBM/review"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  {props.lng ? (
                    "Write a review"
                  ) : (
                    <span className="sinhala-w-600 sinhala-size-13">
                      නිර්දේශ කිරීමක් ලබාදෙන්න
                    </span>
                  )}
                </span>
              </a>
            </p>
            <hr
              style={{
                borderColor: "#E0E0E0",
                width: "96%",
                borderWidth: "1px",
              }}
            />
            {preLoading ? (
              <div className="pre-loading-reviews">
                <CircularProgress
                  style={{ color: "#c19d0d" }}
                  className="loading-circle"
                />
              </div>
            ) : reviews && reviews.length > 0 ? (
              <>
                <div className="reviews-parent-div">
                  {reviews.map((review, i) => (
                    <div className="reviews-main-div">
                      <ReviewComponent review={review} lang={props.lng} />
                    </div>
                  ))}
                </div>
                <Pagination
                  showFirstButton
                  showLastButton
                  // siblingCount={this.state.screenWidth < 500 ? 0 : 1}
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi pagination-div"
                />
              </>
            ) : (
              <div className="no-records-div">No records</div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="create-floor-footer-desktop">
        <Footer lng={props.lng} />
      </div>
      <div className="create-floor-footer-mobile">
        <FooterMobile lng={props.lng} />
      </div>
    </RouteConfigHandler>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(withLanguageMigrator(Reviews)));
